import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import FileSaver from "file-saver";
import { Table } from "antd";
import PageLayout from "@components/layouts/PageLayout";
import { WebinarItemResponse } from "@state/webinar/dto/webinar.item.response";
import {
  requestGetWebinarRegistrationsExport,
  requestGetWebinars,
} from "@state/webinar/WebinarEffects";
import { columns } from "@components/lists/WebinarsColumns";
import { toastError, toastSuccess } from "@utils/toast-helper";

const WebinarsScreen: FC = () => {
  const { t } = useTranslation();
  const [webinars, setWebinars] = useState<WebinarItemResponse[]>([]);
  const [buttonLoadings, setButtonLoadings] = useState<Record<string, boolean>>(
    {},
  );

  useEffect(() => {
    void requestGetWebinars({});
  }, []);

  useEffect(() => {
    return requestGetWebinars.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setWebinars(result.data);
        setButtonLoadings({});
      }
    });
  }, []);

  const afterWs = useCallback(
    (result: ParsedResponse<Blob>, buttonId: string) => {
      setButtonLoadings((currentLoadings) => ({
        ...currentLoadings,
        [buttonId]: false,
      }));
      if (result.ok && result.data && result.fileName) {
        const data = new Blob([result.data], {
          type: result.data.type,
        });
        FileSaver.saveAs(data, result.fileName);
        toastSuccess(t<string>("webinar.registrations.export.success"));
      } else {
        toastError(t<string>("webinar.registrations.export.error"));
      }
    },
    [t],
  );

  const handleExportRegistrations = useCallback(
    (webinar: WebinarItemResponse) => {
      setButtonLoadings((currentLoadings) => ({
        ...currentLoadings,
        [webinar.id]: true,
      }));
      void requestGetWebinarRegistrationsExport(webinar.id).then((result) =>
        afterWs(result, webinar.id),
      );
    },
    [afterWs],
  );

  return (
    <PageLayout title={t<string>("webinar.title")}>
      <>
        <Table
          columns={columns(handleExportRegistrations, buttonLoadings)}
          dataSource={webinars}
          rowKey={(record) => record.id}
        />
      </>
    </PageLayout>
  );
};

export default WebinarsScreen;
