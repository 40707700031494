import i18n from "i18next";
import { alignRight, orderAscend } from "@utils/Constant";
import { Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import React from "react";
import { ColumnsType } from "antd/lib/table/interface";
import MentionStatusTag from "@components/mention/MentionStatusTag";
import MentionELearningStatusTag from "@components/mention/MentionELearningStatusTag";
import { MentionResponseDto } from "@state/mention/dto/mention.response.dto";
import MentionAttestationPacStatusTag from "@components/mention/MentionAttestationPacStatusTag";
import { AlphabeticalSorter } from "@utils/AlphabeticalSorter";
import { NavigateFunction } from "react-router-dom";
import { ROUTES } from "@routes/Routes";

export const columns = (
  navigate: NavigateFunction,
): ColumnsType<MentionResponseDto> => [
  {
    title: i18n.t<string>("mention.pacHybride.list.columns.status"),
    key: "status",
    dataIndex: "status",
    ellipsis: false,
    render: (status: string) => <MentionStatusTag status={status} />,
    defaultSortOrder: orderAscend,
    sorter: AlphabeticalSorter("status"),
  },
  {
    title: i18n.t<string>("mention.pacHybride.list.columns.eLearningStatus"),
    key: "elearningStatus",
    dataIndex: "elearningStatus",
    ellipsis: false,
    render: (elearningStatus: string) => (
      <MentionELearningStatusTag status={elearningStatus} />
    ),
  },
  {
    title: i18n.t<string>("mention.pacHybride.list.columns.statusRge"),
    key: "statusRge",
    dataIndex: "statusRge",
    ellipsis: false,
    render: (status: string) => (
      <MentionAttestationPacStatusTag status={status} />
    ),
  },
  {
    title: i18n.t<string>("mention.pacHybride.list.columns.companyName"),
    key: "companyName",
    dataIndex: "companyName",
    ellipsis: false,
    defaultSortOrder: orderAscend,
    sorter: AlphabeticalSorter("companyName"),
  },
  {
    title: i18n.t<string>("mention.pacHybride.list.columns.username"),
    key: "username",
    dataIndex: "username",
    ellipsis: false,
    defaultSortOrder: orderAscend,
    sorter: AlphabeticalSorter("username"),
  },
  {
    title: i18n.t<string>("mention.pacHybride.list.columns.lastName"),
    key: "lastName",
    dataIndex: "lastName",
    ellipsis: false,
    defaultSortOrder: orderAscend,
    sorter: AlphabeticalSorter("lastName"),
  },
  {
    title: i18n.t<string>("mention.pacHybride.list.columns.firstName"),
    key: "firstName",
    dataIndex: "firstName",
    ellipsis: false,
    defaultSortOrder: orderAscend,
    sorter: AlphabeticalSorter("firstName"),
  },
  {
    title: i18n.t<string>("users.list.columns.actions"),
    key: "actions",
    fixed: alignRight,
    className: "it-actions",
    onCell: () => {
      return {
        onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
          e.stopPropagation(),
      };
    },
    render: (record: MentionResponseDto) => (
      <Button
        icon={<EyeOutlined />}
        type="text"
        size="large"
        className="btn-primary me-1 btn-list"
        onClick={() =>
          navigate(ROUTES.mentionsPacHybride.details.generate(record.id))
        }
      />
    ),
  },
];
