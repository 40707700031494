import React from "react";
import { Radio, Form, Space, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { formUtils } from "@utils/form-utils";

interface PropsType {
  field: string | InternalNamePath;
  required?: boolean;
  emptyErrorMessage?: string;
  readOnly?: boolean;
  value?: string;
  onChange?: (e: any) => void;
  fieldPrefix?: string;
  options: {
    value: number | string;
    label: string;
  }[];
  direction?: "horizontal" | "vertical";
  optionClassName?: string;
  labelTooltip?: string;
  initialValue?: string;
}

const RadioFormField: React.FunctionComponent<PropsType> = (
  props: PropsType,
) => {
  const {
    field,
    required,
    readOnly,
    emptyErrorMessage,
    value,
    onChange,
    fieldPrefix = "",
    options,
    direction = "horizontal",
    optionClassName = "",
    labelTooltip,
    initialValue,
  } = props;
  const { t } = useTranslation();

  const isRequired: boolean = !!readOnly ? false : !!required; // !(!var) => return false if var = false | null | undefined

  const i18nField = formUtils.geti18nFieldFromField(field);

  const key = `field_${i18nField}`.replace(",", "_");

  return (
    <>
      <Form.Item
        name={fieldPrefix ? `${fieldPrefix}${field.toString()}` : field}
        key={fieldPrefix + key}
        initialValue={initialValue ? initialValue : false}
        rules={
          isRequired
            ? [
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          emptyErrorMessage
                            ? emptyErrorMessage
                            : t<string>("common.empty_error_field"),
                        ),
                },
              ]
            : []
        }
        label={labelTooltip && <Tooltip title={labelTooltip} />}
      >
        <Radio.Group
          disabled={readOnly}
          className={readOnly ? " readonly-form-field" : ""}
          id={fieldPrefix + key}
          onChange={onChange}
          value={value}
        >
          <Space direction={direction}>
            {options.map((option, index) => (
              <Radio
                key={index}
                value={option.value}
                className={optionClassName}
              >
                {option.label}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  );
};

export default RadioFormField;
