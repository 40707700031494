import React from "react";
import { Tag } from "antd";
import i18n from "i18next";

export interface Props {
  worksiteStatus: string;
  className?: string;
}

interface WorksiteStatus {
  label: string;
  color: string;
  value: string;
}

export const WORKSITE_STATUS: { [index: string]: WorksiteStatus } = {
  INIT: {
    label: i18n.t<string>("worksite.labels.status.INIT"),
    color: "#405faf",
    value: "INIT",
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  INIT_INELIGIBLE: {
    label: i18n.t<string>("worksite.labels.status.INIT_INELIGIBLE"),
    color: "#e2003c",
    value: "INIT_INELIGIBLE",
  },
  PENDING: {
    label: i18n.t<string>("worksite.labels.status.PENDING"),
    color: "#405faf",
    value: "PENDING",
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PENDING_INELIGIBLE: {
    label: i18n.t<string>("worksite.labels.status.PENDING_INELIGIBLE"),
    color: "#e2003c",
    value: "PENDING_INELIGIBLE",
  },
  WAITING: {
    label: i18n.t<string>("worksite.labels.status.WAITING"),
    color: "#405faf",
    value: "WAITING",
  },
  ELIGIBLE: {
    label: i18n.t<string>("worksite.labels.status.ELIGIBLE"),
    color: "#00b2a9",
    value: "ELIGIBLE",
  },
  GRDF: {
    label: i18n.t<string>("worksite.labels.status.GRDF"),
    color: "#00b2a9",
    value: "GRDF",
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  GRDF_SUPPORT: {
    label: i18n.t<string>("worksite.labels.status.GRDF_SUPPORT"),
    color: "#00b2a9",
    value: "GRDF_SUPPORT",
  },
};

const WorksiteStatusTag: React.FunctionComponent<Props> = (props: Props) => {
  const { worksiteStatus, className } = props;

  return (
    <Tag className={className} color={WORKSITE_STATUS[worksiteStatus].color}>
      {WORKSITE_STATUS[worksiteStatus].label}
    </Tag>
  );
};

export default WorksiteStatusTag;
