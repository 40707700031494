import React, { useCallback, useEffect, useState } from "react";
import { GazProfessionalDetailsResponseDto } from "@state/gazProfessional/dto/gaz.professional.details.response.dto";
import { RgRequestStatus } from "@utils/enums/gaz.professional.qualifications.rg-requests.status.enum";
import { RGRequestType } from "@utils/enums/gaz.professional.qualifications.rg-requests.type.enum";
import { useTranslation } from "react-i18next";
import { GPQualificationsResponseDto } from "@state/professional/dto/professional.qualifications.response.dto";
import QualificationsCard from "@components/cards/QualificationsCard";
import { QUALIFICATION_CARD_TYPE } from "@utils/enums/gaz.professional.qualifications.qualification.card.type.enum";
import Section from "@components/section/Section";
import { Empty, Table } from "antd";
import { Loader } from "@components/loader/Loader";
import { columns } from "@components/lists/QualificationsRequestsColumns";
import { RG_REQUEST_SPECIALITY } from "@utils/enums/gaz.professional.qualifications.rg-requests.speciality.enum";
import {
  maptoRgRequestSpeciality,
  maptoRgRequestStatus,
  maptoRgRequestType,
} from "@utils/rgRequestMappers";
import { GazProfessionalRGRequestItemResponseDto } from "@state/gazProfessional/dto/gaz.professional.rgrequest.item.response.dto";
import {
  requestGetGazProfessionalQualifications,
  requestGetGazProfessionalRGRequests,
} from "@state/gazProfessional/GazProfessionalEffets";
import { toastError } from "@utils/toast-helper";

export interface Props {
  gazProfessional?: GazProfessionalDetailsResponseDto;
}

export interface QualificationsRequestItem {
  id: string;
  createdAt: string;
  typeLabel: string;
  rg: string;
  status: RgRequestStatus;
  type: RGRequestType;
  isInstallation: boolean;
}

const QualificationsDetail: React.FunctionComponent<Props> = ({
  gazProfessional,
}) => {
  const { t } = useTranslation();

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

  const [qualifications, setQualifications] =
    useState<GPQualificationsResponseDto>();

  const [rgRequests, setRgRequests] =
    useState<GazProfessionalRGRequestItemResponseDto[]>();

  const fetchQualifications = useCallback(() => {
    if (gazProfessional) {
      requestGetGazProfessionalQualifications(
        gazProfessional.yoocanId.toString(),
      ).catch(() => {
        toastError(t<string>("gazProfessional.qualifications.error"));
      });
    }
  }, [gazProfessional, t]);

  useEffect(() => {
    fetchQualifications();
  }, [fetchQualifications]);

  useEffect(() => {
    return requestGetGazProfessionalQualifications.done.watch(
      ({ result: { data, ok } }) => {
        if (ok && data) {
          setQualifications(data);
        }
      },
    );
  });

  const fetchRGRequests = useCallback(() => {
    setDataHasLoaded(false);
    if (gazProfessional) {
      requestGetGazProfessionalRGRequests(
        gazProfessional.yoocanId.toString(),
      ).catch(() => {
        toastError(t<string>("gazProfessional.qualifications.error"));
      });
    }
  }, [gazProfessional, t]);

  useEffect(() => {
    fetchRGRequests();
  }, [fetchRGRequests]);

  useEffect(() => {
    return requestGetGazProfessionalRGRequests.done.watch(
      ({ result: { data, ok } }) => {
        if (ok && data) {
          setRgRequests(data);
          setDataHasLoaded(true);
        }
      },
    );
  });

  function mapToRequest(rgRequest: GazProfessionalRGRequestItemResponseDto) {
    const type = maptoRgRequestType(rgRequest.type);
    const speciality = maptoRgRequestSpeciality(rgRequest.speciality);
    const status = maptoRgRequestStatus(rgRequest.status);
    return {
      id: rgRequest.id,
      createdAt: rgRequest.creationDate,
      typeLabel: `${t(type.label)} ${t(speciality.label)}`,
      rg: `${rgRequest.lastName} ${rgRequest.firstName}`,
      status,
      type,
      isInstallation: speciality === RG_REQUEST_SPECIALITY.INSTALLATION,
    };
  }

  function buildRequests() {
    return rgRequests?.map((rgRequest) => mapToRequest(rgRequest));
  }

  return (
    <>
      {qualifications == null ? (
        <Loader text={t<string>("gazProfessional.qualifications.loader")} />
      ) : (
        <div className="qualifications-cards">
          <QualificationsCard
            title={t<string>(
              "gazProfessional.qualifications.installation.title",
            )}
            className="flex-grow-1"
            qualification={qualifications.installation}
            type={QUALIFICATION_CARD_TYPE.INSTALLATION}
          />
          <QualificationsCard
            title={t<string>(
              "gazProfessional.qualifications.maintenance.title",
            )}
            className="flex-grow-1"
            qualification={qualifications.maintenance}
            type={QUALIFICATION_CARD_TYPE.MAINTENANCE}
          />
        </div>
      )}

      <Section
        title={t<string>("gazProfessional.qualifications.requests.title")}
        titleClassName="text-primary"
        className="mt-40"
      >
        <Table
          locale={{
            emptyText: <Empty description={t<string>("common.noData")} />,
          }}
          columns={columns()}
          dataSource={buildRequests()}
          loading={{
            spinning: !dataHasLoaded,
            indicator: <Loader />,
          }}
          size="small"
          className="app-contact-pro-table pro-table-cursor-default"
          pagination={false}
        />
      </Section>
    </>
  );
};

export default QualificationsDetail;
