export enum StructureTypes {
  CATEGORY = "CATEGORY",
  PAGE = "PAGE",

  // eslint-disable-next-line @typescript-eslint/naming-convention
  FRONT_PAGE = "FRONT_PAGE",
}

export enum StructureStaticKeys {
  HOME = "HOME",
  NAVIGATION = "NAVIGATION",
  FOOTER = "FOOTER",
  ARCHIVE = "ARCHIVE",
  ARTICLE = "ARTICLE",
}
