import i18n from "i18next";
import React from "react";
import { MoreOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";
import { showConfirm } from "@components/modals/ConfirmModal";
import { requestExecuteTask } from "@state/tasks/TaskEffects";
import { TaskItemResponseDto } from "@state/tasks/dto/response/task.item.response.dto";
import TableButton from "@components/buttons/TableButton";
import { alignRight } from "@utils/Constant";
import DateFormat from "@utils/DateFormat";
import { ColumnsType } from "antd/lib/table/interface";
import { toastError, toastSuccess } from "@utils/toast-helper";

const popoverContent = (record: TaskItemResponseDto) => (
  <div className="d-inline-flex">
    <TableButton
      icon={<SettingOutlined />}
      tooltipTitle={i18n.t<string>("tasks.list.tooltip.execute")}
      onClick={() => {
        showConfirm(
          i18n.t<string>("tasks.list.confirmation.execute", {
            task: record.name,
          }),
          () => {
            void requestExecuteTask(record.taskId)
              .then(() => {
                toastSuccess(
                  i18n.t<string>("tasks.messages.successfulStart", {
                    task: record.name,
                  }),
                );
              })
              .catch(() => {
                toastError(
                  i18n.t<string>("tasks.messages.errorOnStart", {
                    task: record.name,
                  }),
                );
              });
          },
        );
      }}
      buttonClassName="btn-primary me-1 btn-list"
    />
  </div>
);

export const columns = (): ColumnsType<TaskItemResponseDto> => [
  {
    title: i18n.t<string>("tasks.list.columns.taskId"),
    key: "taskId",
    dataIndex: "taskId",
    ellipsis: false,
  },
  {
    title: i18n.t<string>("tasks.list.columns.name"),
    key: "name",
    dataIndex: "name",
    ellipsis: false,
  },
  {
    title: i18n.t<string>("tasks.list.columns.lastExecution"),
    key: "lastExecution",
    dataIndex: "lastExecution",
    ellipsis: false,
    render: (record: string) => {
      return <span>{DateFormat.standardDateTime(new Date(record))}</span>;
    },
  },
  {
    title: i18n.t<string>("tasks.list.columns.nextExecution"),
    key: "nextExecution",
    dataIndex: "nextExecution",
    ellipsis: false,
    render: (record: string) => {
      return <span>{DateFormat.standardDateTime(new Date(record))}</span>;
    },
  },
  {
    title: i18n.t<string>("tasks.list.columns.actions"),
    key: "execute",
    fixed: alignRight,
    onCell: () => {
      return {
        onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
          e.stopPropagation(),
      };
    },
    render: (record: TaskItemResponseDto) => (
      <Popover
        content={popoverContent(record)}
        placement="bottomRight"
        trigger="hover"
      >
        <Button
          icon={<MoreOutlined />}
          type="text"
          size="large"
          className="btn-list"
        />
      </Popover>
    ),
  },
];
