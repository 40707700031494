import { createEffect } from "effector";
import "whatwg-fetch";
import configuration from "src/utils/Config";
import { restListHandler, restPostHandler } from "@utils/rest/RestClient";
import { StructureSaveRequestDto } from "@state/structure/dto/structure.save.request.dto";
import { StructureDto } from "./dto/structure.dto";

const baseUrl = configuration.backendBaseUrl;
const structureUrl = baseUrl + "/structure";

export const requestGetStructure = createEffect({
  handler: restListHandler<StructureDto>(`${structureUrl}/management`),
});

export const requestUpdateStructure = createEffect({
  handler: restPostHandler<StructureSaveRequestDto, void>(structureUrl),
});
