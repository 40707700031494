import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SpinIcon } from "@components/icons/SpinIcon";
import { Divider, Table } from "antd";
import { columns } from "@components/lists/EndpointsColumns";
import { ProfileDetailsResponseDto } from "@state/app/response/profile.details.response.dto";
import { requestGetEndpoints, requestGetProfiles } from "@state/app/AppEffects";
import { EndpointGroupDetailsResponseDto } from "@state/app/response/endpoint.group.details.response.dto";
import { toastError } from "@utils/toast-helper";
import PageLayout from "@components/layouts/PageLayout";

const EndpointsScreen: FC = () => {
  const { t } = useTranslation();

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

  const [profiles, setProfiles] = useState<ProfileDetailsResponseDto[]>([]);
  const [endpoints, setEndpoints] = useState<EndpointGroupDetailsResponseDto[]>(
    [],
  );

  const fetchProfiles = useCallback(() => {
    requestGetProfiles({})
      .catch(() => {
        toastError(t<string>("endpoints.list.messages.loading-error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [t]);

  const fetchEndpoints = useCallback(() => {
    setDataHasLoaded(false);
    requestGetEndpoints({})
      .catch(() => {
        toastError(t<string>("endpoints.list.messages.loading-error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [t]);

  useEffect(() => {
    fetchEndpoints();
    fetchProfiles();
  }, [fetchEndpoints, fetchProfiles]);

  useEffect(() => {
    return requestGetEndpoints.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setDataHasLoaded(true);
        setEndpoints(result.data);
      }
    });
  });

  useEffect(() => {
    return requestGetProfiles.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setDataHasLoaded(true);
        setProfiles(result.data);
      }
    });
  });

  return (
    <PageLayout title={t<string>("endpoints.title")}>
      <>
        {endpoints.map((endpointGroup) => (
          <>
            <div className="d-flex flex-column align-items-stretch flex-sm-row">
              <div className="w-100 d-flex align-items-center">
                <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
                  <h3 className="text-secondary mb-0">{endpointGroup.label}</h3>
                </Divider>
              </div>
            </div>
            <Table
              key={endpointGroup.label}
              style={{ whiteSpace: "break-spaces" }}
              locale={{ emptyText: dataHasLoaded ? "" : " " }}
              loading={{ spinning: !dataHasLoaded, indicator: SpinIcon }}
              columns={columns(profiles)}
              pagination={false}
              dataSource={endpointGroup.endpoints}
              showSorterTooltip={false}
              rowKey={(record) =>
                `${record.urls.toString()}_${record.methods.toString()}`
              }
              rowClassName="cursor-pointer"
              className="mb-5"
            />
          </>
        ))}
      </>
    </PageLayout>
  );
};

export default EndpointsScreen;
