import { useEffect, useState, FunctionComponent } from "react";
import { Button, Col, Divider, Form, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Field } from "@type/form/field.types";
import { LeadResponseDto } from "@state/contact/dto/contact.response.dto";
import { requestGetLead } from "@state/contact/ContactEffets";
import { formUtils } from "@utils/form-utils";
import { formItemBlockLayout } from "@utils/Constant";
import { ArrowLeftOutlined } from "@ant-design/icons";
import InputFormField from "@components/inputs/InputFormField";
import TextAreaFormField from "@components/inputs/TextAreaFormField";
import {
  CURRENT_ENERGIES,
  EQUIPMENT_TYPES,
  INTERVENTION_TYPES,
} from "@utils/enums/lead.enum";
import DateFormat from "@utils/DateFormat";
import { STATUS } from "@components/contacts/ContactStatusTag";
import { toastError } from "@utils/toast-helper";

export interface Props {
  leadId?: string;
}

const mapContactFields = (lead: LeadResponseDto): Field[] => {
  const fields = formUtils.mapFieldsFrom(lead);
  const createdAtField = fields.find((field) =>
    field.name.includes("createdAt"),
  );
  if (createdAtField?.value) {
    createdAtField.value = DateFormat.standardDateIso(
      new Date(createdAtField.value),
    );
  }
  const status = fields.find((field) => field.name.includes("status"));
  if (status?.value) {
    const value = Object.values(STATUS).find((s) => s.value === status.value);
    status.value = value ? value.label : "";
  }
  const interventionType = fields.find((field) =>
    field.name.includes("interventionType"),
  );
  if (interventionType?.value) {
    const value = Object.values(INTERVENTION_TYPES).find(
      (s) => s.value === interventionType.value,
    );
    interventionType.value = value ? value.label : "";
  }
  const currentEnergy = fields.find((field) =>
    field.name.includes("currentEnergy"),
  );
  if (currentEnergy?.value) {
    const value = Object.values(CURRENT_ENERGIES).find(
      (s) => s.value === currentEnergy.value,
    );
    currentEnergy.value = value ? value.label : "";
  }
  const equipmentType = fields.find((field) =>
    field.name.includes("equipmentTypes"),
  );
  if (equipmentType?.value) {
    const equipmentTypes = equipmentType.value.toString().split(",");
    const equipmentTypesLabel = equipmentTypes
      .map((p) => Object.values(EQUIPMENT_TYPES).find((s) => s.value === p))
      .map((s) => (s ? s.label : ""));
    equipmentType.value = equipmentTypesLabel
      ? equipmentTypesLabel.join(", ")
      : " ";
  }
  return fields;
};

const LeadForm: FunctionComponent<Props> = (props: Props) => {
  const { leadId } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [fields, setFields] = useState<Field[]>([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (leadId) {
      void requestGetLead(leadId);
    }
  }, [leadId]);

  useEffect(() => {
    return requestGetLead.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setFields(mapContactFields(result.data));
      } else {
        toastError(t<string>("contact.notFound"));
      }
    });
  });

  return (
    <>
      <Form {...formItemBlockLayout} form={form} fields={fields}>
        <div className="form-first-item-sticky-top-container">
          <div className="form-first-item-sticky-top-content">
            <div className="d-flex align-items-center justify-content-between flex-wrap px-3">
              <div className="d-flex align-items-center justify-content-center flex-wrap">
                <Button
                  htmlType="reset"
                  className="m-2"
                  onClick={() => navigate(-1)}
                >
                  <ArrowLeftOutlined /> {t<string>("buttons.back")}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Divider orientation="left">
          <h3 className="text-secondary mb-0">
            {t<string>("contacts.lead.details.infoPG")}
          </h3>
        </Divider>
        <Row>
          <Col xs={24} sm={12} lg={24} xl={6}>
            <InputFormField
              className="mx-2"
              showLabel
              module="contacts.lead"
              field="companyName"
              readOnly={true}
            />
          </Col>
          <Col xs={24} sm={12} lg={24} xl={6}>
            <InputFormField
              className="mx-2"
              showLabel
              module="contacts.lead"
              field="username"
              readOnly={true}
            />
          </Col>
          <Col xs={24} sm={12} lg={24} xl={12}>
            <InputFormField
              className="mx-2"
              showLabel
              module="contacts.lead"
              field="addressPG"
              readOnly={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12} lg={24} xl={12}>
            <InputFormField
              className="mx-2"
              showLabel
              module="contacts.lead"
              field="emailPG"
              readOnly={true}
            />
          </Col>
          <Col xs={24} sm={12} lg={24} xl={12}>
            <InputFormField
              className="mx-2"
              showLabel
              module="contacts.lead"
              field="phonePG"
              readOnly={true}
            />
          </Col>
        </Row>
        <Divider orientation="left">
          <h3 className="text-secondary mb-0">
            {t<string>("contacts.lead.details.infoLead")}
          </h3>
        </Divider>
        <Row>
          <Col xs={24} sm={12} lg={24} xl={12}>
            <InputFormField
              className="mx-2"
              showLabel
              module="contacts.lead"
              field="status"
              readOnly={true}
            />
          </Col>
          <Col xs={24} sm={12} lg={24} xl={12}>
            <InputFormField
              type="date"
              className="mx-2"
              showLabel
              module="contacts.lead"
              field="createdAt"
              readOnly={true}
            />
          </Col>
        </Row>
        <Divider orientation="left">
          <h5 className="text-tertiary mb-0">
            {t<string>("contacts.lead.details.infoLeadCustomer")}
          </h5>
        </Divider>
        <Row>
          <Col xs={24} sm={12} lg={24} xl={12}>
            <InputFormField
              className="mx-2"
              showLabel
              module="contacts.lead"
              field="prospect"
              readOnly={true}
            />
          </Col>
          <Col xs={24} sm={12} lg={24} xl={12}>
            <InputFormField
              className="mx-2"
              showLabel
              module="contacts.lead"
              field="phone"
              readOnly={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12} lg={24} xl={12}>
            <InputFormField
              className="mx-2"
              showLabel
              module="contacts.lead"
              field="email"
              readOnly={true}
            />
          </Col>
          <Col xs={24} sm={12} lg={24} xl={12}>
            <InputFormField
              className="mx-2"
              showLabel
              module="contacts.lead"
              field="address"
              readOnly={true}
            />
          </Col>
        </Row>

        <Divider orientation="left">
          <h5 className="text-tertiary mb-0">
            {t<string>("contacts.lead.details.infoLeadProject")}
          </h5>
        </Divider>
        <Row>
          <Col xs={24} sm={12} lg={24} xl={12}>
            <InputFormField
              className="mx-2"
              showLabel
              module="contacts.lead"
              field="interventionType"
              readOnly={true}
            />
          </Col>
          <Col xs={24} sm={12} lg={24} xl={12}>
            <InputFormField
              className="mx-2"
              showLabel
              module="contacts.lead"
              field="currentEnergy"
              readOnly={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12} lg={24} xl={24}>
            <InputFormField
              className="mx-2"
              showLabel
              module="contacts.lead"
              field="equipmentTypes"
              readOnly={true}
            />
          </Col>
        </Row>
        <TextAreaFormField
          showLabel={true}
          module="contacts.contact"
          field="message"
          readOnly={true}
        />

        <Divider orientation="left">
          <h3 className="text-secondary mb-0">
            {t<string>("contacts.lead.details.infoCodeAction")}
          </h3>
        </Divider>
        <Row>
          <Col xs={24} sm={12} lg={24} xl={12}>
            <InputFormField
              className="mx-2"
              showLabel
              module="contacts.lead"
              field="form"
              readOnly={true}
            />
          </Col>
          <Col xs={24} sm={12} lg={24} xl={12}>
            <InputFormField
              className="mx-2"
              showLabel
              module="contacts.lead"
              field="medium"
              readOnly={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12} lg={24} xl={12}>
            <InputFormField
              className="mx-2"
              showLabel
              module="contacts.lead"
              field="campaign"
              readOnly={true}
            />
          </Col>
          <Col xs={24} sm={12} lg={24} xl={12}>
            <InputFormField
              className="mx-2"
              showLabel
              module="contacts.lead"
              field="source"
              readOnly={true}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default LeadForm;
