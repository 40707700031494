import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Modal } from "antd";
import { KeywordInfoResponseDTO } from "@state/keyword/dto/keyword.response.dto";
import { KeywordStore } from "@state/keyword/KeywordStore";
import { useStore } from "effector-react";
import {
  requestCreateKeyword,
  requestUptadeKeyword,
} from "@state/keyword/KeywordEffects";
import { addKeyword, updateKeyword } from "@state/keyword/KeywordEvents";
import { formItemBlockLayout } from "@utils/Constant";
import { Store } from "rc-field-form/lib/interface";
import { toastError, toastSuccess } from "@utils/toast-helper";

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  keyword?: KeywordInfoResponseDTO;
}

export const KeywordModal: React.FunctionComponent<Props> = ({
  visible,
  setVisible,
  keyword,
}) => {
  const { t } = useTranslation();

  const keywordStore: KeywordInfoResponseDTO[] | null = useStore(KeywordStore);

  const handleSubmit = (values: Store) => {
    if (keyword) {
      void requestUptadeKeyword({
        id: keyword.id,
        dto: {
          keyword: values.keyword as string,
        },
      });
    } else if (
      keywordStore.filter((keyword) => keyword.keyword === values.keyword)
        .length > 0
    ) {
      toastError(t<string>("keywords.add.error.text"));
    } else {
      void requestCreateKeyword({
        dto: {
          keyword: values.keyword as string,
        },
      });
    }
  };

  useEffect(() => {
    return requestCreateKeyword.done.watch(({ result }) => {
      if (result.ok && result.data) {
        addKeyword(result.data);
        toastSuccess(t<string>("keywords.add.success"));
        setVisible(false);
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    return requestUptadeKeyword.done.watch(({ result }) => {
      if (result.ok && result.data) {
        updateKeyword(result.data);
        toastSuccess(t<string>("keywords.update.success"));
        setVisible(false);
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  return (
    <Modal
      title={
        keyword
          ? t<string>("keywords.update.modal.title")
          : t<string>("keywords.add.modal.title")
      }
      okText={t<string>("common.confirm")}
      okButtonProps={{
        form: "add_keyword_form",
        htmlType: "submit",
      }}
      onCancel={() => setVisible(false)}
      cancelText={t<string>("common.cancel")}
      open={visible}
      centered
    >
      <Form
        id="add_keyword_form"
        {...formItemBlockLayout}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="keyword"
          label={t<string>("keywords.add.form.text")}
          rules={[
            {
              required: true,
              message: t<string>("common.empty_error_field"),
              whitespace: true,
            },
          ]}
          initialValue={keyword && keyword.keyword}
        >
          <Input id="keyword" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
