import { ROUTES } from "@routes/Routes";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { useEffect, useMemo } from "react";
import { requestGetUserInfo } from "@state/auth/AuthEffects";
import { setUser } from "@state/auth/AuthEvents";

const AuthLayout = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthentified = useStore(authentifiedStore);

  const loginRedirect = useMemo(
    () =>
      ROUTES.auth.login.generate({
        callback: location.pathname,
      }),
    [location],
  );

  useEffect(() => {
    void requestGetUserInfo({}).then((ar) => {
      if (ar.ok && ar.data) {
        setUser(ar.data);
      } else {
        navigate(loginRedirect);
      }
    });
  }, [navigate, loginRedirect]);

  if (!isAuthentified || !isAuthentified.user) {
    return (
      <Routes>
        <Route path="/*" element={<Navigate to={loginRedirect} />} />
      </Routes>
    );
  }

  return <Outlet />;
};

export default AuthLayout;
