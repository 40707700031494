import i18n from "i18next";
import { alignRight } from "@utils/Constant";
import { Button, Popover } from "antd";
import { CheckOutlined, CloseOutlined, MoreOutlined } from "@ant-design/icons";
import React from "react";
import TableButton from "@components/buttons/TableButton";
import { showConfirm } from "@components/modals/ConfirmModal";
import { UserItemResponseDto } from "@state/users/dto/response/user.item.response.dto";
import { requestUpdateUserStatus } from "@state/users/UserEffects";
import { ColumnsType } from "antd/lib/table/interface";

const popoverContent = (record: UserItemResponseDto) => (
  <div className="d-inline-flex">
    {record.status !== "ACTIVE" ? (
      <TableButton
        icon={<CheckOutlined />}
        tooltipTitle={i18n.t<string>("users.list.actions.enable")}
        onClick={() => {
          showConfirm(
            i18n.t<string>("users.edit.modals.enable.title", {
              user: record.name,
            }),
            () => {
              void requestUpdateUserStatus({
                id: record.id,
                dto: {
                  status: "ACTIVE",
                },
              });
            },
          );
        }}
        buttonClassName="btn-primary me-1 btn-list"
      />
    ) : (
      <TableButton
        icon={<CloseOutlined />}
        tooltipTitle={i18n.t<string>("users.list.actions.disable")}
        onClick={() => {
          showConfirm(
            i18n.t<string>("users.edit.modals.disable.title", {
              user: record.name,
            }),
            () => {
              void requestUpdateUserStatus({
                id: record.id,
                dto: {
                  status: "DISABLED",
                },
              });
            },
          );
        }}
        buttonClassName="btn-primary me-1 btn-list"
      />
    )}
  </div>
);

export const columns = (): ColumnsType<UserItemResponseDto> => [
  {
    title: i18n.t<string>("users.list.columns.name"),
    key: "name",
    dataIndex: "name",
    ellipsis: false,
  },
  {
    title: i18n.t<string>("users.list.columns.email"),
    key: "email",
    dataIndex: "email",
    ellipsis: false,
  },
  {
    title: i18n.t<string>("users.list.columns.profile"),
    key: "profile",
    dataIndex: "profile",
    ellipsis: false,
    render: (status: string) =>
      i18n.t<string>(`users.labels.profiles.${status}`),
  },
  {
    title: i18n.t<string>("users.list.columns.status"),
    key: "status",
    dataIndex: "status",
    ellipsis: false,
    render: (status: string) => i18n.t<string>(`users.labels.status.${status}`),
  },
  {
    title: i18n.t<string>("users.list.columns.actions"),
    key: "actions",
    fixed: alignRight,
    className: "it-actions",
    onCell: () => {
      return {
        onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
          e.stopPropagation(),
      };
    },
    render: (record: UserItemResponseDto) => (
      <Popover
        content={popoverContent(record)}
        placement="bottomRight"
        trigger="hover"
      >
        <Button
          icon={<MoreOutlined />}
          type="text"
          size="large"
          className="btn-list"
        />
      </Popover>
    ),
  },
];
