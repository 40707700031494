import { FunctionComponent } from "react";
import { passwordHelper } from "@utils/password-helper";
import { validationPassword } from "@components/forms/SetPasswordForm";
import { useTranslation } from "react-i18next";

interface PasswordValidationTooltipProps {
  password: string;
}

const PasswordValidationTooltip: FunctionComponent<
  PasswordValidationTooltipProps
> = ({ password }: PasswordValidationTooltipProps) => {
  const { t } = useTranslation();

  return (
    <div className="custom-error constant">
      {t<string>("set-password.form.errors.title")}
      <ul>
        <li
          className={
            passwordHelper.validateCharacter(password, validationPassword)
              ? "custom-valid"
              : ""
          }
        >
          {t<string>("set-password.form.errors.character", {
            count: validationPassword.character,
          })}
        </li>
        <li
          className={
            passwordHelper.validateUpperCase(password, validationPassword)
              ? "custom-valid"
              : ""
          }
        >
          {t<string>("set-password.form.errors.upperCase", {
            count: validationPassword.upperCase,
          })}
        </li>
        <li
          className={
            passwordHelper.validateLowerCase(password, validationPassword)
              ? "custom-valid"
              : ""
          }
        >
          {t<string>("set-password.form.errors.lowerCase", {
            count: validationPassword.lowerCase,
          })}
        </li>
        <li
          className={
            passwordHelper.validateNumber(password, validationPassword)
              ? "custom-valid"
              : ""
          }
        >
          {t<string>("set-password.form.errors.number", {
            count: validationPassword.number,
          })}
        </li>
        <li
          className={
            passwordHelper.validateSpecial(password, validationPassword)
              ? "custom-valid"
              : ""
          }
        >
          {t<string>("set-password.form.errors.special", {
            count: validationPassword.special,
          })}
        </li>
      </ul>
    </div>
  );
};

export default PasswordValidationTooltip;
