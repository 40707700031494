import { DeleteOutlined } from "@ant-design/icons/lib";
import BasicButton from "@components/buttons/BasicButton";
import { showConfirm } from "@components/modals/ConfirmModal";
import { requestDeleteCategory } from "@state/category/CategoryEffects";
import { FunctionComponent } from "react";
import { StructureDto } from "@state/structure/dto/structure.dto";
import { useTranslation } from "react-i18next";

export interface PropsType {
  category: StructureDto;
}

const DeleteCategory: FunctionComponent<PropsType> = (props: PropsType) => {
  const { category } = props;

  const { t } = useTranslation();

  return (
    <>
      <BasicButton
        disabled={!category.deletable}
        variant="danger"
        icon={<DeleteOutlined />}
        title={t<string>(
          `structure.deletion.${
            category.deletable ? "deletable" : "notDeletable"
          }`,
        )}
        onClick={() =>
          showConfirm(
            t<string>("categories.table.confirmation.delete", {
              category: category.title,
            }),
            () => {
              void requestDeleteCategory({
                dto: { id: category.key },
              });
            },
          )
        }
        className="ml-8 mt-8 ms-1 tree-button"
      />
    </>
  );
};

export default DeleteCategory;
