import i18n from "i18next";
import { ImageRules } from "src/type/page/page.types";
import { RcFile } from "antd/lib/upload";
import { toastError } from "@utils/toast-helper";

export const getBase64UrlFrom = (base64: string, type: string): string => {
  return `data:${type};base64,${base64}`;
};

const removeBase64UrlPrefix = (base64File: string): string => {
  const i = base64File.indexOf("base64,");
  return base64File.slice(i + 7);
};

export const getBase64 = (
  img: Blob,
  callback: (imageUrl: string | ArrayBuffer | null) => void,
): void => {
  const reader = new FileReader();
  reader.addEventListener("load", () =>
    callback(removeBase64UrlPrefix(reader.result as string)),
  );
  reader.readAsDataURL(img);
};

async function loadImageAndCheckWidthHeight(
  imageUrl: string,
  rules: ImageRules,
) {
  const img = new Image();
  const imageLoadPromise = new Promise((resolve, reject) => {
    img.onload = () => {
      if (
        (rules.width && img.width > rules.width) ||
        (rules.height && img.height > rules.height)
      ) {
        toastError(i18n.t<string>("global.img.error_dimension"));
        reject();
      }
      resolve(undefined);
    };
    img.src = imageUrl;
  });

  await imageLoadPromise;
}

export const beforeUpload = (
  file: RcFile,
  rules?: ImageRules,
):
  | void
  | boolean
  | string
  | Blob
  | File
  | Promise<void | boolean | string | Blob | File> => {
  const isExtensionIsCompatible =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/webp" ||
    file.type === "image/avif";
  if (!isExtensionIsCompatible) {
    void toastError(i18n.t<string>("common.img.error_extension"));
  }
  let maxWeight;
  if (rules && rules.size) {
    maxWeight = file.size < rules.size;
    if (!maxWeight) {
      toastError(
        i18n.t<string>("global.img.error_size", {
          size: `${rules.size} octets`,
        }),
      );
    }
  } else {
    maxWeight = file.size / 1024 / 1024 < 2;
    if (!maxWeight) {
      toastError(i18n.t<string>("common.img.error_size", { size: "2 MB" }));
    }
  }
  if (rules && isExtensionIsCompatible && maxWeight) {
    return loadImageAndCheckWidthHeight(
      window.URL.createObjectURL(file),
      rules,
    );
  } else {
    return isExtensionIsCompatible && maxWeight;
  }
};
