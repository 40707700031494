import { FC } from "react";
import { useTranslation } from "react-i18next";
import CategoryForm from "@views/category/details/CategoryForm";
import PageLayout from "@components/layouts/PageLayout";
import { ROUTES } from "@routes/Routes";
import { useNavigate } from "react-router-dom";

const AddCategoryScreen: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <PageLayout title={t<string>("addCategory.title")}>
      <CategoryForm
        editMode={true}
        onSuccess={() => navigate(ROUTES.cms.structure.details.generate())}
      />
    </PageLayout>
  );
};

export default AddCategoryScreen;
