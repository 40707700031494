import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Form, Row } from "antd";
import { useTranslation } from "react-i18next";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import FileSaver from "file-saver";
import { requestGetUserInfo } from "@state/auth/AuthEffects";
import DatePickerFormField from "@components/inputs/DatePickerFormField";
import moment, { Moment } from "moment";
import { SaveOutlined } from "@ant-design/icons";
import { requestGetCampaignContactsExport } from "@state/contact/CampaignContactEffects";
import { toastError, toastSuccess } from "@utils/toast-helper";

const CampaignContactsForm: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const today = new Date();
  const firstDayOfMonth = new Date(
    today.toISOString().slice(0, 8).concat("01"),
  );

  const [from, setFrom] = useState<Moment>(moment(firstDayOfMonth));
  const [to, setTo] = useState<Moment>(moment(today));

  const InitialValues = {
    from: from,
    to: to,
  };

  const onChangeDateFrom = (current: Moment): void => {
    setFrom(current);
  };

  const onChangeDateTo = (current: Moment): void => {
    setTo(current);
  };

  useEffect(() => {
    void requestGetUserInfo({});
  }, []);

  const handleSubmit = () => {
    setButtonLoading(true);
    void requestGetCampaignContactsExport({
      dto: {
        from: from.toISOString().split("T")[0],
        to: to.toISOString().split("T")[0],
      },
    });
  };

  useEffect(() => {
    return requestGetCampaignContactsExport.done.watch(({ result }) => {
      afterWs(result);
    });
  });

  const afterWs = (result: ParsedResponse<Blob>) => {
    setButtonLoading(false);
    if (result.ok && result.data && result.fileName) {
      const data = new Blob([result.data], {
        type: "application/octet-stream",
      });
      FileSaver.saveAs(data, result.fileName);
      toastSuccess(t<string>("gazMonth.leads.generation.success"));
    } else {
      toastError(t<string>("gazMonth.leads.generation.error"));
    }
  };

  return (
    <>
      <div className="w-100 d-flex align-items-center">
        <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
          <h3 className="text-secondary mb-0">
            {t<string>("gazMonth.leads.title")}
          </h3>
        </Divider>
      </div>
      <div className="mb-0 mb-sm-3 mt-3">
        <Form initialValues={InitialValues}>
          <Row>
            <Col>
              <DatePickerFormField
                required
                showLabel
                module="gazMonth.leads"
                field="from"
                mode="date"
                picker="date"
                onChange={(value) => onChangeDateFrom(value as Moment)}
              />
            </Col>
            <Col>
              <DatePickerFormField
                required
                showLabel
                module="gazMonth.leads"
                field="to"
                mode="date"
                picker="date"
                onChange={(value) => onChangeDateTo(value as Moment)}
                disabledDate={(current) => {
                  if (current > moment()) {
                    return true;
                  }
                  return from && current < from;
                }}
              />
            </Col>
          </Row>
        </Form>

        <Button
          className="btn-primary my-3 mx-3"
          onClick={handleSubmit}
          loading={buttonLoading}
        >
          <SaveOutlined /> {t<string>("gazMonth.leads.form.actions.submit")}
        </Button>
      </div>
    </>
  );
};

export default CampaignContactsForm;
