import i18n from "i18next";
import { DeleteOutlined } from "@ant-design/icons";
import { alignRight, orderAscend } from "@utils/Constant";
import TableButton from "@components/buttons/TableButton";
import { showConfirm } from "@components/modals/ConfirmModal";
import { tooltipDateTimeRender } from "@components/tooltips/CustomTooltip";
import { KeywordInfoResponseDTO } from "@state/keyword/dto/keyword.response.dto";
import { requestDeleteKeyword } from "@state/keyword/KeywordEffects";
import { ColumnsType } from "antd/lib/table/interface";

export const columns = (): ColumnsType<KeywordInfoResponseDTO> => [
  {
    title: i18n.t<string>("keywords.table.keyword"),
    key: "keyword",
    dataIndex: "keyword",
    ellipsis: true,
    defaultSortOrder: orderAscend,
  },
  {
    title: i18n.t<string>("keywords.table.pages_number"),
    key: "pagesNumber",
    dataIndex: "pagesNumber",
    ellipsis: true,
  },
  {
    title: i18n.t<string>("keywords.table.creation_date"),
    key: "createdAt",
    dataIndex: "createdAt",
    ellipsis: true,
    render: tooltipDateTimeRender,
  },
  {
    title: i18n.t<string>("keywords.table.modification_date"),
    key: "updatedAt",
    dataIndex: "updatedAt",
    ellipsis: true,
    render: tooltipDateTimeRender,
  },
  {
    title: i18n.t<string>("keywords.table.action"),
    key: "action",
    fixed: alignRight,
    width: 115,
    render: (text: string, record: KeywordInfoResponseDTO) => (
      <div className="d-inline-flex">
        <TableButton
          tooltipTitle={i18n.t<string>("keywords.table.tooltip.delete")}
          icon={<DeleteOutlined />}
          buttonClassName="btn-danger m-2"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            showConfirm(
              i18n.t<string>("keywords.table.confirmation.delete", {
                keyword: record.keyword,
              }),
              () => {
                void requestDeleteKeyword({
                  dto: record,
                });
              },
            );
          }}
        />
      </div>
    ),
  },
];
