import React from "react";
import { Checkbox, Form, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { formUtils } from "@utils/form-utils";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { QuestionCircleOutlined } from "@ant-design/icons";

interface PropsType {
  module: string;
  field: string | InternalNamePath;
  required?: boolean;
  emptyErrorMessage?: string;
  readOnly?: boolean;
  onChange?: (e: CheckboxChangeEvent) => void;
  fieldPrefix?: string;
  label?: string;
  labelTooltip?: string;
  noLabel?: boolean;
  checked?: boolean;
  initialValue?: boolean;
}

const CheckboxFormField: React.FunctionComponent<PropsType> = (
  props: PropsType,
) => {
  const {
    module,
    field,
    required,
    readOnly,
    emptyErrorMessage,
    onChange,
    fieldPrefix = "",
    label,
    labelTooltip,
    noLabel,
    initialValue,
  } = props;
  const { t } = useTranslation();

  const isRequired: boolean = !!readOnly ? false : !!required; // !(!var) => return false if var = false | null | undefined

  const i18nField = formUtils.geti18nFieldFromField(field);

  const key = `field_${i18nField}`.replace(",", "_");

  return (
    <>
      <Form.Item
        name={fieldPrefix ? `${fieldPrefix}${field.toString()}` : field}
        key={fieldPrefix + key}
        initialValue={initialValue}
        valuePropName="checked"
        rules={
          isRequired
            ? [
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          emptyErrorMessage
                            ? emptyErrorMessage
                            : t<string>("common.empty_error_field"),
                        ),
                },
              ]
            : []
        }
      >
        <Checkbox
          disabled={readOnly}
          className={readOnly ? " readonly-form-field" : ""}
          id={fieldPrefix + key}
          onChange={onChange}
        >
          {!noLabel && (
            <span>
              {label ? label : t<string>(`${module}.form.${i18nField}.label`)}
              &nbsp;
            </span>
          )}
          {labelTooltip && (
            <Tooltip title={labelTooltip}>
              <QuestionCircleOutlined />
            </Tooltip>
          )}
        </Checkbox>
      </Form.Item>
    </>
  );
};

export default CheckboxFormField;
