import configuration from "@utils/Config";
import { createEffect } from "effector";
import { restGetUniqueHandler } from "@utils/rest/RestClient";
import { ContactSearchCriteria } from "@state/contact/dto/contact.search.criteria";

const baseUrl = configuration.backendBaseUrl;

export const requestGetCampaignContactsExport = createEffect({
  handler: restGetUniqueHandler<Blob, ContactSearchCriteria>(
    `${baseUrl}/campaign/contacts/export`,
  ),
});
