import { FC, useEffect } from "react";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { requestGetUserInfo } from "@state/auth/AuthEffects";

const DashboardScreen: FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    void requestGetUserInfo({});
  }, []);

  return (
    <PageLayout
      title={t<string>("dashboard.title")}
      bodyClassName="dashboard-body"
    >
      <div className="text-center dashboard-title">
        {t<string>("dashboard.introduction")}
      </div>
    </PageLayout>
  );
};

export default DashboardScreen;
