import { FC } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BasicButton from "src/components/buttons/BasicButton";
import { requestForgotPassword } from "@state/auth/AuthEffects";
import PageLayout from "@components/layouts/PageLayout";
import InputFormField from "@components/inputs/InputFormField";
import SubmitButton from "@components/buttons/SubmitButton";
import { ROUTES } from "@routes/Routes";
import { toastError, toastSuccess } from "@utils/toast-helper";

interface FormValues {
  email: string;
}

const ForgotPasswordScreen: FC = () => {
  const { t } = useTranslation();

  const InitialValues = {
    email: "",
  };

  const handleSubmit = (values: any) => {
    const obj = values as FormValues;

    requestForgotPassword({ dto: { email: obj.email } })
      .then(() => {
        toastSuccess(t<string>("forgot-password.messages.success"));
      })
      .catch((error: Error) => toastError(error.message));
  };

  return (
    <PageLayout
      noHeader
      noSideMenu
      title={t<string>("forgot-password.title")}
      bodyClassName="login-body"
    >
      <>
        <Form onFinish={handleSubmit} initialValues={InitialValues}>
          <InputFormField
            module="forgot-password"
            field="email"
            type="email"
            className="mb-3"
            required
          />
          <div className="d-flex align-items-center justify-content-center mt-2">
            <Link to={ROUTES.auth.login.generate()}>
              <BasicButton
                text={t<string>("buttons.back")}
                variant="tertiary"
                className="mt-2 me-2"
              />
            </Link>
            <div className="form-group mb-0">
              <SubmitButton
                module="forgot-password"
                isSubmitting={false}
                label="reset"
                className="mt-2"
              />
            </div>
          </div>
        </Form>
      </>
    </PageLayout>
  );
};

export default ForgotPasswordScreen;
