import { FunctionComponent, useEffect, useState } from "react";
import BasicModal from "@components/modals/BasicModal";
import {
  InstallationItem,
  ProgrammingAuditItem,
} from "@state/audit/dto/audit.item.details.dto";
import { requestGetInstallationDetail } from "@state/audit/AuditEffects";
import { useTranslation } from "react-i18next";
import { auditUtils } from "@views/gazProfessional/details/audit/audit-utils";
import parse from "html-react-parser";

interface AuditDetailModalProps {
  title: string;
  visible: boolean;
  onCancel: () => void;
  className?: string;
  programmingAuditItem?: ProgrammingAuditItem;
  pgId: string;
}

const AuditDetailModal: FunctionComponent<AuditDetailModalProps> = ({
  title,
  visible,
  onCancel,
  className = "",
  programmingAuditItem,
  pgId,
}) => {
  const { t } = useTranslation();
  const [auditInstallation, setAuditInstallation] =
    useState<InstallationItem>();

  useEffect(() => {
    if (programmingAuditItem != null && visible) {
      void requestGetInstallationDetail({
        dto: {
          installationId: programmingAuditItem.ccId,
          gazProfessionalId: pgId,
        },
      });
    }
  }, [pgId, programmingAuditItem, visible]);

  useEffect(() => {
    return requestGetInstallationDetail.done.watch(({ result }) => {
      if (result.ok && result.data && visible) {
        const installationDetail = result.data;
        setAuditInstallation({
          id: installationDetail.id,
          installationId: installationDetail.installationId,
          ocId: installationDetail.ocId,
          ocCcId: installationDetail.ocCcId,
          nameRg: installationDetail.nameRg,
          address1: installationDetail.address1,
          address2: installationDetail.address2,
          address3: installationDetail.address3,
          address4: installationDetail.address4,
          city: installationDetail.city,
          zipCode: installationDetail.zipCode,
          dateOfRegistration: installationDetail.dateOfRegistration,
        });
      }
    });
  });

  return (
    <>
      <BasicModal
        title={title}
        visible={visible}
        onCancel={onCancel}
        className={`${className}`}
        footer={null}
      >
        <>
          <div className="audit-detail-installations">
            <div className="audit-detail-installations-items">
              {auditInstallation && (
                <div className="audit-detail-installations-item">
                  <div className="audit-detail-installations-item-name">
                    {auditUtils.renderTitle(auditInstallation.ocId)}
                  </div>
                  <div className="audit-detail-installations-item-number">{`${t<string>(
                    "gazProfessional.audit.detail.installations.items.number",
                  )} ${auditInstallation.ocCcId}`}</div>
                  <div className="audit-detail-installations-item-rgi">
                    {t<string>(
                      "gazProfessional.audit.detail.installations.items.rgi",
                      {
                        name: auditInstallation.nameRg,
                      },
                    )}
                  </div>
                  <div className="audit-detail-installations-item-address">
                    <div>{parse(auditUtils.getAddress(auditInstallation))}</div>
                    <div>
                      {auditInstallation?.zipCode
                        .concat(" ")
                        .concat(auditInstallation?.city)}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      </BasicModal>
    </>
  );
};

export default AuditDetailModal;
