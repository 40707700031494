import React, { useState } from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import BasicLink from "@components/links/BasicLink";
import { StructureDto } from "@state/structure/dto/structure.dto";
import configuration from "@utils/Config";

export interface PagePreviewButtonProps {
  page: StructureDto;
}

const PreviewPageLink: React.FunctionComponent<PagePreviewButtonProps> = ({
  page,
}) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState<boolean>(false);

  const linkButton = (
    <BasicLink
      to="#"
      className="m-8 tree-link"
      onClick={() => setVisible(true)}
    >
      {t<string>("pages.preview")}
    </BasicLink>
  );

  const pageUrl = `${configuration.frontendBaseUrl}/preview${page.seoFriendlyUrlPart}/${page.key}`;

  return (
    <>
      {linkButton}
      <Modal
        className="page-preview"
        title={t<string>("pages.preview")}
        open={visible}
        centered
        footer={
          <Button key="closePreviewModal" onClick={() => setVisible(false)}>
            {t<string>("common.close")}
          </Button>
        }
        onCancel={() => setVisible(false)}
        bodyStyle={{ padding: 0 }}
      >
        <iframe
          className="preview-iframe"
          title={page.seoFriendlyUrlPart}
          src={pageUrl}
        />
      </Modal>
    </>
  );
};

export default PreviewPageLink;
