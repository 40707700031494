import React, { ReactNode } from "react";
import TableButton from "@components/buttons/TableButton";
import { alignRight } from "@utils/Constant";
import { EyeOutlined, MoreOutlined } from "@ant-design/icons/lib";
import DateFormat from "@utils/DateFormat";
import i18n from "@utils/i18n/i18n";
import { Button, Popover } from "antd";
import { GazProfessionalItemResponseDto } from "@state/gazProfessional/dto/gaz.professional.item.response.dto";
import { NavigateFunction } from "react-router-dom";
import { ROUTES } from "@routes/Routes";

const popoverContent = (
  record: GazProfessionalItemResponseDto,
  navigate: NavigateFunction,
) => {
  return (
    <div className="d-inline-flex">
      <TableButton
        onClick={() =>
          navigate(ROUTES.gazProfessionals.details.generate(record.yoocanId))
        }
        icon={<EyeOutlined />}
        tooltipTitle={i18n.t<string>("gazProfessional.table.tooltip.view")}
        buttonClassName="btn-primary me-1 btn-list"
      />
    </div>
  );
};

export const columns = (navigate: NavigateFunction) => [
  {
    title: i18n.t<string>("gazProfessional.table.companyName"),
    key: "companyName",
    dataIndex: "companyName",
    ellipsis: true,
  },
  {
    title: i18n.t<string>("gazProfessional.table.email"),
    key: "email",
    dataIndex: "email",
    ellipsis: true,
  },
  {
    title: i18n.t<string>("gazProfessional.table.yoocanId"),
    key: "yoocanId",
    dataIndex: "yoocanId",
    ellipsis: true,
  },
  {
    title: i18n.t<string>("gazProfessional.table.modificationDate"),
    key: "updatedAt",
    dataIndex: "updatedAt",
    ellipsis: true,
    render: (record: Date): ReactNode => {
      return (
        <span>
          {record ? DateFormat.standardDateTime(new Date(record)) : ""}
        </span>
      );
    },
  },
  {
    title: i18n.t<string>("gazProfessional.table.action"),
    key: "action",
    fixed: alignRight,
    width: 90,
    onCell: (): {
      onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    } => {
      return {
        onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
          e.stopPropagation(),
      };
    },
    render: (
      text: string,
      record: GazProfessionalItemResponseDto,
    ): ReactNode => (
      <Popover
        content={popoverContent(record, navigate)}
        placement="bottomRight"
        trigger="hover"
      >
        <Button
          icon={<MoreOutlined />}
          type="text"
          size="large"
          className="btn-list"
        />
      </Popover>
    ),
  },
];
