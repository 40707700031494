class JSONFormat {
  parseJSON(json: unknown): unknown {
    if (typeof json === "string") {
      return JSON.parse(json);
    } else {
      return JSON.parse(JSON.stringify(json));
    }
  }
}

export default new JSONFormat();
