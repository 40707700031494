import { FC } from "react";
import PageLayout from "@components/layouts/PageLayout";
import { useStore } from "effector-react";
import Section from "@components/section/Section";
import BasicButton from "@components/buttons/BasicButton";
import { HomeOutlined, SearchOutlined } from "@ant-design/icons";
import BasicTag from "@components/tags/BasicTag";
import BasicBadge from "@components/badges/BasicBadge";
import BasicDivider from "@components/divider/BasicDivider";
import BasicCard from "@components/cards/BasicCard";
import BasicAlert from "@components/alerts/BasicAlert";
import { toast } from "react-toastify";
import { authentifiedStore } from "@state/auth/AuthStore";

const ComponentsScreen: FC = () => {
  const isAuthentified = useStore(authentifiedStore);

  return (
    <PageLayout
      noSideMenu={!isAuthentified || !isAuthentified.user}
      title="Composants - Titre page"
      subtitle="Sous-titre page"
      breadcrumbs={[
        {
          link: "/",
          icon: <HomeOutlined />,
        },
        {
          label: "Composants",
        },
      ]}
      noContentInCard
    >
      <>
        <Section title="Sections" className="mb-5">
          <div>Section content (any content)</div>
        </Section>
        <Section title="Boutons" className="mb-5">
          <div className="d-flex align-items-center flex-wrap">
            <BasicButton icon={<SearchOutlined />} className="me-3 mb-3" />
            <BasicButton
              icon={<SearchOutlined />}
              text="Primary"
              className="me-3 mb-3"
            />
            <BasicButton text="Primary" className="me-3 mb-3" />
            <BasicButton
              variant="secondary"
              text="Secondary"
              className="me-3 mb-3"
            />
            <BasicButton
              variant="tertiary"
              text="Tertiary"
              className="me-3 mb-3"
            />
            <BasicButton
              variant="success"
              text="Success"
              className="me-3 mb-3"
            />
            <BasicButton
              variant="warning"
              text="Warning"
              className="me-3 mb-3"
            />
            <BasicButton variant="danger" text="Danger" className="me-3 mb-3" />
            <BasicButton variant="black" text="Black" className="me-3 mb-3" />
            <BasicButton variant="white" text="White" className="me-3 mb-3" />
            <BasicButton variant="grey" text="Grey" className="me-3 mb-3" />
            <BasicButton
              variant="dark-grey"
              text="Dark grey"
              className="me-3 mb-3"
            />
            <BasicButton
              variant="transparent"
              text="Transparent"
              className="mb-3"
            />
          </div>
        </Section>
        <Section title="Badges" className="mb-5">
          <div className="d-flex align-items-center flex-wrap">
            <BasicBadge number={1} className="me-3 mb-3" />
            <BasicBadge variant="primary" number={12} className="me-3 mb-3" />
            <BasicBadge variant="secondary" number={24} className="me-3 mb-3" />
            <BasicBadge variant="tertiary" number={36} className="me-3 mb-3" />
            <BasicBadge variant="success" number={48} className="me-3 mb-3" />
            <BasicBadge variant="warning" number={60} className="me-3 mb-3" />
            <BasicBadge variant="danger" number={72} className="me-3 mb-3" />
            <BasicBadge variant="white" number={84} className="me-3 mb-3" />
            <BasicBadge variant="grey" number={96} className="me-3 mb-3" />
            <BasicBadge variant="dark-grey" number={99} className="mb-3" />
          </div>
        </Section>
        <Section title="Tags" className="mb-5">
          <div className="d-flex align-items-center flex-wrap">
            <BasicTag label="Default" className="me-3 mb-3" />
            <BasicTag variant="primary" label="Primary" className="me-3 mb-3" />
            <BasicTag
              variant="secondary"
              label="Secondary"
              className="me-3 mb-3"
            />
            <BasicTag
              variant="tertiary"
              label="Tertiary"
              className="me-3 mb-3"
            />
            <BasicTag variant="success" label="Success" className="me-3 mb-3" />
            <BasicTag variant="warning" label="Warning" className="me-3 mb-3" />
            <BasicTag variant="danger" label="Danger" className="me-3 mb-3" />
            <BasicTag variant="white" label="White" className="me-3 mb-3" />
            <BasicTag variant="grey" label="Grey" className="me-3 mb-3" />
            <BasicTag variant="dark-grey" label="Dark grey" className="mb-3" />
          </div>
        </Section>
        <Section title="Dividers" className="mb-5">
          <div className="d-flex align-items-center flex-wrap">
            <BasicDivider className="me-3" />
            <BasicDivider variant="primary" className="me-3 mb-3" />
            <BasicDivider variant="secondary" className="me-3 mb-3" />
            <BasicDivider variant="tertiary" className="me-3 mb-3" />
            <BasicDivider variant="success" className="me-3 mb-3" />
            <BasicDivider variant="warning" className="me-3 mb-3" />
            <BasicDivider variant="danger" className="me-3 mb-3" />
            <BasicDivider variant="white" className="me-3 mb-3" />
            <BasicDivider variant="grey" className="me-3 mb-3" />
            <BasicDivider variant="dark-grey" className="mb-3" />
          </div>
        </Section>
        <Section title="Cards" className="mb-5">
          <>
            <BasicCard className="mb-3">
              <>Basic card</>
            </BasicCard>
            <BasicCard onClick={() => console.log("clickable")}>
              <>Basic clickable card</>
            </BasicCard>
          </>
        </Section>
        <Section title="Alerts" className="mb-5">
          <>
            <BasicAlert text="Default alert" className="mb-3" />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Default alert with icon"
              className="mb-3"
            />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Primary alert"
              variant="primary"
              className="mb-3"
            />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Secondary alert"
              variant="secondary"
              className="mb-3"
            />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Tertiary alert"
              variant="tertiary"
              className="mb-3"
            />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Success alert"
              variant="success"
              className="mb-3"
            />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Warning alert"
              variant="warning"
              className="mb-3"
            />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Danger alert"
              variant="danger"
              className="mb-3"
            />
          </>
        </Section>
        <Section title="Toasts" className="mb-5">
          <div className="d-flex align-items-center flex-wrap">
            <BasicButton
              variant="primary"
              text="Info toast"
              onClick={() => toast.info("Info toast")}
              className="me-3 mb-3"
            />
            <BasicButton
              variant="success"
              text="Success toast"
              onClick={() => toast.success("Success toast")}
              className="me-3 mb-3"
            />
            <BasicButton
              variant="danger"
              text="Error toast"
              onClick={() => toast.error("Error toast")}
              className="mb-3"
            />
          </div>
        </Section>
      </>
    </PageLayout>
  );
};

export default ComponentsScreen;
