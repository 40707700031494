import { FC } from "react";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import UserForm from "@views/users/details/UserForm";

const UserAddScreen: FC = () => {
  const { t } = useTranslation();

  return (
    <PageLayout title={t<string>("users.add.title")}>
      <>
        <UserForm editMode />
      </>
    </PageLayout>
  );
};

export default UserAddScreen;
