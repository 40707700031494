const sortFunction = (
  fields: string[],
  a: Record<string, unknown>,
  b: Record<string, unknown>,
): number => {
  const valueA = a[fields[0]];
  const valueB = b[fields[0]];
  return fields.length === 1
    ? (valueA as string).localeCompare(valueB as string)
    : sortFunction(
        fields.filter((item, index) => index > 0),
        valueA as Record<string, unknown>,
        valueB as Record<string, unknown>,
      );
};

export const AlphabeticalSorter = (
  field: string,
): ((a: Record<string, unknown>, b: Record<string, unknown>) => number) => {
  const fields: string[] = field.split(".");
  return (a: Record<string, unknown>, b: Record<string, unknown>) =>
    sortFunction(fields, a, b);
};

const sortWithFalsyFunction = (
  fields: string[],
  a: Record<string, unknown>,
  b: Record<string, unknown>,
): number => {
  const valueA = a[fields[0]];
  const valueB = b[fields[0]];
  return fields.length === 1
    ? valueA
      ? valueB
        ? (valueA as string).localeCompare(valueB as string)
        : -1
      : valueB
      ? 1
      : 0
    : sortWithFalsyFunction(
        fields.filter((item, index) => index > 0),
        valueA as Record<string, unknown>,
        valueB as Record<string, unknown>,
      );
};

export const AlphabeticalWithFalsySorter = (
  field: string,
): ((a: Record<string, unknown>, b: Record<string, unknown>) => number) => {
  const fields: string[] = field.split(".");
  return (a: Record<string, unknown>, b: Record<string, unknown>) =>
    sortWithFalsyFunction(fields, a, b);
};
