import { createEvent } from "effector";
import {
  AuthentifiedContext,
  AuthentifiedUser,
  AuthResult,
} from "@type/auth/auth.types";
import {
  TableFilterPayload,
  TablePageNumberPayload,
  TablePageSizePayload,
} from "@type/pagination/pagination.types";

export const authentifiedContextEvent = createEvent<
  AuthentifiedContext | undefined
>();

export const login = createEvent<AuthResult>();

export const setUser = createEvent<AuthentifiedUser>("SET_USER");

export const clearUser = createEvent<void>("CLEAR_USER");

export const clearFilter = createEvent<void>("CLEAR_FILTER");

export const setTablePageSize = createEvent<TablePageSizePayload>(
  "SET_TABLE_PAGE_SIZE",
);

export const setTablePageNumber = createEvent<TablePageNumberPayload>(
  "SET_TABLE_PAGE_NUMBER",
);

export const setFilterPage =
  createEvent<TableFilterPayload>("SET_TABLE_FILTER");

export const logout = createEvent<void>();
