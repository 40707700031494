import React from "react";
import { EndpointDetailsResponseDto } from "@state/app/response/endpoint.details.response.dto";

export interface Props {
  endpoint: EndpointDetailsResponseDto;
}

const EndpointLabelTag: React.FunctionComponent<Props> = (props: Props) => {
  const { endpoint } = props;

  return (
    <>
      <h5>{endpoint.urls}</h5>
      <h6>{endpoint.description}</h6>
    </>
  );
};

export default EndpointLabelTag;
