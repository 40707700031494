import React, { useState } from "react";
import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import TextAreaFormField from "./TextAreaFormField";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import shortid from "shortid";

export interface TextAreaListValue {
  id: string;
  value: string;
}

interface PropsType {
  showLabel?: boolean;
  module: string;
  field: string;
  initialValues: TextAreaListValue[];
  fieldPrefix?: string;
  readOnly?: boolean;
  onChange: (value: string, inputIndex: number) => void;
  inputIndex: number;
}

const TextAreaListFormField: React.FunctionComponent<PropsType> = (
  props: PropsType,
) => {
  const {
    showLabel,
    module,
    field,
    initialValues,
    fieldPrefix = "",
    readOnly,
    onChange,
    inputIndex,
  } = props;

  const { t } = useTranslation();

  const [values, setValues] = useState<TextAreaListValue[]>(initialValues);

  const localHandleContentChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    index: number,
  ) => {
    const newValues = [...values];
    newValues[index] = {
      ...values[index],
      value: e.target.value,
    };
    setValues(newValues);
    onChange(JSON.stringify(values), inputIndex);
  };

  const handleDeleteBlock = (index: number) => {
    const newValues = [...values];
    newValues.splice(index, 1);
    setValues(newValues);
    onChange(JSON.stringify(values), inputIndex);
  };

  return (
    <>
      {values.map((value, index) => (
        <Row key={value.id}>
          <Col md={20}>
            <TextAreaFormField
              readOnly={readOnly}
              onChange={(e) => localHandleContentChange(e, index)}
              showLabel={showLabel}
              module={module}
              field={field}
              initialValue={value.value}
              fieldPrefix={`${fieldPrefix}_${value.id}`}
            />
          </Col>
          <Col>
            {!readOnly && (
              <Button
                onClick={() => handleDeleteBlock(index)}
                className="btn-danger m-2"
              >
                <DeleteOutlined /> {t<string>("global.delete")}
              </Button>
            )}
          </Col>
        </Row>
      ))}
      {!readOnly && (
        <Button
          onClick={() =>
            setValues([
              ...values,
              {
                id: shortid.generate(),
                value: "",
              },
            ])
          }
          className="btn-secondary m-2"
        >
          <PlusCircleOutlined /> {t<string>("global.add")}
        </Button>
      )}
    </>
  );
};

export default TextAreaListFormField;
