import React, { ChangeEvent, useState } from "react";
import { Form, Upload } from "antd";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { formItemBlockLayout } from "src/utils/Constant";
import { ImageContent, ImageRules } from "src/type/page/page.types";
import { beforeUpload, getBase64, getBase64UrlFrom } from "@utils/image-utils";
import { Image } from "@type/image/image-interface";
import { UploadChangeParam } from "antd/lib/upload/interface";
import { useTranslation } from "react-i18next";
import InputFormField from "@components/inputs/InputFormField";
export interface Props {
  editMode?: boolean;
  formFieldLabel: string;
  formFieldName: string;
  imageContent?: ImageContent;
  newsId?: string;
  inputIndex?: number;
  onChangeCallBack?: (
    imageContent: ImageContent,
    index?: number,
    inputId?: string,
  ) => void;
  setImage?: (image: Image) => void;
  rules?: ImageRules;
  fieldPrefix?: string;
  inputId?: string;
  className?: string;
  required?: boolean;
}

const UploadImageForm: React.FunctionComponent<Props> = (props: Props) => {
  const {
    inputIndex,
    onChangeCallBack,
    formFieldLabel,
    formFieldName,
    imageContent,
    editMode,
    rules,
    setImage,
    fieldPrefix,
    inputId,
    className = "",
    required = false,
  } = props;

  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const [imageUrl, setImageUrl] = useState<string | undefined>(
    imageContent && imageContent.content,
  );
  const [imageAlt, setImageAlt] = useState<string | undefined>(
    imageContent && imageContent.alt,
  );
  const [imageTitle, setImageTitle] = useState<string | undefined>(
    imageContent && imageContent.title,
  );

  const uploadButton = (
    <div className="ant-upload-button">
      {loading ? <LoadingOutlined /> : <UploadOutlined />}
      <div className="ant-upload-text">Importer</div>
    </div>
  );

  const finalFieldName = (fieldPrefix ? fieldPrefix : "") + formFieldName;

  const handleChange = (info: UploadChangeParam) => {
    setLoading(true);
    if (info.file.originFileObj) {
      getBase64(info.file.originFileObj, (imageBase64Data) => {
        if (info.file.type) {
          const imageBase64Url = getBase64UrlFrom(
            imageBase64Data as string,
            info.file.type,
          );
          setLoading(false);
          setImageUrl(imageBase64Url);
          if (onChangeCallBack && inputIndex !== undefined) {
            onChangeCallBack(
              {
                content: imageBase64Url,
                alt: imageAlt,
                title: imageTitle,
              },
              inputIndex,
              inputId,
            );
          }
          if (setImage) {
            setImage({
              base64Data: imageBase64Data as string,
              type: info.file.type,
              name: info.file.name,
              alt: imageAlt,
              title: imageTitle,
            });
          }
        }
      });
    }
    setLoading(false);
  };

  const handleAltChange = (e: ChangeEvent<HTMLInputElement>) => {
    setImageAlt(e.target.value);
    if (onChangeCallBack && imageUrl) {
      onChangeCallBack(
        {
          content: imageUrl,
          alt: e.target.value,
          title: imageTitle,
        },
        inputIndex,
        inputId,
      );
    }
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setImageTitle(e.target.value);
    if (onChangeCallBack && imageUrl) {
      onChangeCallBack(
        {
          content: imageUrl,
          alt: imageAlt,
          title: e.target.value,
        },
        inputIndex,
        inputId,
      );
    }
  };

  return (
    <>
      <Form.Item
        {...formItemBlockLayout}
        name={finalFieldName}
        label={formFieldLabel}
        valuePropName="file"
        className={className}
        rules={
          required && imageContent?.content == undefined
            ? [
                {
                  required: true,
                  message: t<string>("forms.errors.mandatory"),
                },
              ]
            : []
        }
      >
        <div className="d-flex flex-column flex-sm-row align-items-center">
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader w-auto me-sm-4"
            showUploadList={false}
            beforeUpload={(file) => {
              return beforeUpload(file, rules);
            }}
            onChange={handleChange}
            disabled={!editMode}
          >
            {imageContent?.content ? (
              <img
                src={imageContent.content}
                alt="avatar"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
          <div>
            <InputFormField
              showLabel
              readOnly={!editMode}
              className="mx-2 mb-3"
              fieldPrefix={`${finalFieldName}_`}
              field="alt"
              module="page.block.image"
              onChange={handleAltChange}
              initialValue={imageContent?.alt}
            />
            <InputFormField
              showLabel
              readOnly={!editMode}
              className="mx-2 mb-0"
              fieldPrefix={`${finalFieldName}_`}
              field="title"
              module="page.block.image"
              onChange={handleTitleChange}
              initialValue={imageContent?.title}
            />
          </div>
        </div>
      </Form.Item>
    </>
  );
};

UploadImageForm.defaultProps = {
  editMode: true,
};

export default UploadImageForm;
