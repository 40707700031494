import React, { useState } from "react";
import { Button, Modal } from "antd";
import { MobileOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import configuration from "@utils/Config";
import { PageInfoResponseDto } from "@state/page/dto/page.info.response.dto";

export interface PagePreviewButtonProps {
  disabled: boolean;
  page: PageInfoResponseDto;
}

const PagePreviewButton: React.FunctionComponent<PagePreviewButtonProps> = ({
  disabled,
  page,
}) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState<boolean>(false);

  const innerButton = (
    <Button
      disabled={disabled}
      type="default"
      className="btn-secondary m-2"
      onClick={() => setVisible(true)}
    >
      <MobileOutlined />
      {t<string>("detail_news.preview")}
    </Button>
  );

  return (
    <>
      {innerButton}
      <Modal
        className="page-preview"
        title={t<string>("detail_news.preview")}
        open={visible}
        centered
        footer={
          <Button key="closePreviewModal" onClick={() => setVisible(false)}>
            {t<string>("common.close")}
          </Button>
        }
        onCancel={() => setVisible(false)}
        bodyStyle={{ padding: 0 }}
      >
        <iframe
          className="preview-iframe"
          title={page.seoFriendlyTitle}
          src={`${configuration.frontendBaseUrl}/preview${page.categorySeoFriendlyTitle}/${page.id}`}
        />
      </Modal>
    </>
  );
};

export default PagePreviewButton;
