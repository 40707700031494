import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { requestGetUserInfo } from "@state/auth/AuthEffects";
import CampaignContactsForm from "@views/contacts/CampaignContactsForm";

const LeadsEventScreen: FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    void requestGetUserInfo({});
  }, []);

  return (
    <PageLayout title={t<string>("contacts.leads.title")}>
      <CampaignContactsForm />
    </PageLayout>
  );
};

export default LeadsEventScreen;
