import React from "react";
import { Tag } from "antd";
import i18n from "i18next";

export interface Props {
  status: string;
  className?: string;
}

interface PageStatus {
  label: string;
  color: string;
}

export const PAGES_STATUS: { [index: string]: PageStatus } = {
  SAVED: { label: i18n.t<string>("status.saved"), color: "#405faf" },
  PUBLISHED: { label: i18n.t<string>("status.published"), color: "#00b2a9" },
  ARCHIVED: { label: i18n.t<string>("status.archived"), color: "#888888" },
};

const PagesStatusTag: React.FunctionComponent<Props> = (props: Props) => {
  const { status, className } = props;

  return (
    <Tag className={className} color={PAGES_STATUS[status].color}>
      {PAGES_STATUS[status].label}
    </Tag>
  );
};

export default PagesStatusTag;
