import React from "react";
import { DatePicker, Form, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import moment, { Moment } from "moment";
import { InternalNamePath, Rule } from "rc-field-form/lib/interface";
import { dateFormat } from "@utils/Constant";
import "moment/locale/fr";
import { formUtils } from "@utils/form-utils";

interface PropsType {
  showLabel?: boolean;
  module: string;
  field: string | InternalNamePath;
  placeholder?: string;
  labelTooltip?: string | null;
  readOnly?: boolean;
  rules?: Rule[];
  required?: boolean;
  emptyErrorMessage?: string;
  className?: string;
  labelClassName?: string;
  fieldClassName?: string;
  onChange?: (value: Moment | null) => void;
  mode: "year" | "date";
  picker: "year" | "date";
  disabledDate?: (date: Moment) => boolean;
  format?: string;
  showTime?: boolean;
}

const DatePickerFormField: React.FunctionComponent<PropsType> = ({
  format,
  showLabel,
  labelTooltip,
  module,
  field,
  readOnly,
  className = "",
  labelClassName,
  rules = [],
  required = false,
  emptyErrorMessage,
  disabledDate,
  showTime,
  ...props
}) => {
  const { t } = useTranslation();

  const i18nField = formUtils.geti18nFieldFromField(field);

  return (
    <Form.Item
      name={typeof field === "object" ? field : String(field)}
      key={String(field)}
      label={
        readOnly || showLabel ? (
          <span className={labelClassName ?? ""}>
            {t<string>(`${module}.form.fields.${i18nField}.label`)}&nbsp;
            {labelTooltip && <Tooltip title={labelTooltip} />}
          </span>
        ) : (
          ""
        )
      }
      rules={
        readOnly
          ? []
          : [
              ...rules,
              {
                required,
                message:
                  emptyErrorMessage ?? t<string>("forms.errors.mandatory"),
              },
            ]
      }
      className={className}
    >
      <DatePicker
        getPopupContainer={(triggerNode) =>
          triggerNode.parentElement ? triggerNode.parentElement : triggerNode
        }
        disabled={readOnly}
        id={String(field)}
        placeholder={t<string>(
          `${module}.form.fields.${i18nField}.placeholder`,
        )}
        showTime={showTime ? { defaultValue: moment("00", "HH") } : false}
        disabledDate={(current) => {
          return disabledDate
            ? disabledDate(current)
            : current && current > moment();
        }}
        format={format ?? dateFormat}
        {...props}
        dropdownAlign={{
          overflow: {
            adjustY: 0, // do not auto flip in y-axis
          },
        }}
      />
    </Form.Item>
  );
};

export default DatePickerFormField;
