import { createEffect } from "effector";
import "whatwg-fetch";
import configuration from "src/utils/Config";
import {
  restCreationHandler,
  restDeletionHandler,
  restDetailsHandler,
  restListHandler,
  restUpdateHandler,
} from "@utils/rest/RestClient";
import { CategoryInfoResponseDto } from "@state/category/dto/category.info.response.dto";
import { CategoryDetailsResponseDto } from "@state/category/dto/category.details.response.dto";
import { CategorySaveRequestDto } from "@state/category/dto/category.save.request.dto";

const baseUrl = configuration.backendBaseUrl;
const categoryUrl = baseUrl + "/categories";

export const requestGetCategories = createEffect({
  handler: restListHandler<CategoryInfoResponseDto>(categoryUrl),
});
export const requestGetCategory = createEffect({
  handler: restDetailsHandler<CategoryDetailsResponseDto>(`${categoryUrl}/`),
});
export const requestCreateCategory = createEffect({
  handler: restCreationHandler<CategorySaveRequestDto, CategoryInfoResponseDto>(
    `${categoryUrl}`,
  ),
});
export const requestUpdateCategory = createEffect({
  handler: restUpdateHandler<CategorySaveRequestDto, CategoryInfoResponseDto>(
    `${categoryUrl}/`,
  ),
});
export const requestDeleteCategory = createEffect({
  handler: restDeletionHandler<{ id: string }>(`${categoryUrl}/`),
});
