import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import PageLayout from "@components/layouts/PageLayout";
import GazProfessionalForm from "./GazProfessionalForm";

const GazProfessionalScreen: FC = () => {
  const { yoocanId } = useParams<{ yoocanId: string }>();

  const { t } = useTranslation();
  const [editMode, setEditMode] = useState<boolean>(false);

  return (
    <PageLayout title={t<string>("gazProfessional.details.title")}>
      <>
        <GazProfessionalForm
          yoocanId={yoocanId ? yoocanId : ""}
          editMode={editMode}
          setEditMode={setEditMode}
        />
      </>
    </PageLayout>
  );
};

export default GazProfessionalScreen;
