import configuration from "@utils/Config";
import { createEffect } from "effector/compat";
import { restPostHandler } from "@utils/rest/RestClient";
import { ChimneyProfessionalImportSynthesis } from "@state/chimneyProfessional/dto/chimney.professional.import.synthesis";
import { File } from "@type/resource/resource.type";

const baseUrl = configuration.backendBaseUrl;
const chimneyProfessionalUrl = baseUrl + "/chimney-professionals";

export const requestImportChimneyProfessionals = createEffect({
  handler: restPostHandler<File, ChimneyProfessionalImportSynthesis>(
    `${chimneyProfessionalUrl}/import`,
  ),
});
