import i18n from "i18next";

export interface FieldErrors {
  [field: string]: string;
}

export interface ParsedResponse<T> {
  data: T | undefined;
  fileName?: string;
  responseCode: number | undefined;
  errorMessage: string | undefined;
  fieldErrors?: FieldErrors;
  ok: boolean;
}

export interface ErrorResponse {
  errors: Error[];
  errorCode?: number | undefined;
  message?: any;
  statusCode: number;
}

export interface Error {
  field: string;
  code: string;
  message?: string;
}
export class ServerResponseParser<T> {
  parseResponse(response: Response): Promise<ParsedResponse<T>> {
    if (response.ok) {
      if (response.headers.get("Content-Type") === "application/octet-stream") {
        return response.blob().then((blob) => {
          return {
            responseCode: response.status,
            errorMessage: undefined,
            data: blob as unknown as T,
            fileName: this.getFileNameFromContentDispositionHeader(
              response.headers.get("Content-Disposition") as string,
            ),
            ok: true,
          };
        });
      }
      if (response.status === 204) {
        return Promise.resolve({
          responseCode: response.status,
          data: undefined,
          ok: true,
        } as ParsedResponse<T>);
      }

      return response.text().then((text: string) => {
        const data = (text && JSON.parse(text)) as T;
        return {
          responseCode: response.status,
          errorMessage: undefined,
          data,
          ok: true,
        };
      });
    } else {
      return response.text().then((text: string) => {
        const json = (text && JSON.parse(text)) as ErrorResponse;

        const result = {
          responseCode: response.status,
          data: undefined,
          errorCode: json.errorCode,
          fieldErrors: this.createFieldsErrorsFromJsonErrors(json.errors),
          ok: false,
        };
        if (response.status === 400) {
          const errors: FieldErrors = {};
          json.errors.forEach(
            (propertyError: Error) =>
              (errors[propertyError.field] = propertyError.code),
          );
          return {
            ...result,
            errorMessage:
              json.errors.length > 0
                ? // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                  json.errors
                    .map((error) => (error.message ? error.message : ""))
                    .toString()
                : (json.message as string),
            fieldErrors: this.createFieldsErrorsFromJsonErrors(json.errors),
          };
        } else {
          return {
            ...result,
            errorMessage: json.errors
              .map(
                (error) =>
                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                  `${error.field} : ${error.message ? error.message : ""}`,
              )
              .toString(),
          };
        }
      });
    }
  }

  createFieldsErrorsFromJsonErrors = (jsonErrors: Error[]): FieldErrors => {
    const errors: FieldErrors = {};
    jsonErrors.forEach(
      (propertyError: Error) =>
        (errors[propertyError.field] = i18n.t(`error.${propertyError.code}`)),
    );
    return errors;
  };

  getFileNameFromContentDispositionHeader = (
    contentDisposition: string,
  ): string => {
    return contentDisposition.split("__")[1];
  };
}
