import { createEffect } from "effector";
import "whatwg-fetch";
import configuration from "src/utils/Config";
import {
  restDetailsHandler,
  restGetUniqueHandler,
  restListHandlerPagination,
} from "@utils/rest/RestClient";
import { ContactSearchCriteria } from "@state/contact/dto/contact.search.criteria";
import { Pageable } from "@type/pagination/pagination.types";
import {
  ContactResponseDto,
  LeadResponseDto,
} from "@state/contact/dto/contact.response.dto";

const baseUrl = configuration.backendBaseUrl;
const gazProfessionalUrl = baseUrl + "/gaz-professionals";

export const requestGetLeadsExport = createEffect({
  handler: restGetUniqueHandler<Blob, ContactSearchCriteria>(
    `${gazProfessionalUrl}/contacts/export`,
  ),
});

export const requestGetLeads = createEffect({
  handler: restListHandlerPagination<Pageable<LeadResponseDto>>(
    `${gazProfessionalUrl}/contacts/BO`,
  ),
});

export const requestGetLead = createEffect({
  handler: restDetailsHandler<LeadResponseDto>(
    `${gazProfessionalUrl}/contacts/BO/`,
  ),
});

export const requestGetContactsExport = createEffect({
  handler: restGetUniqueHandler<Blob, ContactSearchCriteria>(
    `${baseUrl}/contacts/export`,
  ),
});

export const requestGetContacts = createEffect({
  handler: restListHandlerPagination<Pageable<ContactResponseDto>>(
    `${baseUrl}/contacts/BO`,
  ),
});

export const requestGetContact = createEffect({
  handler: restDetailsHandler<ContactResponseDto>(`${baseUrl}/contacts/`),
});
