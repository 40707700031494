import { ProfileEnum } from "@utils/enums/profile.enum";

export function isProfileAmong(
  profile: ProfileEnum | undefined,
  expectedProfiles: ProfileEnum[],
): boolean {
  if (!profile) {
    return false;
  }

  return (
    profile === ProfileEnum.ADMIN ||
    expectedProfiles.length === 0 ||
    expectedProfiles.includes(profile)
  );
}
