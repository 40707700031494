import { createEffect } from "effector";
import "whatwg-fetch";
import configuration from "src/utils/Config";
import {
  restDetailsHandler,
  restGetUniqueHandler,
  restListHandlerPagination,
  restPatchHandler,
  restWorkflowHandler,
} from "@utils/rest/RestClient";
import { Pageable } from "@type/pagination/pagination.types";
import {
  PacHybrideRenewalRequestDto,
  PacHybrideWorkflowRequestDto,
} from "@state/mention/pachybride/dto/pac.hybride.workflow.request.dto";
import { MentionResponseDto } from "@state/mention/dto/mention.response.dto";
import { GazProfessionalSearchCriteria } from "@state/gazProfessional/dto/gaz.professional.search.criteria";
import { PacHybrideSaveExpiredAtRequest } from "@state/mention/pachybride/dto/pac.hybride.save.expired.at.request";
import { CommentSaveRequest } from "@state/mention/pachybride/dto/pac.hybride.save.comment.dto";
import { UpdateRgeSaveRequest } from "@state/mention/pachybride/dto/pac.hybride.save.rge.id.dto";

const baseUrl = configuration.backendBaseUrl;
const mentionUrl = baseUrl + "/mentions/pac-hybride";

export const requestGetMentionsPacHybride = createEffect({
  handler: restListHandlerPagination<Pageable<MentionResponseDto>>(mentionUrl),
});

export const requestGetMentionsPacHybrideExportExcel = createEffect({
  handler: restGetUniqueHandler<Blob, GazProfessionalSearchCriteria>(
    `${mentionUrl}/export-excel`,
  ),
});

export const requestGetMention = createEffect({
  handler: restDetailsHandler<MentionResponseDto>(`${mentionUrl}/comment/`),
});

export const requestWorkflowMention = createEffect({
  handler: restWorkflowHandler<
    PacHybrideWorkflowRequestDto,
    MentionResponseDto
  >(`${mentionUrl}/`),
});

export const requestRenewalMention = createEffect({
  handler: restPatchHandler<PacHybrideRenewalRequestDto, MentionResponseDto>(
    `${mentionUrl}/`,
    "/workflow-renewal",
  ),
});

export const requestUpdateMentionPacHybrideExpiredAt = createEffect({
  handler: restPatchHandler<PacHybrideSaveExpiredAtRequest, MentionResponseDto>(
    `${mentionUrl}/expired-at/`,
  ),
});

export const requestUpdateMentionPacHybrideComment = createEffect({
  handler: restPatchHandler<CommentSaveRequest, MentionResponseDto>(
    `${mentionUrl}/comment/`,
  ),
});

export const requestUpdateMentionPacHybrideCommentBO = createEffect({
  handler: restPatchHandler<CommentSaveRequest, MentionResponseDto>(
    `${mentionUrl}/commentBO/`,
  ),
});

export const requestUpdateRgeMention = createEffect({
  handler: restPatchHandler<UpdateRgeSaveRequest, MentionResponseDto>(
    `${mentionUrl}/rge/`,
  ),
});
