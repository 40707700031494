import configuration from "@utils/Config";
import { createEffect } from "effector";
import {
  restCreationHandler,
  restGetUniqueHandler,
  restListHandler,
  restListHandlerPagination,
} from "@utils/rest/RestClient";
import { Pageable } from "@type/pagination/pagination.types";
import { PreviousAuditItemResponseDto } from "@state/audit/dto/previous.audit.item.dto";
import {
  AuditCriteria,
  AuditGlobalStatus,
  AuditInProgress,
  AuditStatusCriteria,
  InstallationItem,
  SetInstallation,
} from "@state/audit/dto/audit.item.details.dto";

const baseUrl = configuration.backendBaseUrl;
const auditUrl = baseUrl + "/audits";

export const requestGetPreviousAudit = createEffect({
  handler: restListHandlerPagination<Pageable<PreviousAuditItemResponseDto>>(
    `${auditUrl}/back-office`,
  ),
});

export const requestGetAuditsInProgress = createEffect({
  handler: restListHandler<AuditInProgress>(
    `${auditUrl}/back-office/audit-in-progress`,
  ),
});

export const requestGetInstallationDetail = createEffect({
  handler: restGetUniqueHandler<InstallationItem, AuditCriteria>(
    `${auditUrl}/detail`,
  ),
});

export const requestSetSelectedInstallation = createEffect({
  handler: restCreationHandler<SetInstallation, void>(
    `${auditUrl}/back-office`,
  ),
});

export const requestGetAuditStatus = createEffect({
  handler: restGetUniqueHandler<AuditGlobalStatus, AuditStatusCriteria>(
    `${auditUrl}/back-office/status`,
  ),
});
