import { createStore } from "effector";
import connectLocalStorage from "effector-localstorage";
import { setAuditToProgram } from "@state/audit/AuditEvents";
import { AuditListInstallationToChooseDto } from "@state/audit/dto/previous.audit.item.dto";

const auditLocalStorage = connectLocalStorage("auditLocalStorage").onError(
  (err) => console.log(err),
);

export const auditStore = createStore<AuditListInstallationToChooseDto[]>(
  auditLocalStorage.init({ equivalence: [], rang: 0, lastStatus: "" }),
);

auditStore.on<AuditListInstallationToChooseDto[]>(
  setAuditToProgram,
  (state, payload) => payload,
);

auditStore.watch(auditLocalStorage);
