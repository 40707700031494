import { createEffect } from "effector";
import "whatwg-fetch";
import configuration from "src/utils/Config";
import { restListHandler } from "@utils/rest/RestClient";
import { BlockResponseDto } from "@state/block/dto/block.response.dto";

const baseUrl = configuration.backendBaseUrl;
const blocksUrl = baseUrl + "/blocks";

export const requestGetBlocks = createEffect({
  handler: restListHandler<BlockResponseDto>(blocksUrl),
});
