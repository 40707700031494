import { createEffect } from "effector";
import {
  restCreationHandler,
  restDeletionHandler,
  restListHandler,
  restUpdateHandler,
} from "@utils/rest/RestClient";
import { KeywordInfoResponseDTO } from "@state/keyword/dto/keyword.response.dto";
import configuration from "@utils/Config";
import { KeywordWriteRequestDTO } from "@state/keyword/dto/keyword.write.request.dto";

const baseUrl = configuration.backendBaseUrl;
const keywordsUrl = baseUrl + "/keywords";

export const requestGetKeywords = createEffect({
  handler: restListHandler<KeywordInfoResponseDTO>(keywordsUrl),
});
export const requestCreateKeyword = createEffect({
  handler: restCreationHandler<KeywordWriteRequestDTO, KeywordInfoResponseDTO>(
    keywordsUrl,
  ),
});
export const requestUptadeKeyword = createEffect({
  handler: restUpdateHandler<KeywordWriteRequestDTO, KeywordInfoResponseDTO>(
    `${keywordsUrl}/`,
  ),
});
export const requestDeleteKeyword = createEffect({
  handler: restDeletionHandler<KeywordInfoResponseDTO>(`${keywordsUrl}/`),
});
