import React, { useState } from "react";
import UploadFormField from "@components/inputs/UploadFormField";
import { File } from "@type/resource/resource.type";

interface PropsType {
  editMode?: boolean;
  initialFiles: File[];
  handleFilesChange: (files: File[]) => void;
}

const FilesBlock: React.FunctionComponent<PropsType> = ({
  editMode,
  initialFiles,
  handleFilesChange,
}) => {
  const [files, setFiles] = useState<File[]>(initialFiles);

  return (
    <>
      <UploadFormField
        module="page.block"
        field="files"
        onChange={handleFilesChange}
        formatLabel={".pdf"}
        files={files}
        setFiles={setFiles}
        editMode={editMode}
        types={[
          "application/pdf",
          "application/zip",
          "image/png",
          "image/jpeg",
          "image/webp",
          "image/avif",
        ]}
      />
    </>
  );
};

export default FilesBlock;
