import { createEffect } from "effector";
import "whatwg-fetch";
import configuration from "src/utils/Config";
import {
  restCreationHandler,
  restDeletionHandler,
  restDetailsHandler,
  restListHandlerPagination,
  restUpdateHandler,
  restWorkflowHandler,
} from "@utils/rest/RestClient";
import { PageInfoResponseDto } from "@state/page/dto/page.info.response.dto";
import { Pageable } from "@type/pagination/pagination.types";
import {
  PageSaveRequestDto,
  PageSceduledSaveRequestDto,
} from "@state/page/dto/page.save.request.dto";
import { PageWorkflowRequestDto } from "@state/page/dto/page.workflow.request.dto";
import { PageSearchCriteria } from "@state/page/dto/page.search.criteria";

const baseUrl = configuration.backendBaseUrl;
const pageUrl = baseUrl + "/pages";

export const requestGetPagesList = createEffect({
  handler: restListHandlerPagination<
    Pageable<PageInfoResponseDto>,
    PageSearchCriteria
  >(pageUrl),
});
export const requestGetPage = createEffect({
  handler: restDetailsHandler<PageInfoResponseDto>(`${pageUrl}/`),
});
export const requestCreatePage = createEffect({
  handler: restCreationHandler<PageSaveRequestDto, PageInfoResponseDto>(
    `${pageUrl}`,
  ),
});
export const requestUpdatePage = createEffect({
  handler: restUpdateHandler<PageSaveRequestDto, PageInfoResponseDto>(
    `${pageUrl}/`,
  ),
});

export const requestScheduledPage = createEffect({
  handler: restUpdateHandler<PageSceduledSaveRequestDto, PageInfoResponseDto>(
    `${pageUrl}/`,
    "/scheduled",
  ),
});
export const requestDeletePage = createEffect({
  handler: restDeletionHandler<PageInfoResponseDto>(`${pageUrl}/`),
});
export const requestWorkflowPage = createEffect({
  handler: restWorkflowHandler<PageWorkflowRequestDto, PageInfoResponseDto>(
    `${pageUrl}/`,
  ),
});
