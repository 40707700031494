import { createStore } from "effector";
import {
  authentifiedContextEvent,
  clearFilter,
  clearUser,
  logout,
  setFilterPage,
  setTablePageNumber,
  setTablePageSize,
  setUser,
} from "@state/auth/AuthEvents";
import { AuthentifiedContext, AuthentifiedUser } from "@type/auth/auth.types";
import connectLocalStorage from "effector-localstorage";
import logger from "@utils/logger";
import {
  TableFilterPayload,
  TablePageNumberPayload,
  TablePageSizePayload,
} from "@type/pagination/pagination.types";
import { requestLogout } from "@state/auth/AuthEffects";

const authentifiedLocalStorage = connectLocalStorage(
  "authentifiedStore",
).onError((err) => console.log(err));

export const authentifiedStore = createStore<AuthentifiedContext | null>(
  authentifiedLocalStorage.init(0),
);

authentifiedStore
  .on(authentifiedContextEvent, (state, payload) => {
    return payload === undefined
      ? null
      : {
          ...payload,
          dashboardsPageSizes: {},
          dashboardsPageNumber: {},
          dashboardsFilter: {},
        };
  })
  .on<AuthentifiedUser>(
    setUser,
    (state, payload: AuthentifiedUser): AuthentifiedContext => {
      return {
        user: { ...payload },
        dashboardsPageSizes: state?.dashboardsPageSizes || {},
        dashboardsPageNumber: state?.dashboardsPageNumber || {},
        dashboardsFilter: state?.dashboardsFilter || {},
      };
    },
  )
  .on(clearUser, (state) => ({
    ...state,
    user: undefined,
    dashboardsPageSizes: {},
    dashboardsPageNumber: {},
    dashboardsFilter: {},
  }))
  .on(clearFilter, (state) => ({
    ...state,
    user: state?.user,
    dashboardsPageSizes: state?.dashboardsPageSizes || {},
    dashboardsPageNumber: {},
    dashboardsFilter: {},
  }))
  .on(setTablePageSize, (state, payload: TablePageSizePayload) => {
    if (!state) {
      return null;
    }
    return {
      ...state,
      dashboardsPageSizes: {
        ...state.dashboardsPageSizes,
        [payload.property]: payload.value,
      },
    };
  })
  .on(setTablePageNumber, (state, payload: TablePageNumberPayload) => {
    if (!state) {
      return null;
    }
    return {
      ...state,
      dashboardsPageNumber: {
        ...state.dashboardsPageNumber,
        [payload.property]: payload.value,
      },
    };
  })
  .on(setFilterPage, (state, payload: TableFilterPayload) => {
    if (!state) {
      return null;
    }
    return {
      ...state,
      dashboardsFilter: {
        ...state.dashboardsFilter,
        [payload.property]: payload.value,
      },
    };
  });

authentifiedStore.watch(authentifiedLocalStorage);

logout.watch(() => {
  logger.debug("Clear storage");
  localStorage.clear();
  sessionStorage.clear();
  clearUser();
  void requestLogout({ dto: undefined });
});
