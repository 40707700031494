import { createEffect } from "effector";
import { restGetUniqueHandler, restPostHandler } from "@utils/rest/RestClient";
import { AuthentifiedUser, AuthResult } from "@type/auth/auth.types";
import configuration from "src/utils/Config";
import { ResetPasswordRequestDto } from "@state/auth/dto/reset.password.request.dto";
import { ValidateTokenRequestDto } from "@state/auth/dto/validate.token.request.dto";
import { ForgotPasswordRequestDto } from "@state/auth/dto/forgot.password.request.dto";
import { LoginRequestDto } from "@state/auth/dto/login.request.dto";

const userInfoUrl = "/auth/me";

export const requestGetUserInfo = createEffect({
  handler: restGetUniqueHandler<AuthentifiedUser>(
    configuration.backendBaseUrl + userInfoUrl,
  ),
});

export const requestForgotPassword = createEffect({
  handler: restPostHandler<ForgotPasswordRequestDto, void>(
    configuration.backendBaseUrl + "/account/forgot-password",
  ),
});
export const requestResetPassword = createEffect({
  handler: restPostHandler<ResetPasswordRequestDto, void>(
    configuration.backendBaseUrl + "/account/reset-password",
  ),
});

export const requestValidateResetToken = createEffect({
  handler: restPostHandler<ValidateTokenRequestDto, void>(
    configuration.backendBaseUrl + "/account/validate-reset-token",
  ),
});

export const requestLogin = createEffect({
  handler: restPostHandler<LoginRequestDto, AuthResult>(
    configuration.backendBaseUrl + "/auth/login",
  ),
});

export const requestLogout = createEffect({
  handler: restPostHandler<void, void>(
    configuration.backendBaseUrl + "/auth/logout",
  ),
});
