import React, { useState } from "react";
import { Button, Col, Divider, Row } from "antd";
import { useTranslation } from "react-i18next";
import shortid from "shortid";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  Block,
  BlockInput,
  ImageContent,
  ListProperties,
} from "@type/page/page.types";
import { PageBlockInput } from "@views/page/block/PageBlockInput";

export interface ListValue {
  id: string;
  value: BlockInput[];
}

interface PropsType {
  initialValues?: ListValue[];
  properties: ListProperties;
  fieldPrefix?: string;
  readOnly?: boolean;
  onChange: (value: ListValue[], inputIndex: number) => void;
  block: Block;
  indexBlock: number;
  listInputIndex: number;
  handleUpdateBlock: (block: Block, index: number) => void;
}

const ListFormField: React.FunctionComponent<PropsType> = (
  props: PropsType,
) => {
  const {
    initialValues,
    readOnly,
    onChange,
    indexBlock,
    listInputIndex,
    properties,
    handleUpdateBlock,
    block,
  } = props;

  const computeInitialValues = (): ListValue[] => {
    const result = [];
    for (let i = 0; i < properties.minLength; i++) {
      result.push({
        id: shortid.generate(),
        value: JSON.parse(JSON.stringify(properties.inputs)) as BlockInput[],
      });
    }
    return result;
  };

  const { t } = useTranslation();

  const [values, setValues] = useState<ListValue[]>(
    initialValues || computeInitialValues,
  );

  const handleContentChange = (
    content: string | ListValue[] | ImageContent,
    inputIndex?: number,
    inputId?: string,
  ) => {
    if (inputId && inputIndex != undefined) {
      const newValues = [...values];
      const listValue = newValues.find((input) => input.id === inputId);
      if (listValue) {
        listValue.value[inputIndex].content = content;
        setValues(newValues);
        onChange(values, listInputIndex);
      }
    }
  };

  const handleAddElement = () => {
    setValues([
      ...values,
      {
        id: shortid.generate(),
        value: [
          ...properties.inputs.map((input) => {
            return {
              ...input,
              content: undefined,
            };
          }),
        ],
      },
    ]);
  };

  const handleDeleteElement = (index: number) => {
    const newValues = [...values];
    newValues.splice(index, 1);
    setValues(newValues);
    onChange(newValues, listInputIndex);
  };

  return (
    <>
      {values.map((value, index) => (
        <Row key={value.id}>
          <Divider />
          {value.value.map((input, indexInput) => {
            return (
              <Col key={`${index}_${indexInput}`} xs={8} className="mx-2">
                <PageBlockInput
                  editMode={!readOnly}
                  handleContentChange={handleContentChange}
                  handleUpdateBlock={handleUpdateBlock}
                  indexInput={indexInput}
                  indexBlock={indexBlock}
                  input={input}
                  block={block}
                  inputId={value.id}
                />
              </Col>
            );
          })}
          <Col>
            {!readOnly && values.length > properties.minLength && (
              <Button
                onClick={() => handleDeleteElement(index)}
                className="btn-danger m-2"
              >
                <DeleteOutlined /> {t<string>("common.delete")}
              </Button>
            )}
          </Col>
        </Row>
      ))}
      {!readOnly &&
        (values.length < properties.maxLength ||
          properties.maxLength == undefined) && (
          <Button onClick={handleAddElement} className="btn-secondary m-2">
            <PlusCircleOutlined /> {t<string>("common.add")}
          </Button>
        )}
    </>
  );
};

export default ListFormField;
