import { restPostHandler } from "@utils/rest/RestClient";
import configuration from "@utils/Config";
import { createEffect } from "effector";
import { MailSendingRequestDto } from "@state/mails/dto/request/mail.sending.request.dto";
import { MailSendingResponseDto } from "@state/mails/dto/request/mail.sending.response.dto";

const baseUrl = configuration.backendBaseUrl;
const mailUrl = baseUrl + "/app/mails";

export const requestSendEmail = createEffect({
  handler: restPostHandler<MailSendingRequestDto, MailSendingResponseDto>(
    mailUrl,
  ),
});
