import { InstallationItem } from "@state/audit/dto/audit.item.details.dto";

const renderTitle = (id: string) => {
  let title = "";
  if (id === "2") {
    title = "Qualigaz";
  } else if (id === "3") {
    title = "Dekra";
  } else if (id === "4") {
    title = "COPRAUDIT";
  }
  return title ?? null;
};

const getAddress = (installation: InstallationItem) => {
  let address = `<div>${installation.address1 ?? ""}`;
  if ((installation.address2?.length ?? 0) > 0) {
    address = address.concat("</br>").concat(installation.address2 ?? "");
  }
  if ((installation.address3?.length ?? 0) > 0) {
    address = address.concat("</br>").concat(installation.address3 ?? "");
  }
  if ((installation.address4?.length ?? 0) > 0) {
    address = address.concat("</br>").concat(installation.address4 ?? "");
  }
  return address.concat("</div>");
};

export const auditUtils = {
  renderTitle,
  getAddress,
};
