import { TYPE_MAILS, TypeMailsEnum } from "@utils/enums/type.mails.enum";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { Col, Form, Row, TreeSelect } from "antd";
import BasicButton from "@components/buttons/BasicButton";
import { SaveOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Field } from "@type/form/field.types";
import { requestSendEmail } from "@state/mails/MailEffects";

const MailsScreen: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [fields] = useState<Field[]>([]);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [html, setHtml] = useState<string>("");
  const [template, setTemplate] = useState<TypeMailsEnum>(
    TypeMailsEnum.WORKSITE_ELIGIBLE,
  );

  const handleSubmit = () => {
    setButtonLoading(true);
    void requestSendEmail({ dto: { type: template } });
  };

  useEffect(() => {
    return requestSendEmail.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setHtml(result.data.html);
      }
      setButtonLoading(false);
    });
  });

  return (
    <PageLayout
      container
      noContentInCard={false}
      title={t<string>("mails.title")}
    >
      <>
        <h3 className="flex-grow-1 text-secondary mb-3">
          {t<string>("mails.display.title")}
        </h3>

        <Form
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          fields={fields}
        >
          <Form.Item>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <TreeSelect
                  treeData={TYPE_MAILS}
                  style={{ width: "100%" }}
                  placeholder={t<string>("mails.form.fields.type.placeholder")}
                  onChange={(value) => {
                    setTemplate(value as TypeMailsEnum);
                  }}
                />
              </Col>
            </Row>
          </Form.Item>
          <BasicButton
            type="submit"
            variant="primary"
            icon={<SaveOutlined />}
            text={t<string>("buttons.display")}
            className="ms-2 mt-2"
            isLoading={buttonLoading}
          />
        </Form>
        {html && (
          <div
            /* eslint-disable-next-line @typescript-eslint/naming-convention */
            dangerouslySetInnerHTML={{ __html: html }}
            className="mt-4 card bg-light"
          />
        )}
      </>
    </PageLayout>
  );
};

export default MailsScreen;
