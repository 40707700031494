import { FC } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { useParams } from "react-router-dom";
import OrganisationProfessionalForm from "@views/organisationProfessional/details/OrganisationProfessionalForm";

const OrganisationProfessionalScreen: FC = () => {
  const { yoocanId } = useParams<{ yoocanId: string }>();

  const { t } = useTranslation();

  return (
    <PageLayout title={t<string>("organisationProfessional.details.title")}>
      <>
        <OrganisationProfessionalForm yoocanId={yoocanId ? yoocanId : ""} />
      </>
    </PageLayout>
  );
};

export default OrganisationProfessionalScreen;
