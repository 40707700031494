import i18n from "i18next";
import React from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { alignRight } from "@utils/Constant";
import DateFormat from "@utils/DateFormat";
import { ColumnsType } from "antd/lib/table/interface";
import { WebinarItemResponse } from "@state/webinar/dto/webinar.item.response";

export const columns = (
  onClickImport: (webinar: WebinarItemResponse) => void,
  buttonLoadings: Record<string, boolean>,
): ColumnsType<WebinarItemResponse> => [
  {
    title: i18n.t<string>("webinar.list.columns.name"),
    key: "name",
    dataIndex: "name",
    ellipsis: false,
  },
  {
    title: i18n.t<string>("webinar.list.columns.startAt"),
    key: "startAt",
    dataIndex: "startAt",
    ellipsis: false,
    render: (record: string) => {
      return <span>{DateFormat.standardDateTime(new Date(record))}</span>;
    },
  },
  {
    title: i18n.t<string>("webinar.list.columns.actions"),
    key: "execute",
    fixed: alignRight,
    onCell: () => {
      return {
        onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
          e.stopPropagation(),
      };
    },
    render: (record: WebinarItemResponse) => (
      <Button
        type="text"
        size="large"
        className="btn-primary btn-list"
        onClick={() => onClickImport(record)}
        icon={<DownloadOutlined />}
        loading={buttonLoadings[record.id]}
        disabled={buttonLoadings[record.id]}
      >
        {i18n.t<string>("webinar.registrations.export.button")}
      </Button>
    ),
  },
];
