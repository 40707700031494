import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import FileSaver from "file-saver";
import { Button, Col, Divider, Form, Row } from "antd";
import PageLayout from "@components/layouts/PageLayout";
import { requestGetGazMonthExport } from "@state/gasMonth/GazEventEffect";
import { SaveOutlined } from "@ant-design/icons";
import GazMonthChimneyProfessionalImportForm from "@views/gazMonth/GazMonthChimneyProfessionalImportForm";
import { requestGetUserInfo } from "@state/auth/AuthEffects";
import { toastError, toastSuccess } from "@utils/toast-helper";

const GazMonthScreen: FC = () => {
  const { t } = useTranslation();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  useEffect(() => {
    void requestGetUserInfo({});
  }, []);

  interface GazMonthStore {
    query: string;
  }

  const handleSubmit = (values: GazMonthStore) => {
    setButtonLoading(true);
    void requestGetGazMonthExport({
      dto: {
        query: values.query,
      },
    });
  };

  useEffect(() => {
    return requestGetGazMonthExport.done.watch(({ result }) => {
      afterWs(result);
    });
  });

  const afterWs = (result: ParsedResponse<Blob>) => {
    setButtonLoading(false);
    if (result.ok && result.data && result.fileName) {
      const data = new Blob([result.data], {
        type: "application/octet-stream",
      });
      FileSaver.saveAs(data, result.fileName);
      toastSuccess(t<string>("gazMonth.export.messages.generation.success"));
    } else {
      toastError(t<string>("gazMonth.export.messages.generation.error"));
    }
  };

  return (
    <PageLayout title={t<string>("gazMonth.title")}>
      <>
        <div className="d-flex flex-column align-items-stretch flex-sm-row">
          <div className="w-100 d-flex align-items-center">
            <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
              <h3 className="text-secondary mb-0">
                {t<string>("gazMonth.export.title")}
              </h3>
            </Divider>
          </div>
        </div>
        <Form onFinish={handleSubmit}>
          <Row>
            <Col>
              <Button
                className="btn-primary my-5"
                htmlType="submit"
                loading={buttonLoading}
              >
                <SaveOutlined />{" "}
                {t<string>("gazMonth.export.form.actions.submit")}
              </Button>
            </Col>
          </Row>
        </Form>

        <GazMonthChimneyProfessionalImportForm />
      </>
    </PageLayout>
  );
};

export default GazMonthScreen;
