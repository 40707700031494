import React, { ReactElement } from "react";
import { Form, Select, Tag } from "antd";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { formUtils } from "@utils/form-utils";
import { useTranslation } from "react-i18next";

export interface SelectFieldProps {
  className?: string;
  module: string;
  field: string | InternalNamePath;
  onChange: (value: string) => void;
  showLabel?: boolean;
  readOnly?: boolean;
  fieldPrefix?: string;
  initialValue?: string;
  disabled?: boolean;
}

const options = [
  { value: "default", color: "default", label: "Pas de couleur de fond" },
  { value: "DARK", color: "#232323", label: "dark" },
  { value: "GREEN", color: "#6BA43A", label: "vert" },
  {
    value: "LIGHT_GREEN",
    color: "#DAE1D4",
    textColor: "black",
    label: "vert-léger",
  },
  { value: "BLUE", color: "#0F4291", label: "bleu" },
  {
    value: "LIGHT_BLUE",
    color: "#E7EDF4",
    textColor: "black",
    label: "bleu-léger",
  },
  { value: "LIGHT_GREY", color: "#ECECEC", textColor: "black", label: "gris" },
];

interface CustomTagProps {
  label: React.ReactNode;
  value: unknown;
  disabled: boolean;
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  closable: boolean;
}

const tagRender = (props: CustomTagProps) => {
  const { label, value, closable, onClose } = props;
  const color = value as string;

  return (
    <Tag
      color={color}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
};

const SelectBackgroundColorFormField: React.FunctionComponent<
  SelectFieldProps
> = ({
  className,
  module,
  field,
  showLabel,
  readOnly,
  fieldPrefix = "",
  initialValue,
  disabled,
  onChange,
}: SelectFieldProps): ReactElement => {
  const { t } = useTranslation();
  const i18nField = formUtils.geti18nFieldFromField(field);

  return (
    <Form.Item
      className={className}
      name={
        typeof field === "object" ? field : `${fieldPrefix}${String(field)}`
      }
      key={`${fieldPrefix}${String(field)}`}
      label={
        readOnly || showLabel ? (
          <span>
            {t<string>(`${module}.form.fields.${i18nField}.label`)}&nbsp;
          </span>
        ) : (
          ""
        )
      }
      initialValue={initialValue}
    >
      <Select
        showArrow
        tagRender={tagRender}
        defaultValue={"default"}
        style={{ width: "100%" }}
        disabled={readOnly || disabled}
        onChange={onChange}
      >
        {options.map((option) => {
          return (
            <Select.Option
              key={option.value}
              value={option.value}
              label={option.label}
            >
              <Tag
                color={option.color}
                style={{ marginRight: 3, color: option.textColor }}
              >
                {option.label}
              </Tag>
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default SelectBackgroundColorFormField;
