import { FC } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import PageForm from "@views/page/details/PageForm";

const AddPageScreen: FC = () => {
  const { t } = useTranslation();

  return (
    <PageLayout title={t<string>("addPage.title")}>
      <PageForm editMode />
    </PageLayout>
  );
};

export default AddPageScreen;
