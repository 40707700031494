import { FC, useCallback, useEffect, useState } from "react";
import { emptySearchResult } from "@utils/Constant";
import { Pageable } from "@type/pagination/pagination.types";
import { UserItemResponseDto } from "@state/users/dto/response/user.item.response.dto";
import { useTranslation } from "react-i18next";
import { Divider, Table } from "antd";
import {
  requestGetUsers,
  requestUpdateUserStatus,
} from "@state/users/UserEffects";
import PageLayout from "@components/layouts/PageLayout";
import { SpinIcon } from "@components/icons/SpinIcon";
import { PlusCircleOutlined } from "@ant-design/icons";
import { columns } from "@components/lists/UsersColumns";
import { useNavigate } from "react-router-dom";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { ROUTES } from "@routes/Routes";
import BasicButton from "@components/buttons/BasicButton";

const UsersScreen: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(20);

  const [users, setUsers] =
    useState<Pageable<UserItemResponseDto>>(emptySearchResult);

  const handleChangeLimit = (current: number, newLimit: number): void => {
    setLimit(newLimit);
  };

  const fetchUsers = useCallback(() => {
    setDataHasLoaded(false);
    requestGetUsers({
      page: pageNumber - 1,
      limit,
    })
      .catch(() => {
        toastError(t<string>("users.list.messages.loading-error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [pageNumber, limit, t]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    return requestUpdateUserStatus.done.watch(({ result }) => {
      if (result && result.ok) {
        toastSuccess(t<string>("users.list.messages.change-status-done"));
        fetchUsers();
      }
    });
  });

  useEffect(() => {
    return requestGetUsers.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setUsers(result.data);
      }
    });
  });

  return (
    <PageLayout title={t<string>("users.title")}>
      <>
        <div className="d-flex flex-column align-items-stretch flex-sm-row">
          <div className="w-100 d-flex align-items-center">
            <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
              <h3 className="text-secondary mb-0">
                {t<string>("users.list.title")}
              </h3>
            </Divider>
          </div>
          <div className="w-100 d-flex align-items-center divider-sm-transparent">
            <Divider orientation="right" className="mt-2 mt-sm-3 mb-3">
              <BasicButton
                icon={<PlusCircleOutlined />}
                text={t<string>("users.actions.add")}
                className="btn-secondary"
                onClick={() => navigate(ROUTES.admin.users.add.generate())}
              />
            </Divider>
          </div>
        </div>
        <Table
          style={{ whiteSpace: "break-spaces" }}
          locale={{ emptyText: dataHasLoaded ? "" : " " }}
          loading={{ spinning: !dataHasLoaded, indicator: SpinIcon }}
          columns={columns()}
          dataSource={users.content}
          pagination={{
            total: users.totalElements,
            pageSize: users.size,
            current: users.number + 1,
            onChange: setPageNumber,
            onShowSizeChange: handleChangeLimit,
          }}
          showSorterTooltip={false}
          rowKey={(record) => record.id}
          rowClassName="cursor-pointer"
          onRow={(record) => {
            return {
              onClick: () =>
                navigate(ROUTES.admin.users.details.generate(record.id)),
            };
          }}
        />
      </>
    </PageLayout>
  );
};

export default UsersScreen;
