import { FunctionComponent, useCallback, useEffect, useState } from "react";
import BasicModal from "@components/modals/BasicModal";
import { InstallationItem } from "@state/audit/dto/audit.item.details.dto";
import {
  requestGetInstallationDetail,
  requestSetSelectedInstallation,
} from "@state/audit/AuditEffects";
import { useStore } from "effector-react";
import { auditStore } from "@state/audit/AuditStore";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/buttons/BasicButton";
import ConfirmInstallationModal from "@components/modals/ConfirmInstallationModal";
import DateFormat from "@utils/DateFormat";
import { auditUtils } from "@views/gazProfessional/details/audit/audit-utils";
import parse from "html-react-parser";

interface AuditToProgramDetailModalProps {
  title: string;
  visible: boolean;
  onCancel: () => void;
  className?: string;
  installationId?: string;
  pgId: string;
}

const AuditToProgramDetailModal: FunctionComponent<
  AuditToProgramDetailModalProps
> = ({ title, visible, onCancel, className = "", installationId, pgId }) => {
  const { t } = useTranslation();
  const [installationsToProgram, setInstallationsToPrograms] = useState<
    InstallationItem[]
  >([]);
  const auditToProgram = useStore(auditStore);
  const lastInstallation =
    auditToProgram.length > 0
      ? auditToProgram.find((installation) => installation.id == installationId)
      : null;
  const [index, setIndex] = useState<number>(0);
  const [preSelectedInstallation, setPreSelectedInstallation] =
    useState<InstallationItem>();
  const [
    isConfirmInstallationModalVisible,
    setIsConfirmInstallationModalVisible,
  ] = useState<boolean>(false);

  const handleSelectedInstallation = useCallback(
    (installation?: InstallationItem) => {
      if (installation != undefined && lastInstallation) {
        const timerCancelAudit = new Date(lastInstallation.timerCancelAudit);
        void requestSetSelectedInstallation({
          dto: {
            installationId: installation.installationId,
            pgId: pgId,
            timerCancelAudit: DateFormat.standardDateTime(
              timerCancelAudit,
            ).substring(0, 10),
          },
        });
      }
    },
    [lastInstallation, pgId],
  );

  useEffect(() => {
    if (installationId != null && visible) {
      setInstallationsToPrograms([]);
      const installationToDetailId = lastInstallation?.equivalence;
      installationToDetailId?.forEach((installation) => {
        void requestGetInstallationDetail({
          dto: {
            installationId: installation,
            gazProfessionalId: pgId,
          },
        });
      });
    }
  }, [lastInstallation?.equivalence, installationId, pgId, visible]);

  useEffect(() => {
    return requestGetInstallationDetail.done.watch(({ result }) => {
      if (result.ok && result.data && visible) {
        const installationDetail = result.data;
        setInstallationsToPrograms((installation) => [
          {
            id: installationDetail.id,
            installationId: installationDetail.installationId,
            ocId: installationDetail.ocId,
            ocCcId: installationDetail.ocCcId,
            nameRg: installationDetail.nameRg,
            address1: installationDetail.address1,
            address2: installationDetail.address2,
            address3: installationDetail.address3,
            address4: installationDetail.address4,
            city: installationDetail.city,
            zipCode: installationDetail.zipCode,
            dateOfRegistration: installationDetail.dateOfRegistration,
          },
          ...installation,
        ]);
      }
    });
  });

  return (
    <>
      <BasicModal
        title={title}
        visible={visible}
        onCancel={onCancel}
        className={`${className}`}
        footer={null}
      >
        <>
          <div className="audit-detail-installations">
            <div className="audit-detail-installations-items">
              {installationsToProgram &&
                installationsToProgram.map((installation, index) => (
                  <div key={index} className="audit-detail-installations-item">
                    <div className="audit-detail-installations-item-choice">{`${t<string>(
                      "gazProfessional.audit.detail.installations.items.choice",
                    )} ${index + 1}`}</div>
                    <div className="audit-detail-installations-item-name">
                      {auditUtils.renderTitle(installation.ocId)}
                    </div>
                    <div className="audit-detail-installations-item-number">{`${t<string>(
                      "gazProfessional.audit.detail.installations.items.number",
                    )} ${installation.ocCcId}`}</div>
                    <div className="audit-detail-installations-item-rgi">
                      {t<string>(
                        "gazProfessional.audit.detail.installations.items.rgi",
                        { name: installation.nameRg },
                      )}
                    </div>
                    <div className="audit-detail-installations-item-address">
                      <div>{parse(auditUtils.getAddress(installation))}</div>
                      <div>
                        {installation?.zipCode
                          .concat(" ")
                          .concat(installation?.city)}
                      </div>
                    </div>
                    <BasicButton
                      text={t<string>(
                        "gazProfessional.audit.detail.installations.items.action",
                      )}
                      className="audit-detail-installations-item-action"
                      onClick={() => {
                        setPreSelectedInstallation(installation);
                        setIsConfirmInstallationModalVisible(true);
                        setIndex(index + 1);
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
        </>
      </BasicModal>
      <ConfirmInstallationModal
        titleOC={
          preSelectedInstallation
            ? auditUtils.renderTitle(preSelectedInstallation?.ocId)
            : undefined
        }
        index={index}
        preSelectedInstallation={preSelectedInstallation}
        visible={isConfirmInstallationModalVisible}
        onCancel={() => {
          setPreSelectedInstallation(undefined);
          setIsConfirmInstallationModalVisible(false);
        }}
        onConfirm={() => {
          setPreSelectedInstallation(undefined);
          setIsConfirmInstallationModalVisible(false);
          handleSelectedInstallation(preSelectedInstallation);
          onCancel();
        }}
      />
    </>
  );
};

export default AuditToProgramDetailModal;
