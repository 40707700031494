import { FC } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { useParams } from "react-router-dom";
import LeadForm from "@views/contacts/leads/LeadForm";

const ContactScreen: FC = () => {
  const { leadId } = useParams<{ leadId: string }>();

  const { t } = useTranslation();

  return (
    <PageLayout title={t<string>("contacts.contact.details.title")}>
      <>
        <LeadForm leadId={leadId ? leadId : ""} />
      </>
    </PageLayout>
  );
};

export default ContactScreen;
