import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import { CategoryStore } from "@state/category/CategoryStore";
import { requestGetCategories } from "@state/category/CategoryEffects";
import { setCategories } from "@state/category/CategoryEvents";
import { CategoryInfoResponseDto } from "@state/category/dto/category.info.response.dto";
import { toastError } from "@utils/toast-helper";

interface CategoryFilters {
  codeCategory?: string;
}

interface UseCategoriesManagerReturn {
  categories: CategoryInfoResponseDto[];
  fetchCategories: () => void;
}

export const useCategoriesManager = (): UseCategoriesManagerReturn => {
  const { t } = useTranslation();
  const categories = useStore(CategoryStore);
  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(false);

  const fetchCategories = useCallback(() => {
    requestGetCategories({}).catch(() => {
      toastError(t<string>("category.error.loading"));
    });
  }, [t]);

  useEffect(() => {
    if (!dataHasLoaded && categories.length === 0) {
      setDataHasLoaded(true);
      fetchCategories();
    }
  }, [dataHasLoaded, categories, fetchCategories]);

  useEffect(() => {
    return requestGetCategories.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setCategories(result.data);
      }
    });
  });

  return { categories, fetchCategories };
};

export const useCategories = ({
  codeCategory,
}: CategoryFilters): CategoryInfoResponseDto[] => {
  const { categories } = useCategoriesManager();

  return categories.filter(
    (category) =>
      codeCategory === undefined || category.codeCategory === codeCategory,
  );
};
