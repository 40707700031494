import { createStore } from "effector";
import {
  addKeyword,
  deleteKeyword,
  setKeywords,
  updateKeyword,
} from "./KeywordEvents";
import { KeywordInfoResponseDTO } from "@state/keyword/dto/keyword.response.dto";

export const KeywordStore = createStore<KeywordInfoResponseDTO[]>([])
  .on<KeywordInfoResponseDTO[]>(
    setKeywords,
    (state: KeywordInfoResponseDTO[], payload: KeywordInfoResponseDTO[]) =>
      payload,
  )
  .on<string>(
    deleteKeyword,
    (state: KeywordInfoResponseDTO[], payload: string) =>
      state.filter((news) => news.id !== payload),
  )
  .on<KeywordInfoResponseDTO>(
    addKeyword,
    (state: KeywordInfoResponseDTO[], payload: KeywordInfoResponseDTO) => [
      ...state,
      payload,
    ],
  )
  .on<KeywordInfoResponseDTO>(
    updateKeyword,
    (state: KeywordInfoResponseDTO[], payload: KeywordInfoResponseDTO) => [
      ...state.filter((news) => news.id !== payload.id),
      payload,
    ],
  );
