import { FC, useEffect, useState } from "react";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import UploadFormField from "@components/inputs/UploadFormField";
import { File } from "@type/resource/resource.type";
import { Form } from "antd";
import { requestGetResources } from "@state/resource/ResourceEffects";
import { mapResourceTofileType } from "@utils/upload-utils";
import { formItemBlockLayout } from "@utils/Constant";
import { toastError } from "@utils/toast-helper";

const ResourcesScreen: FC = () => {
  const { t } = useTranslation();

  const types = [
    "application/pdf",
    "application/zip",
    "application/x-zip-compressed",
  ];

  const formatLabel = "PDF - ZIP";

  useEffect(() => {
    requestGetResources({
      dto: {
        domain: [
          "MAINTENANCE",
          "INSTALLATION",
          "GLOBAL",
          "SYNTHESIS_OFFER",
          "GAZ_MEETING",
          "FLYER_RACCORDEMENT",
        ],
        ids: [],
      },
    }).catch(() => {
      toastError(t<string>("common.error.loading"));
    });
  }, [t]);

  useEffect(() => {
    return requestGetResources.done.watch(({ result }) => {
      if (result.ok && result.data && result.data.length > 0) {
        setInstallationFiles(
          result.data
            .filter((file) => file.domain === "INSTALLATION")
            .map((resource) => {
              return mapResourceTofileType(resource);
            }),
        );
        setMaintenanceFiles(
          result.data
            .filter((file) => file.domain === "MAINTENANCE")
            .map((resource) => {
              return mapResourceTofileType(resource);
            }),
        );
        setGlobalFiles(
          result.data
            .filter((file) => file.domain === "GLOBAL")
            .map((resource) => {
              return mapResourceTofileType(resource);
            }),
        );
        setSynthesisOfferFiles(
          result.data
            .filter((file) => file.domain === "SYNTHESIS_OFFER")
            .map((resource) => {
              return mapResourceTofileType(resource);
            }),
        );
        setRaccordementFlyerFiles(
          result.data
            .filter((file) => file.domain === "FLYER_RACCORDEMENT")
            .map((resource) => {
              return mapResourceTofileType(resource);
            }),
        );
        setGazMeetingFiles(
          result.data
            .filter((file) => file.domain === "GAZ_MEETING")
            .map((resource) => {
              return mapResourceTofileType(resource);
            }),
        );
      }
    });
  });

  const [installationFiles, setInstallationFiles] = useState<File[]>([]);
  const [maintenanceFiles, setMaintenanceFiles] = useState<File[]>([]);
  const [globalFiles, setGlobalFiles] = useState<File[]>([]);
  const [synthesisOfferFiles, setSynthesisOfferFiles] = useState<File[]>([]);
  const [raccordementFlyerFiles, setRaccordementFlyerFiles] = useState<File[]>(
    [],
  );
  const [gazMeetingFiles, setGazMeetingFiles] = useState<File[]>([]);

  return (
    <PageLayout
      title={t<string>("resource.title")}
      bodyClassName="app-resources"
    >
      <Form {...formItemBlockLayout} className="resources-form">
        <div className="resources-form-item">
          <h5>{t<string>("resource.form.fields.installation.label")}</h5>
          <UploadFormField
            multiple
            saveFile
            module="resource"
            files={installationFiles}
            field="installation"
            domain="INSTALLATION"
            types={types}
            formatLabel={formatLabel}
            draggable
            setFiles={setInstallationFiles}
          />
        </div>
        <div className="resources-form-item">
          <h5>{t<string>("resource.form.fields.maintenance.label")}</h5>
          <UploadFormField
            multiple
            saveFile
            module="resource"
            files={maintenanceFiles}
            field="maintenance"
            domain="MAINTENANCE"
            types={types}
            formatLabel={formatLabel}
            draggable
            setFiles={setMaintenanceFiles}
          />
        </div>
        <div className="resources-form-item">
          <h5>{t<string>("resource.form.fields.global.label")}</h5>
          <UploadFormField
            multiple
            saveFile
            module="resource"
            files={globalFiles}
            field="global"
            domain="GLOBAL"
            types={types}
            formatLabel={formatLabel}
            draggable
            setFiles={setGlobalFiles}
          />
        </div>
        <div className="resources-form-item">
          <h5>{t<string>("resource.form.fields.synthesisOffer.label")}</h5>
          <UploadFormField
            saveFile
            module="resource"
            files={synthesisOfferFiles}
            field="synthesisOffer"
            domain="SYNTHESIS_OFFER"
            types={types}
            formatLabel={formatLabel}
            unique
            draggable
            setFiles={setSynthesisOfferFiles}
          />
        </div>
        <div className="resources-form-item">
          <h5>{t<string>("resource.form.fields.gazMeeting.label")}</h5>
          <UploadFormField
            multiple
            saveFile
            module="resource"
            files={gazMeetingFiles}
            field="gazMeeting"
            domain="GAZ_MEETING"
            types={types}
            formatLabel={formatLabel}
            draggable
            setFiles={setGazMeetingFiles}
          />
        </div>
        <div className="resources-form-item">
          <h5>{t<string>("resource.form.fields.raccordementFlyer.label")}</h5>
          <UploadFormField
            saveFile
            unique
            module="resource"
            files={raccordementFlyerFiles}
            field="gazMeeting"
            domain="FLYER_RACCORDEMENT"
            types={types}
            formatLabel={formatLabel}
            draggable
            setFiles={setRaccordementFlyerFiles}
          />
        </div>
      </Form>
    </PageLayout>
  );
};

export default ResourcesScreen;
