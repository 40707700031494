import { FC, useCallback, useEffect, useState } from "react";
import { Button, Divider, Form, Empty, Table, Row, Col } from "antd";

import { useTranslation } from "react-i18next";

import {
  requestDeletePage,
  requestGetPagesList,
  requestWorkflowPage,
} from "src/state/page/PageEffects";
import { deletePage, updatePage } from "src/state/page/PageEvents";
import { PlusCircleOutlined } from "@ant-design/icons/lib";

import { emptySearchResult } from "src/utils/Constant";
import { useNavigate } from "react-router-dom";
import { Pageable } from "@type/pagination/pagination.types";
import { PageInfoResponseDto } from "@state/page/dto/page.info.response.dto";
import PageLayout from "@components/layouts/PageLayout";
import { SpinIcon } from "@components/icons/SpinIcon";
import { columns } from "@components/lists/PagesColumns";
import { CategoryInfoResponseDto } from "@state/category/dto/category.info.response.dto";
import { useCategories } from "@state/category/Categories";
import { SelectFormField } from "@components/inputs/SelectFormField";
import { PAGE_STATUS } from "@utils/enums/page.status";
import CategoryTreeFormField from "@components/category/CategoryTreeFormField";
import InputFormField from "@components/inputs/InputFormField";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import {
  setFilterPage,
  setTablePageNumber,
  setTablePageSize,
} from "@state/auth/AuthEvents";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { ROUTES } from "@routes/Routes";

interface FilterObject {
  status: string[];
  title: string;
  category: string;
}

const PagesScreen: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authentifiedContext = useStore(authentifiedStore);

  const [pageNumber, setPageNumber] = useState<number>(
    authentifiedContext?.dashboardsPageNumber?.["pages"] || 1,
  );
  const [pageSize, setPageSize] = useState<number>(
    authentifiedContext?.dashboardsPageSizes?.["pages"] || 10,
  );

  const [pages, setPages] =
    useState<Pageable<PageInfoResponseDto>>(emptySearchResult);

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

  const onChangePageSize = (current: number, newPageSize: number): void => {
    setPageSize(newPageSize);
    setTablePageSize({
      value: newPageSize,
      property: "pages",
    });
  };

  const onChangePageNumber = (current: number): void => {
    setPageNumber(current);
    setTablePageNumber({
      value: current,
      property: "pages",
    });
  };

  const categories: CategoryInfoResponseDto[] = useCategories({});

  const contextFilter: FilterObject | undefined = authentifiedContext
    ?.dashboardsFilter?.["pages"] as FilterObject | undefined;

  const [category, setCategory] = useState<string>(
    contextFilter?.category || "",
  );
  const [status, setStatus] = useState<string[]>(
    contextFilter?.status || ["PUBLISHED", "SAVED"],
  );
  const [title, setTitle] = useState<string>(contextFilter?.title || "");

  const InitialValues = {
    categoryId: category.length === 0 ? undefined : category,
    query: title,
    status: status,
  };

  const onChangeCategory = (current: string): void => {
    setCategory(current);
    setFilterPage({
      value: {
        title: contextFilter?.title,
        category: current,
        status: contextFilter?.status,
      },
      property: "pages",
    });
    onChangePageNumber(1);
  };

  const onChangeTitle = (current: string): void => {
    setTitle(current);
    setFilterPage({
      value: { title: current, category: category, status: status },
      property: "pages",
    });
    onChangePageNumber(1);
  };

  const onChangeStatus = (current: string[]): void => {
    setStatus(current);
    setFilterPage({
      value: { title: title, category: category, status: current },
      property: "pages",
    });
    onChangePageNumber(1);
  };

  const fetchPages = useCallback(() => {
    setDataHasLoaded(false);
    requestGetPagesList({
      page: pageNumber - 1,
      limit: pageSize,
      dto: {
        query: title,
        status: status.length === 0 ? ["PUBLISHED", "SAVED"] : status,
        categoryId: category,
      },
    })
      .catch(() => {
        toastError(t<string>("notification.void messages.error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [pageNumber, pageSize, t, status, category, title]);

  useEffect(() => {
    fetchPages();
  }, [fetchPages]);

  useEffect(() => {
    return requestGetPagesList.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setPages(result.data);
      }
    });
  });

  useEffect(() => {
    return requestDeletePage.done.watch(({ params, result }) => {
      if (result.ok) {
        deletePage(params.dto.id);
        toastSuccess(t<string>("page.globalButtons.delete.success"));
        fetchPages();
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    return requestWorkflowPage.done.watch(({ result }) => {
      if (result.ok && result.data) {
        updatePage(result.data);
        if (result.data.status === "SAVED") {
          toastSuccess(t<string>("page.globalButtons.unPublish.success"));
        }
        if (result.data.status === "PUBLISHED") {
          toastSuccess(t<string>("page.globalButtons.publish.success"));
        }
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  return (
    <PageLayout title={t<string>("pages.title")}>
      <>
        <Divider orientation="left">
          <h3 className="text-secondary mb-0">{t<string>("page.filter")}</h3>
        </Divider>
        <Form initialValues={InitialValues}>
          <Row>
            <Col xs={24} sm={10}>
              <InputFormField
                showLabel
                module="page"
                field="query"
                onPressEnter={(e) => {
                  e.preventDefault();
                  onChangeTitle(e.currentTarget.value);
                }}
              />
            </Col>
            <Col xs={24} sm={8}>
              <CategoryTreeFormField
                categories={categories}
                module="page"
                fieldName="categoryId"
                onChange={onChangeCategory}
                allowClear
                multiple
              />
            </Col>
            <Col xs={24} sm={6}>
              <SelectFormField
                mode="multiple"
                allowClear
                showLabel
                module="page"
                field="status"
                options={PAGE_STATUS}
                onChange={(value) => {
                  onChangeStatus(
                    Array.isArray(value)
                      ? value
                      : value != undefined
                      ? [value]
                      : [],
                  );
                }}
              />
            </Col>
          </Row>
        </Form>
        <div className="d-flex flex-column align-items-stretch flex-sm-row">
          <div className="w-100 d-flex align-items-center">
            <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
              <h3 className="text-secondary mb-0">
                {t<string>("pages.listTitle")}
              </h3>
            </Divider>
          </div>
          <div className="w-100 d-flex align-items-center divider-sm-transparent">
            <Divider orientation="right" className="mt-2 mt-sm-3 mb-3">
              <Button
                type="default"
                className="btn-secondary"
                onClick={() => navigate(ROUTES.cms.pages.add.generate())}
              >
                <PlusCircleOutlined /> {t<string>("page.globalButtons.add")}
              </Button>
            </Divider>
          </div>
        </div>
        <Table
          style={{ whiteSpace: "break-spaces" }}
          locale={{
            emptyText: <Empty description={t<string>("pages.table.noData")} />,
          }}
          loading={{ spinning: !dataHasLoaded, indicator: SpinIcon }}
          columns={columns(categories, navigate)}
          dataSource={pages.content}
          pagination={{
            total: pages.totalElements,
            pageSize: pages.size,
            current: pages.number + 1,
            onChange: onChangePageNumber,
            onShowSizeChange: onChangePageSize,
            showSizeChanger: true,
          }}
          showSorterTooltip={false}
          rowKey={(record) => record.id}
          rowClassName="cursor-pointer"
          onRow={(record) => {
            return {
              onClick: () =>
                navigate(ROUTES.cms.pages.details.generate(record.id)),
            };
          }}
        />
      </>
    </PageLayout>
  );
};

export default PagesScreen;
