import React from "react";
import { Tag } from "antd";
import i18n from "i18next";

export interface Props {
  status: string;
  className?: string;
}

interface MentionStatus {
  label: string;
  color: string;
  value: string;
}

export const MENTION_STATUS: { [index: string]: MentionStatus } = {
  PENDING: {
    label: i18n.t<string>("mention.labels.status.PENDING"),
    color: "#405faf",
    value: "PENDING",
  },
  ACCEPTED: {
    label: i18n.t<string>("mention.labels.status.ACCEPTED"),
    color: "#00b2a9",
    value: "ACCEPTED",
  },
  REFUSED: {
    label: i18n.t<string>("mention.labels.status.REFUSED"),
    color: "#e2003c",
    value: "REFUSED",
  },
  EXPIRED: {
    label: i18n.t<string>("mention.labels.status.EXPIRED"),
    color: "#888888",
    value: "EXPIRED",
  },
};

export const MENTION_SMOKE_DUCT_STATUS: { [index: string]: MentionStatus } = {
  PENDING: {
    label: i18n.t<string>("mention.labels.status.PENDING"),
    color: "#405faf",
    value: "PENDING",
  },
  ACCEPTED: {
    label: i18n.t<string>("mention.labels.status.ACCEPTED"),
    color: "#00b2a9",
    value: "ACCEPTED",
  },
};

const MentionStatusTag: React.FunctionComponent<Props> = (props: Props) => {
  const { status, className } = props;

  return (
    <Tag className={className} color={MENTION_STATUS[status].color}>
      {MENTION_STATUS[status].label}
    </Tag>
  );
};

export default MentionStatusTag;
