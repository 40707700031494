import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Empty, Form, Table } from "antd";
import { Loader } from "@components/loader/Loader";
import { OrganisationProfessionalItemResponseDto } from "@state/organisationProfessional/dto/organisation.professional.item.response.dto";
import { SorterResult } from "antd/es/table/interface";
import { Pageable } from "@type/pagination/pagination.types";
import { emptySearchResult } from "@utils/Constant";
import { OptionType } from "@type/form/field.types";
import DropdownFilter from "@components/filters/DropdownFilter";
import InputFormField from "@components/inputs/InputFormField";
import {
  setFilterPage,
  setTablePageNumber,
  setTablePageSize,
} from "@state/auth/AuthEvents";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { requestGetOrganisationProfessionalRGRequests } from "@state/organisationProfessional/OrganisationProfessionalEffets";
import { columns } from "@views/organisationProfessional/details/OrganisationProfessionalRGRequestsColumns";
import { OrganisationProfessionalRGRequestItemResponseDto } from "@state/organisationProfessional/dto/organisation.professional.rgrequest.item.response.dto";
import { RgRequestStatus } from "@utils/enums/gaz.professional.qualifications.rg-requests.status.enum";
import { RGRequestType } from "@utils/enums/gaz.professional.qualifications.rg-requests.type.enum";
import {
  RG_REQUEST_FILTERS_STATUS,
  RG_REQUEST_FILTERS_TYPE,
} from "@utils/enums/organisation.professional.rgrequest.filters.enum";
import {
  maptoRgRequestSpeciality,
  maptoRgRequestStatus,
  maptoRgRequestType,
} from "@utils/rgRequestMappers";
import { RG_REQUEST_SPECIALITY } from "@utils/enums/gaz.professional.qualifications.rg-requests.speciality.enum";
import { toastError } from "@utils/toast-helper";

export interface Props {
  organisationProfessional?: OrganisationProfessionalItemResponseDto;
}

export interface RGRequestItem {
  id: string;
  pgId: number;
  creationDate: string;
  reference: string;
  typeLabel: string;
  rg: string;
  status: RgRequestStatus;
  type: RGRequestType;
  isInstallation: boolean;
}

const RGRequestsList: React.FunctionComponent<Props> = ({
  organisationProfessional,
}) => {
  const { t } = useTranslation();
  const authentifiedContext = useStore(authentifiedStore);

  const [rgRequests, setRgRequests] =
    useState<Pageable<OrganisationProfessionalRGRequestItemResponseDto>>(
      emptySearchResult,
    );

  const [localQuery, setLocalQuery] = useState<string>("");
  const InitialValues = {
    query: localQuery,
  };

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

  const [selectedType, setSelectedType] = useState<OptionType>();
  const [selectedStatus, setSelectedStatus] = useState<OptionType>();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(
    authentifiedContext?.dashboardsPageSizes?.[
      "organisationprofessionals-rgrequest"
    ] || 10,
  );
  const [sorter, setSorter] = useState<SorterResult<any>>({
    columnKey: "creationDate",
    order: "descend",
  });

  const fetchPgs = useCallback(() => {
    if (organisationProfessional) {
      setDataHasLoaded(false);
      requestGetOrganisationProfessionalRGRequests({
        page: pageNumber - 1,
        limit: pageSize,
        sorter,
        dto: {
          query: localQuery,
          type: selectedType?.value,
          status: selectedStatus?.value,
        },
        id: organisationProfessional.id,
      }).catch(() => {
        toastError(
          t<string>("organisationProfessional.details.listRGRequests.error"),
        );
      });
    }
  }, [
    organisationProfessional,
    pageNumber,
    pageSize,
    t,
    sorter,
    localQuery,
    selectedType,
    selectedStatus,
  ]);

  useEffect(() => {
    fetchPgs();
  }, [fetchPgs]);

  useEffect(() => {
    return requestGetOrganisationProfessionalRGRequests.done.watch(
      ({ result: { data, ok } }) => {
        if (ok && data) {
          setRgRequests(data);
          setDataHasLoaded(true);
        } else {
          toastError(
            t<string>("organisationProfessional.details.listRGRequests.error"),
          );
        }
      },
    );
  });

  function mapToRequest(
    rgRequest: OrganisationProfessionalRGRequestItemResponseDto,
  ) {
    const type = maptoRgRequestType(rgRequest.type);
    const speciality = maptoRgRequestSpeciality(rgRequest.speciality);
    const status = maptoRgRequestStatus(rgRequest.status);
    return {
      id: rgRequest.id,
      pgId: rgRequest.pgId,
      creationDate: rgRequest.creationDate,
      reference: rgRequest.reference,
      typeLabel: `${t(type.label)} ${t(speciality.label)}`,
      rg: `${rgRequest.lastName} ${rgRequest.firstName}`,
      status,
      type,
      isInstallation: speciality === RG_REQUEST_SPECIALITY.INSTALLATION,
    };
  }

  function buildRequests() {
    return rgRequests.content.map((rgRequest) => mapToRequest(rgRequest));
  }

  const onChangePageSize = (current: number, newPageSize: number): void => {
    setPageSize(newPageSize);
    setTablePageSize({
      value: newPageSize,
      property: "organisationprofessionals-rgrequest",
    });
  };

  const onChangePageNumber = (current: number): void => {
    setPageNumber(current);
    setTablePageNumber({
      value: current,
      property: "organisationprofessionals-rgrequest",
    });
  };

  const onChangeQuery = (current: string): void => {
    setLocalQuery(current);
    setFilterPage({
      value: {
        query: current,
      },
      property: "organisationprofessionals-rgrequest",
    });
    onChangePageNumber(1);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-end flex-wrap my-3 gap-3">
        <h3 className="flex-grow-1 text-primary mb-0">{`${
          rgRequests.totalElements
        } ${t(
          "organisationProfessional.details.listRGRequests.rgRequests",
        )}`}</h3>
      </div>
      <div className="d-flex align-items-center justify-content-end flex-wrap mb-3 gap-3">
        <div className="d-flex align-items-center justify-content-end flex-wrap gap-3">
          <DropdownFilter
            label={t<string>(
              "organisationProfessional.details.listRGRequests.filters.type.label",
            )}
            items={RG_REQUEST_FILTERS_TYPE}
            selectedItem={selectedType}
            setSelectedItem={setSelectedType}
          />
          <DropdownFilter
            label={t<string>(
              "organisationProfessional.details.listRGRequests.filters.status.label",
            )}
            items={RG_REQUEST_FILTERS_STATUS}
            selectedItem={selectedStatus}
            setSelectedItem={setSelectedStatus}
          />
          <Form initialValues={InitialValues}>
            <InputFormField
              module="organisationProfessional.details.listRGRequests.search"
              field="query"
              onPressEnter={(e) => {
                e.preventDefault();
                onChangeQuery(e.currentTarget.value);
              }}
            />
          </Form>
        </div>
      </div>
      <Table
        style={{ whiteSpace: "break-spaces" }}
        locale={{
          emptyText: <Empty description={t<string>("common.noData")} />,
        }}
        columns={columns()}
        dataSource={buildRequests()}
        loading={{
          spinning: !dataHasLoaded,
          indicator: <Loader />,
        }}
        size="small"
        rowKey={(record: RGRequestItem) => record?.id}
        className="app-contact-pro-table pro-table-large-font"
        pagination={{
          total: rgRequests.totalElements,
          pageSize: rgRequests.size,
          current: rgRequests.number + 1,
          onChange: setPageNumber,
          onShowSizeChange: onChangePageSize,
          showSizeChanger: true,
        }}
        onChange={(pagination, filters, sorter) =>
          setSorter(sorter as SorterResult<any>)
        }
      />
    </>
  );
};

export default RGRequestsList;
