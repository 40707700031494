import i18n from "i18next";
import { ReactNode } from "react";
import BasicTag from "@components/tags/BasicTag";
import { InfoCircleOutlined } from "@ant-design/icons";
import DateFormat from "@utils/DateFormat";
import { Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { RG } from "@state/professional/dto/professional.qualifications.response.dto";
import { maptoQualificationRgStatus } from "@utils/rgStatusMapper";

export const columns = (): ColumnsType<RG> => [
  {
    title: i18n.t("gazProfessional.qualifications.table.headers.name"),
    dataIndex: "name",
    key: "name",
    className: "name",
    ellipsis: {
      showTitle: false,
    },
    sorter: false,
  },
  {
    title: i18n.t(
      "gazProfessional.qualifications.table.headers.qualificationDeadline",
    ),
    key: "qualificationDeadline",
    className: "qualificationDeadline",
    ellipsis: {
      showTitle: false,
    },
    width: 160,
    render: (record: RG): ReactNode => {
      const status = maptoQualificationRgStatus(record.status);
      return (
        <div className="d-flex justify-content-end">
          <BasicTag
            label={DateFormat.standardDate(new Date(record.expirationDate))}
            variant={status.variant}
            className="d-inline"
            icon={
              <Tooltip placement="bottomRight" title={i18n.t(status.label)}>
                <InfoCircleOutlined />
              </Tooltip>
            }
          />
        </div>
      );
    },
    sorter: false,
  },
];
