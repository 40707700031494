import React from "react";
import { Tag } from "antd";
import i18n from "i18next";

export interface Props {
  status: string;
  className?: string;
}

interface WorksiteResourceStatus {
  label: string;
  color: string;
  value: string;
}

export const WORKSITE_RESOURCE_STATUS: {
  [index: string]: WorksiteResourceStatus;
} = {
  INIT: {
    label: i18n.t<string>("worksite.labels.statusResource.PENDING"),
    color: "#405faf",
    value: "INIT",
  },
  VALIDATED: {
    label: i18n.t<string>("worksite.labels.statusResource.VALIDATED"),
    color: "#00b2a9",
    value: "VALIDATED",
  },
  REFUSED: {
    label: i18n.t<string>("worksite.labels.statusResource.REFUSED"),
    color: "#e2003c",
    value: "REFUSED",
  },
};

const WorksiteResourceStatusTag: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const { status, className } = props;
  const statusObject = Object.values(WORKSITE_RESOURCE_STATUS).find(
    (aps) => aps.value === status,
  );

  return (
    <>
      {statusObject && (
        <Tag className={className} color={statusObject.color}>
          {statusObject.label}
        </Tag>
      )}
    </>
  );
};

export default WorksiteResourceStatusTag;
