import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import UserForm from "./UserForm";
import PageLayout from "@components/layouts/PageLayout";
import { useParams } from "react-router-dom";

const UserScreen: FC = () => {
  const { userId } = useParams<{ userId: string }>();

  const { t } = useTranslation();
  const [editMode, setEditMode] = useState<boolean>(false);

  return (
    <PageLayout
      title={t<string>(editMode ? "users.edit.title" : "users.details.title")}
    >
      <>
        <UserForm
          userId={userId ? userId : ""}
          editMode={editMode}
          setEditMode={setEditMode}
        />
      </>
    </PageLayout>
  );
};

export default UserScreen;
