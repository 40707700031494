import { createStore } from "effector";
import { BlockResponseDto } from "@state/block/dto/block.response.dto";
import { setBlocksStore } from "@state/block/BlockEvents";

export const BlockStore = createStore<BlockResponseDto[]>([]).on<
  BlockResponseDto[]
>(
  setBlocksStore,
  (state: BlockResponseDto[], payload: BlockResponseDto[]) => payload,
);
