import { OptionType } from "@type/form/field.types";
import i18n from "i18next";

export enum PageStatus {
  PUBLISHED = "PUBLISHED",
  VALIDATION = "VALIDATION",
  SAVED = "SAVED",
}

export const PAGE_STATUS = [
  {
    value: "PUBLISHED",
    label: i18n.t<string>("status.published"),
  },
  {
    value: "ARCHIVED",
    label: i18n.t<string>("status.archived"),
  },
  {
    value: "SAVED",
    label: i18n.t<string>("status.saved"),
  },
] as OptionType[];
