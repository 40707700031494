import { FunctionComponent, useEffect, useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import BasicButton from "../buttons/BasicButton";
import {
  requestResetPassword,
  requestValidateResetToken,
} from "@state/auth/AuthEffects";
import { useForm } from "antd/lib/form/Form";
import { logout } from "@state/auth/AuthEvents";
import { extractStringFrom } from "@utils/query-helper";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import { passwordHelper, ValidationPassword } from "@utils/password-helper";
import PageLayout from "@components/layouts/PageLayout";
import InputFormField from "@components/inputs/InputFormField";
import SubmitButton from "@components/buttons/SubmitButton";
import PasswordValidationTooltip from "@components/tooltips/PasswordValidationTooltip";
import { formUtils } from "@utils/form-utils";
import { ROUTES } from "@routes/Routes";
import { toastError, toastSuccess } from "@utils/toast-helper";

interface SetPasswordProps {
  isCreation?: boolean;
}

interface FormValues {
  credential: string;
  confirmCredential: string;
}

export const validationPassword: ValidationPassword = {
  character: 9,
  upperCase: 1,
  lowerCase: 1,
  number: 1,
  special: 1,
};

const SetPasswordComponent: FunctionComponent<SetPasswordProps> = (
  props: SetPasswordProps,
) => {
  const { isCreation } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = useForm();

  const InitialValues = {
    credential: "",
    confirmCredential: "",
  };

  const [tokenValue, setTokenValue] = useState<string | null | undefined>();
  const [userIdValue, setUserIdValue] = useState<string | null | undefined>();
  const [passwordValidationFailed, setPasswordValidationFailed] =
    useState<boolean>(false);
  const [password, setPassword] = useState<string>("");

  function getParams(): {
    token: string | undefined;
    userId: string | undefined;
  } {
    const searchParams = new URLSearchParams(window.location.search);

    const token = searchParams.get("token");
    const userId = searchParams.get("userId");

    const tokenValue = extractStringFrom(token);
    const userIdValue = extractStringFrom(userId);
    return { token: tokenValue, userId: userIdValue };
  }

  useEffect(() => {
    const { token, userId } = getParams();
    // remove token from url to prevent http referer leakage
    navigate(window.location.pathname, { replace: true });

    void requestValidateResetToken({
      dto: {
        token: token || "",
        userId: userId || "",
      },
    }).then((result) => {
      if (result.ok) {
        setTokenValue(token);
        setUserIdValue(userId);
      } else {
        navigate(ROUTES.auth.login.generate());
      }
    });
  }, [navigate]);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setPassword(value);
    if (value === "" || passwordHelper.validate(value, validationPassword)) {
      setPasswordValidationFailed(false);
    } else {
      setPasswordValidationFailed(true);
    }
  };

  const handleSubmit = (values: any) => {
    const obj = values as FormValues;

    requestResetPassword({
      dto: {
        userType: "ADMIN_USER",
        token: tokenValue || "",
        userId: userIdValue || "",
        credential: obj.credential,
        confirmCredential: obj.confirmCredential,
      },
    })
      .then((result: ParsedResponse<void>) => {
        if (result.ok) {
          toastSuccess(
            t<string>(
              `${isCreation ? "create" : "reset"}-password.messages.success`,
            ),
          );
          logout();
          navigate(ROUTES.auth.login.generate());
        } else {
          if (result.fieldErrors) {
            if (result.fieldErrors.credential === "3001") {
              form.setFields([{ name: "credential", errors: [""] }]);
            } else {
              form.setFields(
                formUtils.addErrorsToFieldsData([], result.fieldErrors),
              );
            }
          }
          toastError(result.errorMessage);
        }
      })
      .catch((error: Error) => {
        toastError(error.message);
      });
  };

  return (
    <PageLayout
      noHeader
      noSideMenu
      title={t<string>(`${isCreation ? "create" : "reset"}-password.title`)}
      bodyClassName="login-body"
    >
      <>
        <Form onFinish={handleSubmit} form={form} initialValues={InitialValues}>
          <InputFormField
            onChange={onChange}
            module="set-password"
            type="password"
            field="credential"
            required
          />
          {passwordValidationFailed && (
            <PasswordValidationTooltip password={password} />
          )}
          <InputFormField
            module="set-password"
            type="password"
            field="confirmCredential"
            className="mb-3"
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("credential") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    t<string>("set-password.form.errors.unmatch"),
                  );
                },
              }),
            ]}
            required
          />
          <div className="d-flex align-items-center justify-content-center mt-2">
            <BasicButton
              text={t<string>("buttons.back")}
              variant="tertiary"
              className="mt-2 me-2"
              onClick={() => navigate(ROUTES.auth.login.generate())}
            />
            <div className="form-group mb-0">
              <SubmitButton
                module="set-password"
                isSubmitting={false}
                label="save"
                className="mt-2"
              />
            </div>
          </div>
        </Form>
      </>
    </PageLayout>
  );
};

export default SetPasswordComponent;
