import configuration from "@utils/Config";
import { createEffect } from "effector";
import { restGetUniqueHandler } from "@utils/rest/RestClient";
import { GazEventSearchCriteria } from "@state/gasMonth/dto/gaz.event.search.criteria";

const baseUrl = configuration.backendBaseUrl;
const gazMonthUrl = baseUrl + "/gaz-event";

export const requestGetGazMonthExport = createEffect({
  handler: restGetUniqueHandler<Blob, GazEventSearchCriteria>(
    `${gazMonthUrl}/export`,
  ),
});
