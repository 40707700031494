import React, { useCallback, useEffect, useState } from "react";
import { Empty, Table, Tooltip } from "antd";
import parse from "html-react-parser";
import { Pageable } from "@type/pagination/pagination.types";
import { emptySearchResult } from "@utils/Constant";
import { columns } from "@components/lists/PreviousAuditsColumns";
import { SorterResult } from "antd/es/table/interface";
import { PreviousAuditItemResponseDto } from "@state/audit/dto/previous.audit.item.dto";
import {
  requestGetAuditsInProgress,
  requestGetAuditStatus,
  requestGetPreviousAudit,
} from "@state/audit/AuditEffects";
import { useTranslation } from "react-i18next";
import { GazProfessionalDetailsResponseDto } from "@state/gazProfessional/dto/gaz.professional.details.response.dto";
import {
  AuditGlobalStatus,
  AuditInProgress,
  ProgrammingAuditItem,
} from "@state/audit/dto/audit.item.details.dto";
import {
  AUDIT_GLOBAL_STATUS,
  AUDIT_PROGRAMMING_STATUS,
} from "@utils/enums/audit.enum";
import { IconCanceled, IconWaiting } from "../../../../static/icons";
import BasicCard from "@components/cards/BasicCard";
import BasicButton from "@components/buttons/BasicButton";
import { setAuditToProgram } from "@state/audit/AuditEvents";
import AuditToProgramDetailModal from "@components/modals/AuditToProgramDetailModal";
import AuditShowOldInstallationModal from "@components/modals/AuditShowOldInstallationModal";
import AuditDetailModal from "@components/modals/AuditDetailModal";
import BasicTag from "@components/tags/BasicTag";
import { toastError } from "@utils/toast-helper";

export interface Props {
  gazProfessional?: GazProfessionalDetailsResponseDto;
}

const AuditDetail: React.FunctionComponent<Props> = ({ gazProfessional }) => {
  const { t } = useTranslation();
  const [audits, setAudits] = useState<AuditInProgress[]>([]);
  const [previousAudits, setPreviousAudits] =
    useState<Pageable<PreviousAuditItemResponseDto>>(emptySearchResult);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [sorter, setSorter] = useState<SorterResult<any>>({
    columnKey: "date",
    order: "descend",
  });
  const [
    detailAuditToProgramModalVisible,
    setDetailAuditToProgramModalVisible,
  ] = useState<boolean>(false);
  const [detailAuditModalVisible, setDetailAuditModalVisible] =
    useState<boolean>(false);
  const [
    auditShowOldInstallationModalVisible,
    setAuditShowOldInstallationModalVisible,
  ] = useState<boolean>(false);
  const [installationId, setInstallationId] = useState<string>("");
  const [programmingAuditItems, setProgrammingAuditItems] = useState<
    ProgrammingAuditItem[]
  >([]);

  const onChangeLimit = (current: number, newLimit: number): void => {
    setLimit(newLimit);
  };

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);
  const [auditGlobalStatus, setAuditGlobalStatus] =
    useState<AuditGlobalStatus>();

  const fetchPreviousAudits = useCallback(() => {
    if (gazProfessional) {
      setDataHasLoaded(false);
      requestGetPreviousAudit({
        page: pageNumber - 1,
        limit,
        sorter,
        dto: { id: gazProfessional.id },
      })
        .catch(() => {
          toastError(t<string>("notification.void messages.error"));
        })
        .finally(() => {
          setDataHasLoaded(true);
        });
    }
  }, [gazProfessional, pageNumber, limit, sorter, t]);

  const fetchAuditInProgress = useCallback(() => {
    if (gazProfessional) {
      setDataHasLoaded(false);
      requestGetAuditsInProgress({
        dto: { id: gazProfessional.id },
      })
        .catch(() => {
          toastError(t<string>("notification.void messages.error"));
        })
        .finally(() => {
          setDataHasLoaded(true);
        });
    }
  }, [gazProfessional, t]);

  useEffect(() => {
    fetchPreviousAudits();
    fetchAuditInProgress();
    if (gazProfessional?.id) {
      void requestGetAuditStatus({
        dto: { gazProfessionalId: gazProfessional.id },
      });
    }
  }, [fetchAuditInProgress, fetchPreviousAudits, gazProfessional]);

  useEffect(() => {
    return requestGetPreviousAudit.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setPreviousAudits(result.data);
      }
    });
  });

  useEffect(() => {
    return requestGetAuditsInProgress.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setAudits(result.data);
        const progrAudit = result.data
          ?.filter((item) => item.isProgrammable)
          .map((item) => {
            return {
              id:
                item?.isProgrammable && item?.isProgrammable.length > 0
                  ? item.isProgrammable.filter((e) => e != null)[0]
                  : "",
              equivalence: item.isProgrammable ?? [],
              rang: item.programmingAuditItems.filter(
                (status) => status.auditStatus === "ANNULE",
              ).length,
              lastStatus:
                item.programmingAuditItems[
                  item.programmingAuditItems.length - 1
                ].auditStatus,
              timerCancelAudit:
                item.programmingAuditItems[
                  item.programmingAuditItems.length - 1
                ].timeBeforeAuditCancelled ?? "",
              timerChoixCC:
                item.programmingAuditItems[
                  item.programmingAuditItems.length - 1
                ].timeBeforeChoiceCC ?? "",
            };
          });
        setAuditToProgram(progrAudit);
      }
    });
  });

  const getLastProgrammingItem = (audit: AuditInProgress) => {
    return audit?.programmingAuditItems?.slice(-1)[0];
  };

  const isBlock = (audit: AuditInProgress) => {
    return (
      audit.programmingAuditItems.filter((a) => a.auditStatus === "ANNULE")
        .length === 3
    );
  };

  useEffect(() => {
    return requestGetAuditStatus.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setAuditGlobalStatus(result.data);
      }
    });
  });

  return (
    <>
      <div className="app-audit-list">
        <BasicCard className="audit-card card-no-margin-mobile audit-list-situation">
          <>
            <div className="audit-list-situation-recap">
              <h4 className="audit-list-situation-recap-title">
                {t<string>("gazProfessional.audit.situation.recap.title")}
                {auditGlobalStatus && (
                  <div className="audit-list-situation-title-status-tag">
                    <BasicTag
                      label={t<string>(
                        `${
                          AUDIT_GLOBAL_STATUS[auditGlobalStatus.pgStatus].label
                        }`,
                      )}
                      variant={t<string>(
                        `${
                          AUDIT_GLOBAL_STATUS[auditGlobalStatus.pgStatus]
                            .variant
                        }`,
                      )}
                      className="d-flex align-items-end justify-content-center"
                    />
                  </div>
                )}
              </h4>
              <div className="audit-list-situation-recap-table">
                <div className="audit-list-situation-recap-table-header">
                  <div className="audit-list-situation-recap-table-title title-id">
                    {`${audits.length} ${t<string>(
                      "gazProfessional.audit.situation.recap.table.headers.id",
                    )}`}
                  </div>
                  <div className="audit-list-situation-recap-table-title title-programming">
                    {t<string>(
                      "gazProfessional.audit.situation.recap.table.headers.first",
                    )}
                  </div>
                  <div className="audit-list-situation-recap-table-title title-programming">
                    {t<string>(
                      "gazProfessional.audit.situation.recap.table.headers.second",
                    )}
                  </div>
                  <div className="audit-list-situation-recap-table-title title-programming">
                    {t<string>(
                      "gazProfessional.audit.situation.recap.table.headers.third",
                    )}
                  </div>
                  <div className="audit-list-situation-recap-table-title title-programming">
                    {t<string>(
                      "gazProfessional.audit.situation.recap.table.headers.threshold",
                    )}
                  </div>
                  <div className="audit-list-situation-recap-table-title title-programming">
                    {t<string>(
                      "gazProfessional.audit.situation.recap.table.headers.action",
                    )}
                  </div>
                </div>
                <div className="audit-list-situation-recap-table-rows">
                  {audits
                    .sort(function (a, b) {
                      return (
                        parseInt(b.timerNextAction) -
                        parseInt(a.timerNextAction)
                      );
                    })
                    .map((audit, index) => {
                      const isBlocked =
                        getLastProgrammingItem(audit)?.auditStatus === "ANNULE";
                      const isWaiting =
                        getLastProgrammingItem(audit)?.auditStatus ===
                        "EN_ATTENTE_CC";
                      const isWaitingValidation =
                        getLastProgrammingItem(audit)?.auditStatus ===
                        "WAITING_VALIDATION";
                      const nbCanceled = audit?.programmingAuditItems?.filter(
                        (a) => a.auditStatus === "ANNULE",
                      ).length;

                      return (
                        <div
                          key={audit.id}
                          className="audit-list-situation-recap-table-row"
                        >
                          <div className="audit-list-situation-recap-table-value table-id">
                            <div className="table-id-title">
                              {`${t<string>(
                                "gazProfessional.audit.situation.recap.table.row.id",
                              )}${index + 1}`}
                            </div>
                            <div className="table-id-days">
                              <IconWaiting />
                              {`${audit.timerNextAction} ${t<string>(
                                "gazProfessional.audit.situation.recap.table.row.limitDays",
                              )}`}
                            </div>
                            <div className="table-id-status">
                              <>
                                {isWaiting &&
                                  t(
                                    "gazProfessional.audit.situation.recap.table.row.programmingStatus.waiting",
                                  )}
                                {isWaitingValidation &&
                                  t(
                                    "gazProfessional.audit.situation.recap.table.row.programmingStatus.waitingValidation",
                                  )}
                                {!audit.programmingAuditItems[0] && (
                                  <div className="status-success">
                                    {t<string>(
                                      "gazProfessional.audit.situation.recap.table.row.new",
                                    )}
                                  </div>
                                )}
                                {nbCanceled > 0 &&
                                  !isWaiting &&
                                  !isWaitingValidation && (
                                    <div className="status-canceled">
                                      <IconCanceled />
                                      {`${nbCanceled} ${t<string>(
                                        "gazProfessional.audit.situation.recap.table.row.canceled",
                                      )}`}
                                    </div>
                                  )}
                              </>
                            </div>
                          </div>
                          <div className="audit-list-situation-recap-table-value table-programming">
                            <div
                              className={`table-programming-card card-${
                                AUDIT_PROGRAMMING_STATUS[
                                  audit.programmingAuditItems[0].auditStatus
                                ].variant
                              }`}
                            >
                              <Tooltip
                                title={parse(
                                  t(
                                    "gazProfessional.audit.situation.recap.table.row.tooltip",
                                    {
                                      ccId: audit.programmingAuditItems[0].ccId,
                                      ocCcId:
                                        audit.programmingAuditItems[0].ocCcId ??
                                        "",
                                    },
                                  ),
                                )}
                              >
                                {audit.programmingAuditItems[0].auditStatus ===
                                  "ANNULE" && <IconCanceled />}
                                {t<string>(
                                  AUDIT_PROGRAMMING_STATUS[
                                    audit.programmingAuditItems[0].auditStatus
                                  ].label,
                                )}
                              </Tooltip>
                            </div>
                          </div>
                          <div className="audit-list-situation-recap-table-value table-programming">
                            {audit.programmingAuditItems[1] && (
                              <div
                                className={`table-programming-card card-${
                                  AUDIT_PROGRAMMING_STATUS[
                                    audit.programmingAuditItems[1].auditStatus
                                  ].variant
                                }`}
                              >
                                <Tooltip
                                  title={parse(
                                    t<string>(
                                      "gazProfessional.audit.situation.recap.table.row.tooltip",
                                      {
                                        ccId: audit.programmingAuditItems[1]
                                          .ccId,
                                        ocCcId:
                                          audit.programmingAuditItems[1]
                                            .ocCcId ?? "",
                                      },
                                    ),
                                  )}
                                >
                                  <>
                                    {audit.programmingAuditItems[1]
                                      .auditStatus === "ANNULE" && (
                                      <IconCanceled />
                                    )}
                                    {t<string>(
                                      AUDIT_PROGRAMMING_STATUS[
                                        audit.programmingAuditItems[1]
                                          .auditStatus
                                      ].label,
                                    )}
                                  </>
                                </Tooltip>
                              </div>
                            )}
                          </div>
                          <div className="audit-list-situation-recap-table-value table-programming">
                            {audit.programmingAuditItems[2] && (
                              <div
                                className={`table-programming-card card-${
                                  AUDIT_PROGRAMMING_STATUS[
                                    audit.programmingAuditItems[2].auditStatus
                                  ].variant
                                }`}
                              >
                                <Tooltip
                                  title={parse(
                                    t<string>(
                                      "gazProfessional.audit.situation.recap.table.row.tooltip",
                                      {
                                        ccId: audit.programmingAuditItems[2]
                                          .ccId,
                                        ocCcId:
                                          audit.programmingAuditItems[2]
                                            .ocCcId ?? "",
                                      },
                                    ),
                                  )}
                                >
                                  <>
                                    {audit.programmingAuditItems[2]
                                      .auditStatus === "ANNULE" && (
                                      <IconCanceled />
                                    )}
                                    {t<string>(
                                      AUDIT_PROGRAMMING_STATUS[
                                        audit.programmingAuditItems[2]
                                          .auditStatus
                                      ].label,
                                    )}
                                  </>
                                </Tooltip>
                              </div>
                            )}
                          </div>
                          <div className="audit-list-situation-recap-table-value table-programming">
                            {isBlocked && (
                              <div className="table-programming-card card-danger">
                                <div className="text-center">
                                  <div>
                                    {t<string>(
                                      "gazProfessional.audit.situation.recap.table.row.threshold1",
                                    )}
                                  </div>
                                  <div>
                                    {t<string>(
                                      "gazProfessional.audit.situation.recap.table.row.threshold2",
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="audit-list-situation-recap-table-value table-action">
                            {isBlock(audit) ? (
                              <BasicButton
                                size="sm"
                                text={t<string>(
                                  "gazProfessional.audit.situation.recap.table.row.action.seeAuditCanceled",
                                )}
                                onClick={() => {
                                  setAuditShowOldInstallationModalVisible(true);
                                  setProgrammingAuditItems(
                                    audit.programmingAuditItems,
                                  );
                                }}
                              />
                            ) : (
                              <BasicButton
                                size="sm"
                                text={
                                  !audit.isProgrammable
                                    ? t<string>(
                                        "gazProfessional.audit.situation.recap.table.row.action.seeDetails",
                                      )
                                    : t<string>(
                                        "gazProfessional.audit.situation.recap.table.row.action.program",
                                      )
                                }
                                onClick={() => {
                                  if (audit.isProgrammable) {
                                    setDetailAuditToProgramModalVisible(true);
                                    setInstallationId(
                                      audit?.isProgrammable[0]
                                        ? audit.isProgrammable[0]
                                        : "0",
                                    );
                                  } else {
                                    setDetailAuditModalVisible(true);
                                    setProgrammingAuditItems(
                                      audit.programmingAuditItems,
                                    );
                                  }
                                }}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </>
        </BasicCard>
        <div className="mt-4">
          <h2>{t<string>("gazProfessional.audit.historic.title")}</h2>
          <>
            <Table
              locale={{
                emptyText: <Empty description={t<string>("common.noData")} />,
              }}
              columns={columns}
              dataSource={previousAudits.content}
              loading={!dataHasLoaded}
              size="small"
              className="app-contact-pro-table"
              pagination={{
                total: previousAudits.totalElements,
                pageSize: previousAudits.size,
                current: previousAudits.number + 1,
                onChange: setPageNumber,
                onShowSizeChange: onChangeLimit,
                showSizeChanger: true,
              }}
              onChange={(pagination, filters, sorter) =>
                setSorter(sorter as SorterResult<any>)
              }
            />
          </>
        </div>
      </div>
      <AuditShowOldInstallationModal
        title={t<string>(
          "Veuillez contacter votre organisme de contrôle pour choisir une installation parmi ces propositions",
        )}
        visible={auditShowOldInstallationModalVisible}
        onCancel={() => setAuditShowOldInstallationModalVisible(false)}
        programmingAuditItems={programmingAuditItems}
        pgId={gazProfessional?.id ?? ""}
        className={"app-modal-detail-installation"}
      />

      <AuditToProgramDetailModal
        title={t<string>("gazProfessional.audit.detail.installations.title")}
        visible={detailAuditToProgramModalVisible}
        onCancel={() => setDetailAuditToProgramModalVisible(false)}
        installationId={installationId}
        pgId={gazProfessional?.id ?? ""}
        className={"app-modal-detail-installation"}
      />
      <AuditDetailModal
        title={t<string>("gazProfessional.audit.modals.auditDetail.title")}
        visible={detailAuditModalVisible}
        onCancel={() => setDetailAuditModalVisible(false)}
        programmingAuditItem={
          programmingAuditItems[programmingAuditItems.length - 1]
        }
        pgId={gazProfessional?.id ?? ""}
        className={"app-modal-detail-installation"}
      />
    </>
  );
};

export default AuditDetail;
