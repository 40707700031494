export interface Field {
  name: string[];
  value: string | number | undefined;
}

export interface OptionType {
  value: string;
  label: string;
}

export const FORMS_FIELD_TYPE = {
  TEXT: "TEXT",
  SELECT: "SELECT",
  CUSTOM: "CUSTOM",
  NUMBER: "NUMBER",
  CHECKBOX: "CHECKBOX",
};

export interface TreeType {
  label: string;
  value: string;
  selectable: boolean;
  children: TreeType[];
}
