import configuration from "src/utils/Config";
import { createEffect } from "effector";
import {
  restCreationMultipartHandler,
  restDeletionHandler,
  restDetailsHandler,
  restListHandler,
  restPatchHandler,
} from "@utils/rest/RestClient";
import { ResourceDetailsResponseDto } from "@state/resource/dto/resource.details.response.dto";
import { ResourceSearchCriteria } from "@state/resource/dto/resource.search.criteria";
import { ResourceSaveRequestDto } from "@state/resource/dto/resource.save.request.dto";

const baseUrl = configuration.backendBaseUrl;
const resourcesUrl = baseUrl + "/resources";

export const requestGetResourceMetadata = createEffect({
  handler: restDetailsHandler<ResourceDetailsResponseDto>(`${resourcesUrl}/`),
});

export const requestGetResource = createEffect({
  handler: restDetailsHandler<ResourceDetailsResponseDto>(
    `${resourcesUrl}/`,
    "/content",
  ),
});

export const requestGetResources = createEffect({
  handler: restListHandler<ResourceDetailsResponseDto, ResourceSearchCriteria>(
    `${resourcesUrl}`,
  ),
});

export const requestUpdateResource = createEffect({
  handler: restPatchHandler<ResourceSaveRequestDto, ResourceDetailsResponseDto>(
    `${resourcesUrl}/`,
  ),
});

export const requestCreateResource = createEffect({
  handler: restCreationMultipartHandler<ResourceDetailsResponseDto>(
    `${resourcesUrl}`,
  ),
});

export const requestDeleteResource = createEffect({
  handler: restDeletionHandler(`${resourcesUrl}/`),
});
