import { FunctionComponent } from "react";
import BasicModal from "@components/modals/BasicModal";
import BasicButton from "@components/buttons/BasicButton";
import { useTranslation } from "react-i18next";
import { InstallationItem } from "@state/audit/dto/audit.item.details.dto";
import { auditUtils } from "@views/gazProfessional/details/audit/audit-utils";
import parse from "html-react-parser";

interface ConfirmInstallationModalProps {
  preSelectedInstallation: InstallationItem | undefined;
  visible: boolean;
  index: number;
  onCancel: () => void;
  onConfirm: () => void;
  className?: string;
  titleOC?: string;
}

const ConfirmInstallationModal: FunctionComponent<
  ConfirmInstallationModalProps
> = ({
  preSelectedInstallation,
  visible,
  index,
  onCancel,
  onConfirm,
  className = "",
  titleOC,
}) => {
  const { t } = useTranslation();

  return (
    <BasicModal
      title={t<string>(
        "gazProfessional.audit.detail.installations.modal.title",
      )}
      visible={visible}
      onCancel={onCancel}
      className={`app-modal-confirm-installation ${className}`}
      footer={
        <>
          <div className="d-flex align-items-center justify-content-center">
            <BasicButton
              text={t<string>(
                "gazProfessional.audit.detail.installations.modal.confirm",
              )}
              onClick={onConfirm}
            />
          </div>
        </>
      }
    >
      <>
        {preSelectedInstallation && (
          <div className="confirm-installation-item-container">
            <div className="confirm-installation-item">
              <div className="confirm-installation-item-choice">{`${t<string>(
                "gazProfessional.audit.detail.installations.items.choice",
              )} ${index}`}</div>
              <div className="confirm-installation-item-name">
                {titleOC ?? preSelectedInstallation.ocId}
              </div>
              <div className="confirm-installation-item-number">{`${t<string>(
                "gazProfessional.audit.detail.installations.items.number",
              )} ${preSelectedInstallation.ocCcId}`}</div>
              <div className="confirm-installation-item-rgi">
                {`${preSelectedInstallation.dateOfRegistration} - ${t<string>(
                  "gazProfessional.audit.detail.installations.items.rgi",
                  { name: preSelectedInstallation?.nameRg },
                )}`}
              </div>
              <div className="confirm-installation-item-address">
                <div>
                  {parse(auditUtils.getAddress(preSelectedInstallation))}
                </div>
                <div>
                  {preSelectedInstallation?.zipCode
                    .concat(" ")
                    .concat(preSelectedInstallation?.city)}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="confirm-installation-text">
          {t<string>("gazProfessional.audit.detail.installations.modal.text")}
        </div>
      </>
    </BasicModal>
  );
};

export default ConfirmInstallationModal;
