import { createEffect } from "effector";
import configuration from "src/utils/Config";
import {
  restDetailsHandler,
  restIdListHandlerPagination,
  restListHandlerPagination,
} from "@utils/rest/RestClient";
import { Pageable } from "@type/pagination/pagination.types";
import { OrganisationProfessionalItemResponseDto } from "@state/organisationProfessional/dto/organisation.professional.item.response.dto";
import { OrganisationProfessionalSearchCriteria } from "@state/organisationProfessional/dto/organisation.professional.search.criteria";
import { OrganisationProfessionalGPSearchCriteria } from "@state/organisationProfessional/dto/organisation.professional.gaz.professional.search.criteria";
import { OrganisationProfessionalRGSearchCriteria } from "@state/organisationProfessional/dto/organisation.professional.responsable.gaz.search.criteria";
import { OrganisationProfessionalRGRequestSearchCriteria } from "@state/organisationProfessional/dto/organisation.professional.rg-request.search.criteria";
import { OrganisationProfessionalGPItemResponseDto } from "@state/organisationProfessional/dto/organisation.professional.gaz.professional.item.response.dto";
import { OrganisationProfessionalRGItemResponseDto } from "@state/organisationProfessional/dto/organisation.professional.responsable.gaz.item.response.dto";
import { OrganisationProfessionalRGRequestItemResponseDto } from "@state/organisationProfessional/dto/organisation.professional.rgrequest.item.response.dto";

const baseUrl = configuration.backendBaseUrl;
const organisationProfessionalUrl = baseUrl + "/organisation-professionals";

export const requestGetOrganisationProfessionalsList = createEffect({
  handler: restListHandlerPagination<
    Pageable<OrganisationProfessionalItemResponseDto>,
    OrganisationProfessionalSearchCriteria
  >(organisationProfessionalUrl),
});

export const requestGetOrganisationProfessional = createEffect({
  handler: restDetailsHandler<OrganisationProfessionalItemResponseDto>(
    `${organisationProfessionalUrl}/`,
  ),
});

export const requestGetOrganisationProfessionalPGs = createEffect({
  handler: restIdListHandlerPagination<
    Pageable<OrganisationProfessionalGPItemResponseDto>,
    OrganisationProfessionalGPSearchCriteria
  >(`${organisationProfessionalUrl}/`, "/pgs"),
});

export const requestGetOrganisationProfessionalRGs = createEffect({
  handler: restIdListHandlerPagination<
    Pageable<OrganisationProfessionalRGItemResponseDto>,
    OrganisationProfessionalRGSearchCriteria
  >(`${organisationProfessionalUrl}/`, "/rgs"),
});

export const requestGetOrganisationProfessionalRGRequests = createEffect({
  handler: restIdListHandlerPagination<
    Pageable<OrganisationProfessionalRGRequestItemResponseDto>,
    OrganisationProfessionalRGRequestSearchCriteria
  >(`${organisationProfessionalUrl}/`, "/rg-requests"),
});

export const requestGetOrganisationProfessionalPGsExportExcel = createEffect({
  handler: restDetailsHandler<Blob>(
    `${organisationProfessionalUrl}/`,
    "/pgs/export-excel",
  ),
});

export const requestGetOrganisationProfessionalRGsExportExcel = createEffect({
  handler: restDetailsHandler<Blob>(
    `${organisationProfessionalUrl}/`,
    "/rgs/export-excel",
  ),
});
