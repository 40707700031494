import React from "react";
import { Form, Tooltip, TreeSelect } from "antd";
import { useTranslation } from "react-i18next";
import { CategoryInfoResponseDto } from "@state/category/dto/category.info.response.dto";
import { QuestionCircleOutlined } from "@ant-design/icons";

interface PropsType {
  module: string;
  fieldName: string;
  categories: CategoryInfoResponseDto[];
  readOnly?: boolean;
  required?: boolean;
  labelTooltip?: string;
  onChange?: (value: string) => void;
  allowClear?: boolean;
  multiple?: boolean;
}

interface CategoryHashTableContent extends CategoryInfoResponseDto {
  children: CategoryHashTableContent[];
  value: string;
}
interface HashTable<T> {
  [key: string]: T;
}

const CategoryTreeFormField: React.FunctionComponent<PropsType> = ({
  module,
  fieldName,
  categories,
  readOnly,
  required = false,
  labelTooltip,
  onChange,
  allowClear,
  multiple,
}) => {
  const { t } = useTranslation();

  const createTreeCategoriesFromCategories = (): CategoryInfoResponseDto[] => {
    const hashTable: HashTable<CategoryHashTableContent> = {};
    categories.forEach(
      (category) =>
        (hashTable[category.id] = {
          ...category,
          children: [],
          value: category.id,
        }),
    );
    const dataTree: CategoryInfoResponseDto[] = [];
    categories.forEach((category) => {
      if (category.parentCategoryId)
        hashTable[category.parentCategoryId].children.push(
          hashTable[category.id],
        );
      else dataTree.push(hashTable[category.id]);
    });
    return dataTree;
  };

  return (
    <>
      <Form.Item
        name={fieldName}
        key={fieldName}
        label={
          <span>
            {t<string>(`${module}.form.fields.${fieldName}.label`)}&nbsp;
            {labelTooltip && (
              <Tooltip title={labelTooltip}>
                {" "}
                <QuestionCircleOutlined />
              </Tooltip>
            )}
          </span>
        }
        rules={
          required
            ? [
                {
                  required: true,
                  message: t<string>("forms.errors.mandatory"),
                },
              ]
            : []
        }
      >
        <TreeSelect
          multiple={multiple}
          allowClear={allowClear}
          onChange={onChange}
          disabled={readOnly}
          style={{ width: "100%" }}
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          treeData={createTreeCategoriesFromCategories()}
          placeholder={t<string>(
            `${module}.form.fields.${fieldName}.placeholder`,
          )}
          treeDefaultExpandAll
        />
      </Form.Item>
    </>
  );
};

export default CategoryTreeFormField;
