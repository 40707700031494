import React from "react";
import { Tag } from "antd";
import i18n from "i18next";

export interface Props {
  status: string;
  className?: string;
}

interface AttestationPacStatus {
  label: string;
  color: string;
  value: string;
}

export const ATTESTATION_PAC_STATUS: { [index: string]: AttestationPacStatus } =
  {
    PENDING: {
      label: i18n.t<string>("mention.labels.statusRge.PENDING"),
      color: "#405faf",
      value: "PENDING",
    },
    VALIDATED: {
      label: i18n.t<string>("mention.labels.statusRge.VALIDATED"),
      color: "#00b2a9",
      value: "VALIDATED",
    },
    SOONEXPIRED: {
      label: i18n.t<string>("mention.labels.statusRge.SOON_EXPIRED"),
      color: "#888888",
      value: "SOON_EXPIRED",
    },
    EXPIRED: {
      label: i18n.t<string>("mention.labels.statusRge.EXPIRED"),
      color: "#e2003c",
      value: "EXPIRED",
    },
    REFUSED: {
      label: i18n.t<string>("mention.labels.statusRge.REFUSED"),
      color: "#e2003c",
      value: "REFUSED",
    },
  };

const MentionAttestationPacStatusTag: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const { status, className } = props;
  const statusObject = Object.values(ATTESTATION_PAC_STATUS).find(
    (aps) => aps.value === status,
  );

  return (
    <>
      {statusObject && (
        <Tag className={className} color={statusObject.color}>
          {statusObject.label}
        </Tag>
      )}
    </>
  );
};

export default MentionAttestationPacStatusTag;
