import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider } from "antd";
import BasicButton from "@components/buttons/BasicButton";
import { GazProfessionalDetailsResponseDto } from "@state/gazProfessional/dto/gaz.professional.details.response.dto";
import {
  requestGetGazProfessional,
  requestPostGazProfessionalNewUrlInitialisation,
} from "@state/gazProfessional/GazProfessionalEffets";
import { LoadingOutlined } from "@ant-design/icons";
import { GazProfessionalStatus } from "@utils/enums/gaz.professional.status";
import { toastError } from "@utils/toast-helper";

const GazProfessionalSupportInitialization: FC = () => {
  const { t } = useTranslation();
  const [url, setUrl] = useState<string>("");
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [gazProfessional, setGazProfessional] =
    useState<GazProfessionalDetailsResponseDto>();

  const onClickCreateUrl = useCallback(() => {
    if (gazProfessional?.username) {
      setButtonLoading(true);
      void requestPostGazProfessionalNewUrlInitialisation({
        dto: {
          username: gazProfessional.username,
        },
      });
    }
  }, [gazProfessional?.username]);

  useEffect(() => {
    return requestPostGazProfessionalNewUrlInitialisation.done.watch(
      ({ result }) => {
        if (result.ok && result.data && result.data.url) {
          setUrl(result.data.url);
        } else {
          setUrl("");
          toastError(t<string>("gazProfessional.support.initialisation.error"));
        }
        setButtonLoading(false);
      },
    );
  });

  useEffect(() => {
    return requestGetGazProfessional.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setGazProfessional(result.data);
      }
    });
  }, []);

  return gazProfessional ? (
    <div className="mb-5">
      <Divider orientation="left">
        <h3 className="text-secondary mb-0">
          {gazProfessional?.status === GazProfessionalStatus.INIT
            ? t<string>("gazProfessional.support.initialisation.title")
            : t<string>("gazProfessional.support.reset.title")}
        </h3>
      </Divider>
      <div className="px-3">
        <BasicButton
          type="button"
          variant="primary"
          text={t<string>("gazProfessional.support.initialisation.button")}
          onClick={onClickCreateUrl}
        />
        {buttonLoading ? (
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        ) : (
          <div className="mt-3">{url}</div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default GazProfessionalSupportInitialization;
