import React, { useEffect } from "react";
import { Divider, Form } from "antd";
import { useTranslation } from "react-i18next";
import { Block } from "src/type/page/page.types";
import { requestGetBlocks } from "@state/block/BlockEffects";
import { setBlocksStore } from "@state/block/BlockEvents";
import { PageAddBlocButton } from "@views/page/block/PageAddBlocButton";
import { PageBlock } from "@views/page/block/PageBlock";
import { toastError } from "@utils/toast-helper";

export interface Props {
  editMode?: boolean;
  blocks: Block[];
  setBlocks: (blocks: Block[]) => void;
}

export const PageBlocks: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { blocks, setBlocks, editMode } = props;

  useEffect(() => {
    void requestGetBlocks({});
  }, []);

  useEffect(() => {
    return requestGetBlocks.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setBlocksStore(result.data);
      } else {
        toastError(t<string>("templates.load_failed"));
      }
    });
  });

  const handleAddBlock = (block: Block, index?: number) => {
    if (index !== undefined) {
      const newBlocs = [...blocks];
      newBlocs.splice(index, 0, block);
      setBlocks(newBlocs);
    } else {
      setBlocks([...blocks, block]);
    }
  };

  const handleUpdateBlock = (block: Block, index: number) => {
    const newBlocs = [...blocks];
    newBlocs[index] = block;
    setBlocks(newBlocs); //
  };

  const handleDeleteBlock = (index: number) => {
    const newBlocs = [...blocks];
    newBlocs.splice(index, 1);
    setBlocks(newBlocs);
  };

  const handleAscendBlock = (index: number) => {
    const block = blocks[index];
    const newBlocs = [...blocks];
    newBlocs.splice(index, 1);
    newBlocs.splice(index - 1, 0, block);
    setBlocks(newBlocs);
  };

  const handleDescendBlock = (index: number) => {
    const block = blocks[index];
    const newBlocs = [...blocks];
    newBlocs.splice(index, 1);
    newBlocs.splice(index + 1, 0, block);
    setBlocks(newBlocs);
  };

  return (
    <>
      <div className="d-flex flex-column align-items-stretch flex-sm-row">
        <div className="w-100 d-flex align-items-center">
          <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
            <h3 className="text-secondary mb-0">{t<string>("page.blocks")}</h3>
          </Divider>
        </div>
        {editMode && blocks.length < 1 && (
          <div className="w-100 d-flex align-items-center divider-sm-transparent">
            <Divider orientation="right" className="mt-2 mt-sm-3 mb-3">
              <Form.Item className="mb-0">
                <PageAddBlocButton
                  buttonLabel={t<string>("page.globalButtons.block.add")}
                  handleAddBlock={handleAddBlock}
                />
              </Form.Item>
            </Divider>
          </div>
        )}
      </div>
      {editMode && blocks.length < 1 && (
        <div className="mb-4 text-center">{t<string>("page.noBlocks")}</div>
      )}
      {blocks.map((block: Block, index) => (
        <React.Fragment key={block.idTempLocal}>
          <Form.Item className="mb-0">
            <PageBlock
              indexBlock={index}
              block={block}
              handleUpdateBlock={handleUpdateBlock}
              handleDeleteBlock={handleDeleteBlock}
              handleAddBlock={handleAddBlock}
              handleAscendBlock={
                blocks.length > 1 && index > 0 ? handleAscendBlock : undefined
              }
              handleDescendBlock={
                blocks.length > 1 && index < blocks.length - 1
                  ? handleDescendBlock
                  : undefined
              }
              editMode={editMode}
            />
          </Form.Item>
        </React.Fragment>
      ))}
      {editMode && blocks.length >= 1 && (
        <div className="d-flex justify-content-center">
          <PageAddBlocButton
            buttonLabel={t<string>("page.globalButtons.block.add")}
            handleAddBlock={handleAddBlock}
          />
        </div>
      )}
    </>
  );
};

PageBlocks.defaultProps = {
  editMode: true,
};
