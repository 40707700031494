import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Divider, Form, Modal, Row, Spin } from "antd";
import {
  requestGetMention,
  requestRenewalMention,
  requestUpdateMentionPacHybrideComment,
  requestUpdateMentionPacHybrideCommentBO,
  requestUpdateMentionPacHybrideExpiredAt,
  requestWorkflowMention,
} from "@state/mention/pachybride/MentionPacHybrideEffects";
import BasicButton from "@components/buttons/BasicButton";
import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import InputFormField from "@components/inputs/InputFormField";
import { formItemBlockLayout } from "@utils/Constant";
import { Field } from "@type/form/field.types";
import { formUtils } from "@utils/form-utils";
import { File } from "@type/resource/resource.type";
import { mapResourceTofileType } from "@utils/upload-utils";
import { MentionResponseDto } from "@state/mention/dto/mention.response.dto";
import { MENTION_STATUS } from "@components/mention/MentionStatusTag";
import { MENTION_E_LEARNING_STATUS } from "@components/mention/MentionELearningStatusTag";
import { SelectFormField } from "@components/inputs/SelectFormField";
import { ATTESTATION_PAC_STATUS } from "@components/mention/MentionAttestationPacStatusTag";
import DateFormat from "@utils/DateFormat";
import moment from "moment";
import { Store } from "rc-field-form/lib/interface";
import SubmitButton from "@components/buttons/SubmitButton";
import TextAreaFormField from "@components/inputs/TextAreaFormField";
import { MentionPacAttestationStatus } from "@utils/enums/mention.pac.attestation.status";
import CheckboxFormField from "@components/inputs/CheckboxFormField";
import { ResourceStatus } from "@utils/enums/resource.status";
import { ResourceDetailsResponseDto } from "@state/resource/dto/resource.details.response.dto";
import { MentionStatus } from "@utils/enums/mention.status";
import UploadResourceRge from "@components/mention/UploadResourceRge";
import UploadFormField from "@components/inputs/UploadFormField";
import { MentionElearningStatus } from "@utils/enums/mention.elearning.status";
import { toastError, toastSuccess } from "@utils/toast-helper";

const mapMentionPacHybrideFields = (mention: MentionResponseDto): Field[] => {
  const fields = formUtils.mapFieldsFrom(mention);

  const expiredAtField = fields.find((field) =>
    field.name.includes("expiredAt"),
  );
  const elearningValidatedAt = fields.find((field) =>
    field.name.includes("elearningValidatedAt"),
  );
  if (expiredAtField?.value) {
    expiredAtField.value = DateFormat.standardDateIso(
      new Date(expiredAtField.value),
    );
  }
  if (elearningValidatedAt?.value) {
    elearningValidatedAt.value = DateFormat.standardDateIso(
      new Date(elearningValidatedAt.value),
    );
  }

  return fields;
};

const { confirm } = Modal;

const MentionPacHybrideScreen: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { mentionPacHybrideId } = useParams<{ mentionPacHybrideId: string }>();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [fields, setFields] = useState<Field[]>([]);
  const [renewalInstallation, setRenewalInstallation] =
    useState<ResourceDetailsResponseDto>();
  const [renewalTechnicalTraining, setRenewalTechnicalTraining] =
    useState<ResourceDetailsResponseDto>();
  const [rge, setRge] = useState<ResourceDetailsResponseDto>();
  const [rgeFile, setRgeFile] = useState<File[]>([]);
  const [checkbox, setCheckbox] = useState<string[]>([]);
  const [mention, setMention] = useState<MentionResponseDto | undefined>(
    undefined,
  );
  const [form] = Form.useForm();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [nbFiles, setNbFiles] = useState<number>(0);
  const today = new Date(Date.now());
  const expiredAt = mention?.expiredAt && new Date(mention?.expiredAt);

  const isPending =
    (expiredAt != null &&
      expiredAt > today &&
      (mention?.statusRge === MentionPacAttestationStatus.PENDING ||
        mention?.elearningStatus === MentionElearningStatus.PENDING)) ||
    (mention?.statusRge === MentionPacAttestationStatus.PENDING &&
      mention?.elearningStatus === MentionElearningStatus.PENDING) ||
    (mention?.statusRge === MentionPacAttestationStatus.PENDING &&
      mention?.elearningStatus === MentionElearningStatus.VALIDATED);
  const isAccepted = mention?.status === MentionStatus.ACCEPTED;
  const isRefused =
    mention?.status === MentionStatus.REFUSED ||
    mention?.statusRge === MentionPacAttestationStatus.REFUSED ||
    mention?.elearningStatus === MentionElearningStatus.REFUSED;
  const isExpired = mention?.status === MentionStatus.EXPIRED;
  const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const confirmationPopup = async (title: string) => {
    return new Promise<boolean>((resolve) => {
      confirm({
        title: t("common.confirm"),
        content: title,
        okText: t("common.accept"),
        cancelText: t("common.cancel"),
        centered: true,
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  useEffect(() => {
    if (mentionPacHybrideId) {
      void requestGetMention(mentionPacHybrideId);
    }
  }, [mentionPacHybrideId]);

  useEffect(() => {
    return requestGetMention.done.watch(({ result }) => {
      if (result.ok && result.data) {
        const mention = result.data;
        setFields(mapMentionPacHybrideFields(mention));
        setRge(mention.rge);
        setRgeFile(mention.rge ? [mapResourceTofileType(mention.rge)] : []);
        mention.renewalInstallation &&
          setRenewalInstallation(mention.renewalInstallation);
        mention.renewalTechnicalTraining &&
          setRenewalTechnicalTraining(mention.renewalTechnicalTraining);
        const resource = [
          mention.rge,
          mention.renewalInstallation,
          mention.renewalCommercialTraining,
          mention.renewalTechnicalTraining,
        ];
        setCheckbox(
          resource
            .filter((s) => s != null)
            .filter((s) => s.status === "REFUSED")
            .map((s) => s?.id),
        );
        setNbFiles(
          resource.filter((s) => s != null).length +
            (mention.commercialTraining != null ? 1 : 0),
        );
        setMention(mention);
      } else {
        toastError(t<string>("common.notFound"));
      }
    });
  });

  const handleRenewalStatus = useCallback(
    (targetStatus: string) => {
      if (mentionPacHybrideId) {
        setButtonLoading(true);
        const expiredAt = form.getFieldValue("expiredAt") as string;
        void requestRenewalMention({
          id: mentionPacHybrideId,
          dto: {
            targetStatus: targetStatus,
            expiredAt: expiredAt && moment.utc(expiredAt).toISOString(),
            ids: checkbox,
          },
        }).finally(() => setButtonLoading(false));
      }
    },
    [form, mentionPacHybrideId, checkbox],
  );

  const handleAttestationStatus = useCallback(
    (targetStatus: string) => {
      if (mentionPacHybrideId) {
        setButtonLoading(true);
        const expiredAt =
          targetStatus != "REFUSED"
            ? (form.getFieldValue("expiredAt") as string)
            : undefined;
        void requestWorkflowMention({
          id: mentionPacHybrideId,
          dto: {
            targetStatus,
            expiredAt: expiredAt && moment.utc(expiredAt).toISOString(),
          },
        }).finally(() => setButtonLoading(false));
      }
    },
    [mentionPacHybrideId, form],
  );

  useEffect(() => {
    return requestWorkflowMention.done.watch(({ result }) => {
      setButtonLoading(false);
      if (result.ok && result.data) {
        toastSuccess(t<string>("mention.pacHybride.details.workflow.success"));
        setMention(result.data);
        setFields(mapMentionPacHybrideFields(result.data));
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    return requestRenewalMention.done.watch(({ result }) => {
      setButtonLoading(false);
      if (result.ok && result.data) {
        toastSuccess(t<string>("mention.pacHybride.details.workflow.success"));
        setMention(result.data);
        setFields(mapMentionPacHybrideFields(result.data));
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    return requestUpdateMentionPacHybrideExpiredAt.done.watch(({ result }) => {
      setButtonLoading(false);
      if (result.ok && result.data) {
        toastSuccess(
          t<string>("mention.pacHybride.details.update.expiredAt.success"),
        );
        setMention(result.data);
        setFields(mapMentionPacHybrideFields(result.data));
        setIsEditMode(false);
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  const handleUpdateMentionExpiredAt = useCallback(
    (values: Store) => {
      if (mentionPacHybrideId) {
        const expiredAt = values.expiredAt as string;
        setButtonLoading(true);
        void requestUpdateMentionPacHybrideExpiredAt({
          id: mentionPacHybrideId,
          dto: {
            expiredAt: moment.utc(expiredAt).toISOString(),
          },
        });
      }
    },
    [mentionPacHybrideId],
  );

  const handleComment = useCallback(
    (values: Store) => {
      if (mentionPacHybrideId) {
        const comment = values.comment as string;
        void requestUpdateMentionPacHybrideComment({
          id: mentionPacHybrideId,
          dto: {
            comment: comment,
          },
        });
        toastSuccess(t<string>("mention.messages.comment.success"));
      }
    },
    [mentionPacHybrideId, t],
  );

  const handleCommentBO = useCallback(
    (values: Store) => {
      if (mentionPacHybrideId) {
        const commentBo = values.commentBO as string;
        void requestUpdateMentionPacHybrideCommentBO({
          id: mentionPacHybrideId,
          dto: {
            comment: commentBo,
          },
        });
        toastSuccess(t<string>("mention.messages.comment.success"));
      }
    },
    [mentionPacHybrideId, t],
  );

  const handleChangeCheckbox = (id: string) => {
    checkbox.includes(id)
      ? setCheckbox(checkbox.filter((s) => s !== id))
      : setCheckbox([...checkbox, id]);
  };

  return (
    <PageLayout title={t<string>("mention.pacHybride.detailTitle")}>
      {!mention ? (
        <div style={{ textAlign: "center" }}>
          <Spin indicator={spinIcon} />
        </div>
      ) : (
        <>
          <Form
            {...formItemBlockLayout}
            form={form}
            fields={fields}
            onFinish={
              isEditMode
                ? handleUpdateMentionExpiredAt
                : nbFiles < 2
                ? () =>
                    handleAttestationStatus(
                      MentionPacAttestationStatus.VALIDATED,
                    )
                : () => handleRenewalStatus(MentionStatus.ACCEPTED)
            }
          >
            <div className="form-first-item-sticky-top-container">
              <div className="form-first-item-sticky-top-content">
                <div className="d-flex align-items-center justify-content-between flex-wrap px-3">
                  <div className="d-flex align-items-center justify-content-center flex-wrap">
                    {isEditMode && (
                      <Button
                        htmlType="reset"
                        className="m-2"
                        onClick={() => {
                          setIsEditMode(false);
                          form.resetFields();
                          setFields(mapMentionPacHybrideFields(mention));
                        }}
                      >
                        <ArrowLeftOutlined />{" "}
                        {t<string>(
                          "mention.pacHybride.details.update.expiredAt.cancel",
                        )}
                      </Button>
                    )}
                    {!isEditMode && (
                      <Button
                        htmlType="reset"
                        className="m-2"
                        onClick={() => navigate(-1)}
                      >
                        <ArrowLeftOutlined /> {t<string>("buttons.back")}
                      </Button>
                    )}
                  </div>
                  <div className="d-flex align-items-center justify-content-center flex-wrap">
                    {isEditMode && (
                      <BasicButton
                        key="update_expired_at"
                        className="btn-success m-2"
                        text={t<string>(
                          "mention.pacHybride.details.update.expiredAt.save",
                        )}
                        isLoading={buttonLoading}
                        type="submit"
                      />
                    )}
                    {(isAccepted || isRefused || isExpired) &&
                      !isEditMode &&
                      !isPending && (
                        <>
                          {!isRefused && (
                            <BasicButton
                              key="edit-mode"
                              className="m-2"
                              variant="primary"
                              text={t<string>(
                                "mention.pacHybride.details.update.expiredAt.edit",
                              )}
                              onClick={() => setIsEditMode(true)}
                            />
                          )}
                          <BasicButton
                            key="cancel-validation"
                            className="m-2"
                            variant="grey"
                            text={t<string>(
                              `mention.pacHybride.details.workflow.cancel.${mention.statusRge}`,
                            )}
                            onClick={() =>
                              nbFiles < 2
                                ? handleAttestationStatus(
                                    MentionPacAttestationStatus.PENDING,
                                  )
                                : handleRenewalStatus(MentionStatus.PENDING)
                            }
                            isLoading={buttonLoading}
                          />
                        </>
                      )}
                    {isPending && (
                      <>
                        <BasicButton
                          key="validate"
                          className="btn-success m-2"
                          text={
                            mention.numberPacHybride != null
                              ? t<string>(
                                  "mention.pacHybride.details.workflow.renewal.accept",
                                )
                              : t<string>("common.accept")
                          }
                          isLoading={buttonLoading}
                          type="submit"
                          disabled={checkbox.length !== 0}
                        />
                        <BasicButton
                          key="refused"
                          className="btn-danger m-2"
                          text={
                            mention.numberPacHybride != null
                              ? t<string>(
                                  "mention.pacHybride.details.workflow.renewal.refused",
                                )
                              : t<string>("common.refuse")
                          }
                          onClick={() => {
                            void confirmationPopup(
                              t<string>("mention.modal.refused"),
                            ).then((confirmed: boolean) => {
                              if (confirmed) {
                                nbFiles < 2
                                  ? handleAttestationStatus(
                                      MentionPacAttestationStatus.REFUSED,
                                    )
                                  : handleRenewalStatus(MentionStatus.REFUSED);
                              }
                            });
                          }}
                          isLoading={buttonLoading}
                          disabled={checkbox.length === 0}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Divider orientation="left">
              <h3 className="text-secondary mb-0">
                {t<string>("mention.pacHybride.details.infoGp")}
              </h3>
            </Divider>
            <Row>
              <Col xs={12} sm={6}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.pacHybride.details"
                  field="companyName"
                  readOnly={true}
                />
              </Col>
              <Col xs={12} sm={6}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.pacHybride.details"
                  field="firstName"
                  readOnly={true}
                />
              </Col>
              <Col xs={12} sm={6}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.pacHybride.details"
                  field="lastName"
                  readOnly={true}
                />
              </Col>
              <Col xs={12} sm={6}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.pacHybride.details"
                  field="email"
                  readOnly={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.pacHybride.details"
                  field="username"
                  readOnly={true}
                />
              </Col>
              <Col xs={12} sm={6}>
                <SelectFormField
                  className="mx-2"
                  showLabel
                  module="mention.pacHybride.details"
                  field="status"
                  readOnly={true}
                  options={Object.values(MENTION_STATUS)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <SelectFormField
                  className="mx-2"
                  showLabel
                  module="mention.pacHybride.details"
                  field="elearningStatus"
                  readOnly={true}
                  options={Object.values(MENTION_E_LEARNING_STATUS)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.pacHybride.details"
                  field="phone"
                  readOnly={true}
                />
              </Col>
              <Col xs={12} sm={6}>
                <SelectFormField
                  className="mx-2"
                  showLabel
                  module="mention.pacHybride.details"
                  field="statusRge"
                  readOnly={true}
                  options={Object.values(ATTESTATION_PAC_STATUS)}
                />
              </Col>
              <Col xs={12} sm={8}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.pacHybride.details"
                  field="expiredAt"
                  readOnly={!isPending && !isEditMode}
                  type="date"
                  required
                />
              </Col>
              <Col xs={12} sm={8}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.pacHybride.details"
                  field="elearningValidatedAt"
                  readOnly={true}
                  type="date"
                />
              </Col>
            </Row>
            <Divider orientation="left">
              <h3 className="text-secondary mb-0">
                {t<string>("mention.pacHybride.details.resources.title")}
              </h3>
            </Divider>
            <h4>{t("mention.pacHybride.details.resources.rge")}</h4>
            <Row>
              <Col xs={24} sm={12} lg={24} xl={12}>
                <UploadResourceRge
                  title={t<string>(
                    "mention.pacHybride.details.resources.detailRge",
                  )}
                  mentionId={mention.id}
                  files={rgeFile}
                  setFiles={setRgeFile}
                  module="mention.pacHybride.details"
                  field="rge"
                  domain="RGE_PAC_HYBRIDE"
                />
              </Col>
              {isPending && rge && (
                <Col xs={24} sm={12} lg={24} xl={12}>
                  <CheckboxFormField
                    module="mention.pacHybride.details"
                    field={rge.id}
                    noLabel={true}
                    onChange={() => handleChangeCheckbox(rge.id)}
                    initialValue={rge.status === ResourceStatus.REFUSED}
                  />
                </Col>
              )}
            </Row>
            {renewalInstallation && (
              <>
                <h4>
                  {t<string>(
                    "mention.pacHybride.details.resources.renewalInstallation",
                  )}
                </h4>
                <Row>
                  <Col xs={24} sm={12} lg={24} xl={12}>
                    <UploadFormField
                      files={[mapResourceTofileType(renewalInstallation)]}
                      module="mention.pacHybride.details"
                      field="renewalInstallation"
                      editMode={false}
                    />
                  </Col>
                  {isPending && (
                    <Col xs={24} sm={12} lg={24} xl={12}>
                      <CheckboxFormField
                        module="mention.pacHybride.details"
                        field={renewalInstallation.id}
                        noLabel={true}
                        onChange={() =>
                          handleChangeCheckbox(renewalInstallation.id)
                        }
                        initialValue={
                          renewalInstallation.status === ResourceStatus.REFUSED
                        }
                      />
                    </Col>
                  )}
                </Row>
              </>
            )}
            {renewalTechnicalTraining && (
              <>
                <h4>
                  {t<string>(
                    "mention.pacHybride.details.resources.renewalTechnicalTraining",
                  )}
                </h4>
                <Row>
                  <Col xs={24} sm={12} lg={24} xl={12}>
                    <UploadFormField
                      files={[mapResourceTofileType(renewalTechnicalTraining)]}
                      module="mention.pacHybride.details"
                      field="renewalTechnicalTraining"
                      editMode={false}
                    />
                  </Col>
                  {isPending && (
                    <Col xs={24} sm={12} lg={24} xl={12}>
                      <CheckboxFormField
                        module="mention.pacHybride.details"
                        field={renewalTechnicalTraining.id}
                        noLabel={true}
                        onChange={() =>
                          handleChangeCheckbox(renewalTechnicalTraining.id)
                        }
                        initialValue={
                          renewalTechnicalTraining.status ===
                          ResourceStatus.REFUSED
                        }
                      />
                    </Col>
                  )}
                </Row>
              </>
            )}
            {mention.siret && mention?.commercialTraining === "yes" && (
              <>
                <h4>
                  {t<string>(
                    "mention.pacHybride.details.resources.renewalCommercialTraining",
                  )}
                </h4>
                {mention.siret && mention?.commercialTraining === "yes" && (
                  <Row>
                    <Col xs={24} sm={12} lg={24} xl={12}>
                      <div>
                        {t(
                          "mention.pacHybride.details.resources.commercialTraining",
                          { siret: mention.siret },
                        )}
                      </div>
                    </Col>
                    {isPending && (
                      <Col xs={24} sm={12} lg={24} xl={12}>
                        <CheckboxFormField
                          module="mention.pacHybride.details"
                          field={mention.commercialTraining}
                          noLabel={true}
                          onChange={() =>
                            handleChangeCheckbox("commercialTraining")
                          }
                          initialValue={
                            mention.hasPassedACommercialTraining != null
                          }
                        />
                      </Col>
                    )}
                  </Row>
                )}
              </>
            )}
          </Form>
          <Form className="mb-0" onFinish={handleComment} fields={fields}>
            <div className="d-flex flex-column align-items-stretch flex-sm-row">
              <div className="w-100 d-flex align-items-center">
                <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
                  <h3 className="text-secondary mb-0">
                    {t<string>(
                      "mention.pacHybride.details.form.fields.comment.label",
                    )}
                  </h3>
                </Divider>
              </div>
              <div className="w-100 d-flex align-items-center divider-sm-transparent">
                <Divider orientation="right" className="mt-2 mt-sm-3 mb-3">
                  <SubmitButton
                    module="mention.pacHybride.details"
                    label="comment"
                  />
                </Divider>
              </div>
            </div>
            <TextAreaFormField
              module="mention.smokeDuct.details"
              field="comment"
            />
          </Form>
          <Form className="mb-0" onFinish={handleCommentBO} fields={fields}>
            <div className="d-flex flex-column align-items-stretch flex-sm-row">
              <div className="w-100 d-flex align-items-center">
                <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
                  <h3 className="text-secondary mb-0">
                    {t<string>(
                      "mention.pacHybride.details.form.fields.commentBO.label",
                    )}
                  </h3>
                </Divider>
              </div>
              <div className="w-100 d-flex align-items-center divider-sm-transparent">
                <Divider orientation="right" className="mt-2 mt-sm-3 mb-3">
                  <SubmitButton
                    module="mention.pacHybride.details"
                    label="commentBO"
                  />
                </Divider>
              </div>
            </div>
            <TextAreaFormField
              module="mention.pacHybride.details"
              field="commentBO"
            />
          </Form>
        </>
      )}
    </PageLayout>
  );
};

export default MentionPacHybrideScreen;
