import i18n from "i18next";
import { ReactNode } from "react";
import BasicTag from "@components/tags/BasicTag";
import { OrganisationProfessionalGPItemResponseDto } from "@state/organisationProfessional/dto/organisation.professional.gaz.professional.item.response.dto";

export const columns = [
  {
    title: i18n.t(
      "organisationProfessional.details.listPGs.table.headers.code",
    ),
    dataIndex: "code",
    key: "code",
    width: 200,
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t(
      "organisationProfessional.details.listPGs.table.headers.companyName",
    ),
    dataIndex: "companyName",
    key: "companyName",
    ellipsis: {
      showTitle: false,
    },
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t(
      "organisationProfessional.details.listPGs.table.headers.qualifications",
    ),
    key: "qualifications",
    width: 269,
    render: (record: OrganisationProfessionalGPItemResponseDto): ReactNode => {
      return record.pgiYear || record.pgmYear ? (
        <div className="app-pg-subscribers-tags">
          <div className="pg-subscribers-tag">
            {record.pgiYear > 0 && (
              <BasicTag
                label={`${i18n.t(
                  "organisationProfessional.details.listPGs.table.pgi",
                )} ${record.pgiYear}`}
                variant={record.pgiYearValid ? "light-green" : "light-red"}
              />
            )}
          </div>
          <div className="pg-subscribers-tag">
            {record.pgmYear > 0 && (
              <BasicTag
                label={`${i18n.t(
                  "organisationProfessional.details.listPGs.table.pgm",
                )} ${record.pgmYear}`}
                variant={record.pgmYearValid ? "light-green" : "light-red"}
              />
            )}
          </div>
        </div>
      ) : (
        ""
      );
    },
  },
];
