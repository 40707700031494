import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Popconfirm, Radio } from "antd";
import { InfoCircleOutlined, PlusCircleOutlined } from "@ant-design/icons/lib";
import { useStore } from "effector-react";
import { Block } from "src/type/page/page.types";
import {
  BlockFormatDto,
  BlockResponseDto,
} from "@state/block/dto/block.response.dto";
import { BlockStore } from "@state/block/BlockStore";
import shortid from "shortid";

export interface Props {
  buttonLabel: string;
  index?: number;
  handleAddBlock: (block: Block, index?: number) => void;
}

export const PageAddBlocButton: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const { t } = useTranslation();

  const blocksStore: BlockResponseDto[] | null = useStore(BlockStore);

  const { handleAddBlock, index, buttonLabel } = props;

  const radioStyle = {
    display: "block",
    height: "40px",
    lineHeight: "40px",
  };

  const [block, setBlock] = useState<BlockResponseDto>();

  const popconfirmTitle = (
    <>
      <div>
        <b>{t<string>("page.globalButtons.block.popConfirm.title")}</b>
      </div>
      <div className="my-2">
        <Radio.Group
          buttonStyle="outline"
          onChange={(e) =>
            setBlock(
              blocksStore.filter((block) => block.id === e.target.value)[0],
            )
          }
        >
          {blocksStore.map((block: BlockResponseDto) => (
            <Radio style={radioStyle} key={block.id} value={block.id}>
              {block.label}
            </Radio>
          ))}
        </Radio.Group>
      </div>
    </>
  );

  return (
    <Popconfirm
      title={popconfirmTitle}
      okText={t<string>("common.confirm")}
      cancelText={t<string>("common.cancel")}
      placement="top"
      icon={<InfoCircleOutlined />}
      onConfirm={() => {
        if (block) {
          const format = JSON.parse(block.format) as BlockFormatDto;
          handleAddBlock(
            {
              blockId: block.id,
              idTempLocal: shortid.generate(),
              inputs: format.inputs,
            },
            index,
          );
        }
      }}
    >
      <Button className="btn-secondary m-2">
        <PlusCircleOutlined /> {buttonLabel}
      </Button>
    </Popconfirm>
  );
};
