import React from "react";
import i18n from "i18next";
import { alignRight, orderAscend } from "@utils/Constant";
import { Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table/interface";
import { LeadResponseDto } from "@state/contact/dto/contact.response.dto";
import { tooltipDateTimeRender } from "@components/tooltips/CustomTooltip";
import { NavigateFunction } from "react-router-dom";
import { ROUTES } from "@routes/Routes";
import ContactStatusTag from "@components/contacts/ContactStatusTag";

export const columns = (
  navigate: NavigateFunction,
): ColumnsType<LeadResponseDto> => [
  {
    title: i18n.t<string>("contacts.leads.list.columns.status"),
    key: "status",
    dataIndex: "status",
    ellipsis: false,
    defaultSortOrder: orderAscend,
    render: (contactStatus: string) => (
      <ContactStatusTag contactStatus={contactStatus} />
    ),
  },
  {
    title: i18n.t<string>("contacts.leads.list.columns.createdAt"),
    key: "createdAt",
    dataIndex: "createdAt",
    ellipsis: true,
    sorter: true,
    render: tooltipDateTimeRender,
  },
  {
    title: i18n.t<string>("contacts.leads.list.columns.username"),
    key: "username",
    dataIndex: "username",
    ellipsis: false,
    defaultSortOrder: orderAscend,
  },
  {
    title: i18n.t<string>("contacts.leads.list.columns.companyName"),
    key: "companyName",
    dataIndex: "companyName",
    ellipsis: false,
    defaultSortOrder: orderAscend,
  },
  {
    title: i18n.t<string>("contacts.leads.list.columns.nameProspect"),
    key: "nameProspect",
    dataIndex: "nameProspect",
    ellipsis: false,
    defaultSortOrder: orderAscend,
  },
  {
    title: i18n.t<string>("contacts.leads.list.columns.actions"),
    key: "actions",
    fixed: alignRight,
    className: "it-actions",
    onCell: () => {
      return {
        onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
          e.stopPropagation(),
      };
    },
    render: (record: LeadResponseDto) => (
      <Button
        icon={<EyeOutlined />}
        type="text"
        size="large"
        className="btn-primary me-1 btn-list"
        onClick={() => navigate(ROUTES.leads.details.generate(record.id))}
      />
    ),
  },
];
