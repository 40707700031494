import {
  FunctionComponent,
  JSXElementConstructor,
  ReactElement,
  useRef,
} from "react";
import { UploadFile } from "antd/es/upload/interface";
import { useDrag, useDrop } from "react-dnd";

interface DragableUploadListItemProps {
  originNode: ReactElement<any, string | JSXElementConstructor<any>>;
  file: UploadFile;
  fileList: UploadFile[];
  moveRow: (dragIndex: any, hoverIndex: any) => void;
}

const DragableUploadListItem: FunctionComponent<
  DragableUploadListItemProps
> = ({ originNode, moveRow, file, fileList }) => {
  const ref = useRef<HTMLDivElement>(null);
  const index = fileList.indexOf(file);

  const type = "DragableUploadList";

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const {
        index: dragIndex,
      }: {
        index: number;
      } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item: { index: number }) => {
      moveRow(item.index, index);
    },
  });

  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));

  /* eslint-disable */
  return (
    <div
      ref={ref}
      className={`ant-upload-draggable-list-item ${
        isOver ? dropClassName : ""
      }`}
      style={{ cursor: "move" }}
    >
      {originNode}
    </div>
  );
  /* eslint-enable */
};

export default DragableUploadListItem;
