import { FC } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { useParams } from "react-router-dom";
import ContactForm from "@views/contacts/ContactForm";

const ContactScreen: FC = () => {
  const { contactId } = useParams<{ contactId: string }>();

  const { t } = useTranslation();

  return (
    <PageLayout title={t<string>("contacts.contact.details.title")}>
      <>
        <ContactForm contactId={contactId ? contactId : ""} />
      </>
    </PageLayout>
  );
};

export default ContactScreen;
