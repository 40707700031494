import React from "react";
import { Form, Input, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { formUtils } from "@utils/form-utils";

interface PropsType {
  showLabel?: boolean;
  module: string;
  field: string;
  required?: boolean;
  emptyErrorMessage?: string;
  labelTooltip?: string | null;
  readOnly?: boolean;
  labelClassName?: string;
  fieldClassName?: string;
  maxLength?: number;
  onChange?: (e: any) => void;
  initialValue?: string;
  fieldPrefix?: string;
}

const TextAreaFormField: React.FunctionComponent<PropsType> = (
  props: PropsType,
) => {
  const {
    showLabel,
    labelTooltip,
    module,
    field,
    required,
    emptyErrorMessage,
    readOnly,
    labelClassName,
    fieldClassName,
    onChange,
    initialValue,
    fieldPrefix = "",
  } = props;

  const { t } = useTranslation();

  const isRequired: boolean = !!readOnly ? false : !!required; // !(!var) => return false if var = false | null | undefined

  const i18nField = formUtils.geti18nFieldFromField(field);

  return (
    <>
      <Form.Item
        name={fieldPrefix + field}
        key={fieldPrefix + field}
        label={
          readOnly || showLabel ? (
            <span className={labelClassName || ""}>
              {t<string>(`${module}.form.fields.${i18nField}.label`)}&nbsp;
              {labelTooltip && <Tooltip title={labelTooltip} />}
            </span>
          ) : (
            ""
          )
        }
        rules={[
          {
            required: isRequired,
            message: emptyErrorMessage
              ? emptyErrorMessage
              : t<string>("common.empty_error_field"),
            whitespace: isRequired,
          },
        ]}
        initialValue={initialValue}
      >
        <Input.TextArea
          className={`${fieldClassName ? fieldClassName : ""} ${
            readOnly ? " readonly-form-field" : ""
          }`}
          placeholder={t<string>(
            `${module}.form.fields.${i18nField}.placeholder`,
          )}
          disabled={readOnly}
          autoSize={{ minRows: 3 }}
          onChange={onChange}
        />
      </Form.Item>
    </>
  );
};

export default TextAreaFormField;
