export enum WorksiteStatus {
  INIT = "INIT",
  INITINELIGIBLE = "INIT_INELIGIBLE",
  PENDING = "PENDING",
  PENDINGINELIGIBLE = "PENDING_INELIGIBLE",
  WAITING = "WAITING",
  ELIGIBLE = "ELIGIBLE",
  GRDF = "GRDF",
}

export const NUMBER_BOILER = [
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
  {
    value: "6",
    label: "6",
  },
  {
    value: "7",
    label: "7",
  },
  {
    value: "8",
    label: "8",
  },
  {
    value: "9",
    label: "9",
  },
  {
    value: "10",
    label: "10",
  },
  {
    value: "11",
    label: "11",
  },
  {
    value: "12",
    label: "12",
  },
  {
    value: "13",
    label: "13",
  },
  {
    value: "14",
    label: "14",
  },
  {
    value: "15",
    label: "15",
  },
  {
    value: "16",
    label: "16",
  },
  {
    value: "17",
    label: "17",
  },
  {
    value: "18",
    label: "18",
  },
  {
    value: "19",
    label: "19",
  },
  {
    value: "20",
    label: "20",
  },
] as OptionType[];

export const TYPE_RENOVATION = [
  {
    value: "INDIVIDUAL_RENOVATION",
    label: "worksite.renovationType.individualRenovation",
  },
  {
    value: "COLLECTIVE_RENOVATION_SHUNT_COL",
    label: "worksite.renovationType.shuntCollection",
  },
  {
    value: "COLLECTIVE_RENOVATION_SHUNT_IND",
    label: "worksite.renovationType.shuntIndividual",
  },
  {
    value: "COLLECTIVE_RENOVATION_ALVEOLE",
    label: "worksite.renovationType.atg",
  },
  {
    value: "COLLECTIVE_RENOVATION_3CE",
    label: "worksite.renovationType.3CE",
  },
] as OptionType[];

export interface OptionType {
  value: string;
  label: string;
}
