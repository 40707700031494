import { FC, useCallback, useEffect, useState } from "react";
import { Button, Col, Divider, Empty, Form, Table } from "antd";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { Pageable } from "@type/pagination/pagination.types";
import { emptySearchResult } from "@utils/Constant";
import { SpinIcon } from "@components/icons/SpinIcon";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import FileSaver from "file-saver";
import { SaveOutlined } from "@ant-design/icons";
import InputFormField from "@components/inputs/InputFormField";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import {
  setFilterPage,
  setTablePageNumber,
  setTablePageSize,
} from "@state/auth/AuthEvents";
import { GazProfessionalItemResponseDto } from "@state/gazProfessional/dto/gaz.professional.item.response.dto";
import { GazProfessionalSearchCriteria } from "@state/gazProfessional/dto/gaz.professional.search.criteria";
import { columns } from "@views/gazProfessional/list/GazProfessionalsColumns";
import { requestGetGreenGazExport } from "@state/mention/greengaz/GreenGazEffets";
import { requestGetGazProfessionalsList } from "@state/gazProfessional/GazProfessionalEffets";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { useNavigate } from "react-router-dom";

interface FilterObject {
  query: string;
}

const MentionsGreenGazScreen: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authentifiedContext = useStore(authentifiedStore);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(
    authentifiedContext?.dashboardsPageNumber?.["mentionsGreenGaz"] || 1,
  );
  const [pageSize, setPageSize] = useState<number>(
    authentifiedContext?.dashboardsPageSizes?.["mentionsGreenGaz"] || 10,
  );

  const contextFilter: FilterObject | undefined = authentifiedContext
    ?.dashboardsFilter?.["mentionsGreenGaz"] as FilterObject | undefined;

  const [query, setQuery] = useState<string>(contextFilter?.query || "");

  const InitialValues = {
    query: query,
  };

  const onChangeQuery = (current: string): void => {
    setQuery(current);
    setFilterPage({
      value: {
        query: current,
      },
      property: "mentionsGreenGaz",
    });
    onChangePageNumber(1);
  };

  const [gazProfessionals, setGazProfessionals] =
    useState<Pageable<GazProfessionalItemResponseDto>>(emptySearchResult);

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

  const onChangePageSize = (current: number, newPageSize: number): void => {
    setPageSize(newPageSize);
    setTablePageSize({
      value: newPageSize,
      property: "mentionsGreenGaz",
    });
  };

  const onChangePageNumber = (current: number): void => {
    setPageNumber(current);
    setTablePageNumber({
      value: current,
      property: "mentionsGreenGaz",
    });
  };

  const fetchGazProfessionals = useCallback(() => {
    setDataHasLoaded(false);
    const searchCriteria: GazProfessionalSearchCriteria = {
      query: query,
      expertise: ["GREEN_GAZ"],
    };
    requestGetGazProfessionalsList({
      dto: searchCriteria,
      page: pageNumber - 1,
      limit: pageSize,
    })
      .catch(() => {
        toastError(t<string>("gazProfessional.void messages.error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [query, pageNumber, pageSize, t]);

  const handleSubmit = () => {
    setButtonLoading(true);
    const searchCriteria: GazProfessionalSearchCriteria = {
      query,
    };
    void requestGetGreenGazExport({
      dto: {
        query: searchCriteria.query,
      },
    });
  };

  useEffect(() => {
    return requestGetGreenGazExport.done.watch(({ result }) => {
      afterWs(result);
    });
  });

  const afterWs = (result: ParsedResponse<Blob>) => {
    setButtonLoading(false);
    if (result.ok && result.data && result.fileName) {
      const data = new Blob([result.data], {
        type: "application/octet-stream",
      });
      FileSaver.saveAs(data, result.fileName);
      toastSuccess(t<string>("gazProfessional.messages.generation.success"));
    } else {
      toastError(t<string>("gazProfessional.messages.generation.error"));
    }
  };

  useEffect(() => {
    fetchGazProfessionals();
  }, [fetchGazProfessionals]);

  useEffect(() => {
    return requestGetGazProfessionalsList.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setGazProfessionals(result.data);
      }
    });
  });

  return (
    <PageLayout title={t<string>("gazProfessional.greenGaz.title")}>
      <>
        <div className="d-flex flex-column align-items-stretch flex-sm-row">
          <div className="w-100 d-flex align-items-center">
            <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
              <h3 className="text-secondary mb-0">
                {t<string>("gazProfessional.listTitle")}
              </h3>
            </Divider>
          </div>
          <div className="w-100 d-flex align-items-center divider-sm-transparent">
            <Divider orientation="right" className="mt-2 mt-sm-3 mb-3">
              <Form onFinish={handleSubmit}>
                <Button
                  className="btn-primary my-5"
                  htmlType="submit"
                  loading={buttonLoading}
                >
                  <SaveOutlined /> {t<string>("gazProfessional.export.save")}
                </Button>
              </Form>
            </Divider>
          </div>
        </div>
        <Col xs={24} lg={12}>
          <Form initialValues={InitialValues}>
            <InputFormField
              module="gazProfessional.search"
              field="query"
              onPressEnter={(e) => {
                e.preventDefault();
                onChangeQuery(e.currentTarget.value);
              }}
            />
          </Form>
        </Col>
        <Table
          style={{ whiteSpace: "break-spaces" }}
          locale={{
            emptyText: (
              <Empty description={t<string>("gazProfessional.table.noData")} />
            ),
          }}
          loading={{ spinning: !dataHasLoaded, indicator: SpinIcon }}
          columns={columns(navigate)}
          dataSource={gazProfessionals.content}
          pagination={{
            total: gazProfessionals.totalElements,
            pageSize: gazProfessionals.size,
            current: gazProfessionals.number + 1,
            onChange: onChangePageNumber,
            onShowSizeChange: onChangePageSize,
            showSizeChanger: true,
          }}
          showSorterTooltip={false}
          rowKey={(record) => record.id}
          rowClassName="cursor-pointer"
        />
      </>
    </PageLayout>
  );
};
export default MentionsGreenGazScreen;
