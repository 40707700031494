import { FunctionComponent, useEffect, useState } from "react";
import BasicModal from "@components/modals/BasicModal";
import {
  InstallationItem,
  ProgrammingAuditItem,
} from "@state/audit/dto/audit.item.details.dto";
import { requestGetInstallationDetail } from "@state/audit/AuditEffects";
import { useTranslation } from "react-i18next";
import { auditUtils } from "@views/gazProfessional/details/audit/audit-utils";
import parse from "html-react-parser";

interface AuditShowOldInstallationModalProps {
  title: string;
  visible: boolean;
  onCancel: () => void;
  className?: string;
  programmingAuditItems?: ProgrammingAuditItem[];
  pgId: string;
}

const AuditShowOldInstallationModal: FunctionComponent<
  AuditShowOldInstallationModalProps
> = ({
  title,
  visible,
  onCancel,
  className = "",
  programmingAuditItems,
  pgId,
}) => {
  const { t } = useTranslation();
  const [auditInstallations, setAuditInstallations] = useState<
    InstallationItem[]
  >([]);

  useEffect(() => {
    if (programmingAuditItems != null && visible) {
      setAuditInstallations([]);
      const installationToDetailId = programmingAuditItems?.map(
        (item) => item.ccId,
      );
      installationToDetailId?.forEach((installation) => {
        void requestGetInstallationDetail({
          dto: {
            installationId: installation,
            gazProfessionalId: pgId,
          },
        });
      });
    }
  }, [pgId, programmingAuditItems, visible]);

  useEffect(() => {
    return requestGetInstallationDetail.done.watch(({ result }) => {
      if (result.ok && result.data && visible) {
        const installationDetail = result.data;
        setAuditInstallations((installation) => [
          {
            id: installationDetail.id,
            installationId: installationDetail.installationId,
            ocId: installationDetail.ocId,
            ocCcId: installationDetail.ocCcId,
            nameRg: installationDetail.nameRg,
            address1: installationDetail.address1,
            address2: installationDetail.address2,
            address3: installationDetail.address3,
            address4: installationDetail.address4,
            city: installationDetail.city,
            zipCode: installationDetail.zipCode,
            dateOfRegistration: installationDetail.dateOfRegistration,
          },
          ...installation,
        ]);
      }
    });
  });

  return (
    <>
      <BasicModal
        title={title}
        visible={visible}
        onCancel={onCancel}
        className={`${className}`}
        footer={null}
      >
        <>
          <div className="audit-detail-installations">
            <div className="audit-detail-installations-items">
              {auditInstallations &&
                auditInstallations.map((installation, index) => (
                  <div key={index} className="audit-detail-installations-item">
                    <div className="audit-detail-installations-item-choice">{`${t<string>(
                      "gazProfessional.audit.detail.installations.items.choice",
                    )} ${index + 1}`}</div>
                    <div className="audit-detail-installations-item-name">
                      {auditUtils.renderTitle(installation.ocId)}
                    </div>
                    <div className="audit-detail-installations-item-number">{`${t<string>(
                      "gazProfessional.audit.detail.installations.items.number",
                    )} ${installation.ocCcId}`}</div>
                    <div className="audit-detail-installations-item-rgi">
                      {t<string>(
                        "gazProfessional.audit.detail.installations.items.rgi",
                        { name: installation?.nameRg },
                      )}
                    </div>
                    <div className="audit-detail-installations-item-address">
                      <div>{parse(auditUtils.getAddress(installation))}</div>
                      <div>
                        {installation?.zipCode
                          .concat(" ")
                          .concat(installation?.city)}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </>
      </BasicModal>
    </>
  );
};

export default AuditShowOldInstallationModal;
