import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  Modal,
  Popconfirm,
  Row,
  Spin,
} from "antd";
import {
  ArrowLeftOutlined,
  EditOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  SendOutlined,
  WarningOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import { FIELD_LENGTH, formItemBlockLayout } from "src/utils/Constant";
import {
  DeleteOutlined,
  DownOutlined,
  SaveOutlined,
  StopOutlined,
} from "@ant-design/icons/lib";
import {
  requestCreatePage,
  requestDeletePage,
  requestGetPage,
  requestScheduledPage,
  requestUpdatePage,
  requestWorkflowPage,
} from "src/state/page/PageEffects";
import { addPage, updatePage } from "src/state/page/PageEvents";
import { CategoryInfoResponseDto } from "@state/category/dto/category.info.response.dto";
import { PageInfoResponseDto } from "@state/page/dto/page.info.response.dto";
import { Block, BlockInput } from "@type/page/page.types";
import { Image } from "@type/image/image-interface";
import { useNavigate } from "react-router-dom";
import { PageSaveRequestDto } from "@state/page/dto/page.save.request.dto";
import PagesStatusTag from "@views/page/details/PagesStatusTag";
import { showConfirm } from "@components/modals/ConfirmModal";
import { PageStatus } from "@utils/enums/page.status";
import CheckboxFormField from "@components/inputs/CheckboxFormField";
import InputFormField from "@components/inputs/InputFormField";
import CategoryTreeFormField from "@components/category/CategoryTreeFormField";
import { PageBlocks } from "@views/page/block/PageBlocks";
import {
  FieldData,
  Store,
  ValidateErrorEntity,
} from "rc-field-form/lib/interface";
import TextAreaFormField from "@components/inputs/TextAreaFormField";
import UploadImageForm from "@components/inputs/UploadImageForm";
import { getBase64UrlFrom } from "@utils/image-utils";
import { useCategories } from "@state/category/Categories";
import { SelectFormField } from "@components/inputs/SelectFormField";
import { KeywordInfoResponseDTO } from "@state/keyword/dto/keyword.response.dto";
import { useKeywords } from "@state/keyword/Keywords";
import InputNumberFormField from "@components/inputs/InputNumberFormField";
import { inputNumberFormat } from "@utils/input-number-format";
import DatePickerFormField from "@components/inputs/DatePickerFormField";
import moment, { Moment } from "moment";
import RadioFormField from "@components/inputs/RadioFormField";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import PagePreviewButton from "@views/page/preview/PagePreviewButton";
import {
  requestGetResource,
  requestGetResourceMetadata,
} from "@state/resource/ResourceEffects";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { ROUTES } from "@routes/Routes";
import BasicModal from "@components/modals/BasicModal";
import DateFormat from "@utils/DateFormat";
import BasicAlert from "@components/alerts/BasicAlert";

const { confirm } = Modal;

export interface Props {
  pageId?: string;
  editMode?: boolean;
  setEditMode?: (editMode: boolean) => void;
}

export interface MainImageSaveRequest {
  mainImageData: string;
  mainImageFilename: string;
  mainImageType: string;
  mainImageAlt?: string;
  mainImageTitle?: string;
}

const PageForm: React.FunctionComponent<Props> = (props: Props) => {
  const { pageId, editMode, setEditMode } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const categories: CategoryInfoResponseDto[] | null = useCategories({});
  const keywords: KeywordInfoResponseDTO[] = useKeywords();

  const [form] = Form.useForm();

  const [page, setPage] = useState<PageInfoResponseDto | null>(null);

  const [blocks, setBlocks] = useState<Block[]>([]);

  const [fields, setFields] = useState<FieldData[]>([]);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [isNews, setNews] = useState<boolean>(false);

  const [getResponseReceived, setGetResponseReceived] =
    useState<boolean>(false);

  const [mainImage, setMainImage] = useState<Image | undefined>(undefined);
  const [shareImage, setShareImage] = useState<Image | undefined>(undefined);
  const [isScheduledModalIsVisible, setIsScheduledModalIsVisible] =
    useState<boolean>(false);
  const [from, setFrom] = useState<Moment | undefined>(
    moment.utc(page?.scheduledPublishDate),
  );
  const [to, setTo] = useState<Moment | undefined>(
    moment.utc(page?.scheduledUnpublishDate),
  );
  const today = new Date();

  const [InitialValues, setInitialValues] = useState<{
    from: Moment | undefined;
    to: Moment | undefined;
  }>();

  useEffect(() => {
    setInitialValues({ from: from, to: to });
  }, [to, from]);

  const onChangeDateFrom = (current: Moment): void => {
    setFrom(current);
  };

  const onChangeDateTo = (current: Moment): void => {
    setTo(current);
  };

  const handleSchedule = () => {
    setButtonLoading(true);
    if (pageId) {
      if ((!from?.isBefore(to) && to == undefined) || to != undefined) {
        void requestScheduledPage({
          id: pageId,
          dto: {
            scheduledPublishDate: from ? moment(from).toISOString() : "",
            scheduledUnpublishDate: to ? moment(to).toISOString() : "",
          },
        });
      } else {
        toastError(
          "La date de publication ne peut pas être mise après la date de dépublication",
        );
        setButtonLoading(false);
      }
    }
  };

  useEffect(() => {
    return requestScheduledPage.done.watch(({ result }) => {
      setButtonLoading(false);
      setIsScheduledModalIsVisible(false);
      if (result.ok && result.data && setEditMode) {
        updatePage(result.data);
        setPage(result.data);
        toastSuccess(t<string>("editPage.scheduled"));
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  const confirmationPopup = async (publish?: boolean) => {
    return new Promise<boolean>((resolve) => {
      confirm({
        title: t<string>("common.confirm"),
        content: publish
          ? t<string>("page.publish")
          : publish !== undefined
          ? t<string>("pages.table.confirmation.unPublish")
          : t<string>("editPage.save"),
        okText: t<string>("common.yes"),
        cancelText: t<string>("common.no"),
        centered: true,
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  // Used as keys for react
  const generateBlockIds = (pageResponse: PageInfoResponseDto) => {
    setBlocks(
      pageResponse.blocks.map((pageBlock) => {
        return {
          idTempLocal: pageBlock.id,
          blockId: pageBlock.blockId,
          classNames: pageBlock.classNames,
          background: pageBlock.background,
          inputs: JSON.parse(pageBlock.inputs) as BlockInput[],
        };
      }),
    );
  };

  useEffect(() => {
    if (pageId) {
      void requestGetPage(pageId);
    }
  }, [pageId]);

  useEffect(() => {
    return requestGetPage.done.watch(({ result }) => {
      setGetResponseReceived(true);
      if (result.ok && result.data) {
        loadMainImage(result.data);
        loadShareImage(result.data);
        setPage(result.data);
        generateBlockIds(result.data);
        setFields([
          {
            name: ["title"],
            value: result.data.title,
          },
          {
            name: ["shortTitle"],
            value: result.data.shortTitle,
          },
          {
            name: ["isHeadline"],
            value: result.data.isHeadline ? "true" : undefined,
          },
          {
            name: ["isNews"],
            value: result.data.publicationDate ? "true" : undefined,
          },
          {
            name: ["summary"],
            value: result.data.summary,
          },
          {
            name: ["categoryId"],
            value: result.data.categoryId,
          },
          {
            name: ["publicationDate"],
            value: result.data.publicationDate
              ? moment.utc(result.data.publicationDate)
              : "",
          },
          {
            name: ["readingTime"],
            value: result.data.readingTime,
          },
          {
            name: ["keywords"],
            value: result?.data?.keywords?.map((k) => k.keywordId),
          },
        ]);
        setNews(result.data.publicationDate != undefined);
        setTo(
          result.data.scheduledUnpublishDate
            ? moment(result.data.scheduledUnpublishDate)
            : undefined,
        );
        setFrom(
          result.data.scheduledPublishDate
            ? moment(result.data.scheduledPublishDate)
            : undefined,
        );
      } else {
        toastError(t<string>("page.notFound"));
      }
    });
  });

  const loadMainImage = (page: PageInfoResponseDto) => {
    if (page?.mainImageResourceId != null) {
      const getResourceMetadata = requestGetResourceMetadata(
        page.mainImageResourceId,
      );
      const getResourceContent = requestGetResource(page.mainImageResourceId);

      Promise.all([getResourceMetadata, getResourceContent])
        .then((values) => {
          const metadata = values[0]?.data;
          const content = values[1]?.data;

          if (values[0].ok && metadata && values[1].ok && content) {
            setMainImage({
              base64Data: content.data,
              type: content.mimeType,
              name: metadata.filename,
            });
          }
        })
        .catch(() => {
          toastError(t<string>("page.notFound"));
        });
    }
  };

  const loadShareImage = (page: PageInfoResponseDto) => {
    if (page?.shareImageResourceId != null) {
      const getResourceMetadata = requestGetResourceMetadata(
        page.shareImageResourceId,
      );
      const getResourceContent = requestGetResource(page.shareImageResourceId);

      Promise.all([getResourceMetadata, getResourceContent])
        .then((values) => {
          const metadata = values[0]?.data;
          const content = values[1]?.data;

          if (values[0].ok && metadata && values[1].ok && content) {
            setShareImage({
              base64Data: content.data,
              type: content.mimeType,
              name: metadata.filename,
            });
          }
        })
        .catch(() => {
          toastError(t<string>("page.notFound"));
        });
    }
  };

  useEffect(() => {
    return requestCreatePage.done.watch(({ result }) => {
      setButtonLoading(false);
      if (result.ok && result.data) {
        addPage(result.data);
        toastSuccess(t<string>("addPage.success"));
        navigate(ROUTES.cms.pages.details.generate(result.data.id));
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    return requestUpdatePage.done.watch(({ result }) => {
      setButtonLoading(false);
      if (result.ok && result.data && setEditMode) {
        updatePage(result.data);
        setPage(result.data);
        setEditMode(false);
        toastSuccess(t<string>("editPage.success"));
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    return requestWorkflowPage.done.watch(({ result }) => {
      setButtonLoading(false);
      if (result.ok && result.data) {
        if (setEditMode) {
          setEditMode(false);
        }
        updatePage(result.data);
        setPage(result.data);
        toastSuccess(t<string>("editPage.success"));
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    return requestDeletePage.done.watch(({ result }) => {
      if (result.ok) {
        toastSuccess(t<string>("page.globalButtons.delete.success"));
        navigate(ROUTES.cms.pages.list.generate());
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  const setValuesAndRequestUpdatePage = (
    values: Store,
    imageSaveRequest?: MainImageSaveRequest,
    shareImageSaveRequest?: MainImageSaveRequest,
  ) => {
    setButtonLoading(true);
    const pageToSave: PageSaveRequestDto = {
      title: values["title"] as string,
      shortTitle: values["shortTitle"] as string,
      summary: values["summary"] as string,
      categoryId: values["categoryId"] as string,
      isHeadline: values["isHeadline"] as boolean,
      blocks: blocks.map((block) => {
        return {
          blockId: block.blockId,
          inputs: JSON.stringify(block.inputs),
          classNames: block.classNames,
          background: block.background,
        };
      }),
      publicationDate: values["publicationDate"] as string,
      readingTime: values["readingTime"] as number,
      keywords: values["keywords"] as string[],
      mainImage: imageSaveRequest && {
        base64Data: imageSaveRequest.mainImageData,
        name: imageSaveRequest.mainImageFilename,
      },
      shareImage: shareImageSaveRequest && {
        base64Data: shareImageSaveRequest.mainImageData,
        name: shareImageSaveRequest.mainImageFilename,
      },
    };
    if (pageId) {
      void requestUpdatePage({
        id: pageId,
        dto: pageToSave,
      });
    } else {
      void requestCreatePage({
        dto: pageToSave,
      });
    }
    setButtonLoading(true);
  };

  const handleNewVersion = () => {
    setButtonLoading(true);
    if (page) {
      const pageToSave: PageSaveRequestDto = {
        ...page,
        blocks: blocks.map((block) => {
          return {
            blockId: block.blockId,
            inputs: JSON.stringify(block.inputs),
            classNames: block.classNames,
            background: block.background,
          };
        }),
        keywords: page.keywords?.map((k) => k.keywordId),
        oldVersionId: page.id,
      };
      void requestCreatePage({
        dto: pageToSave,
      });
    }
  };

  const handleSubmit = (values: Store) => {
    if (blocks.length === 0) {
      toastError(t<string>("page.submit.error.no_block"));
    } else {
      void confirmationPopup().then((confirmed: boolean) => {
        if (confirmed) {
          return mainImage
            ? setValuesAndRequestUpdatePage(
                values,
                {
                  mainImageData: mainImage.base64Data,
                  mainImageFilename: mainImage.name,
                  mainImageType: mainImage.type,
                  mainImageAlt: mainImage.alt,
                  mainImageTitle: mainImage.title,
                },
                shareImage
                  ? {
                      mainImageData: shareImage.base64Data,
                      mainImageFilename: shareImage.name,
                      mainImageType: shareImage.type,
                      mainImageAlt: shareImage.alt,
                      mainImageTitle: shareImage.title,
                    }
                  : undefined,
              )
            : undefined;
        }
      });
    }
  };

  const getScheduledText = (page: PageInfoResponseDto) => {
    const text = t<string>("page.scheduled");
    if (page.scheduledPublishDate && page.scheduledUnpublishDate) {
      return text
        .concat(
          t<string>("page.scheduledPublishDate", {
            date: DateFormat.standardDate(new Date(page?.scheduledPublishDate)),
          }),
        )
        .concat(t<string>("page.and"))
        .concat(
          t<string>("page.scheduledUnpublishDate", {
            date: DateFormat.standardDate(
              new Date(page?.scheduledUnpublishDate),
            ),
          }),
        );
    } else if (page.scheduledPublishDate) {
      return text.concat(
        t<string>("page.scheduledPublishDate", {
          date: DateFormat.standardDate(new Date(page?.scheduledPublishDate)),
        }),
      );
    } else if (page.scheduledUnpublishDate) {
      return text.concat(
        t<string>("page.scheduledUnpublishDate", {
          date: DateFormat.standardDate(new Date(page?.scheduledUnpublishDate)),
        }),
      );
    } else return text;
  };

  const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const onFinishFailed = ({ errorFields }: ValidateErrorEntity<Store>) => {
    toastError(t<string>("common.on_finish_failed"));
    form.scrollToField(errorFields[0].name);
  };

  const getForm = () => {
    if (!pageId || page) {
      return (
        <Form
          {...formItemBlockLayout}
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          fields={fields}
        >
          <div className="form-first-item-sticky-top-container">
            <div className="form-first-item-sticky-top-content">
              {page && (
                <div className="mb-2">
                  <PagesStatusTag status={page.status} />
                  <span className="ms-1">
                    {t<string>("page.version", {
                      version: page ? page.version : 1,
                    })}
                  </span>
                </div>
              )}
              <div className="d-flex justify-content-between flex-grow-1">
                <div className="d-flex align-items-center justify-content-center flex-wrap">
                  {editMode && setEditMode ? (
                    <>
                      <Popconfirm
                        title={t<string>("common.cancel_confirmation")}
                        okText={t<string>("common.yes")}
                        cancelText={t<string>("common.no")}
                        onConfirm={() => {
                          setEditMode(false);
                        }}
                        placement="top"
                        icon={<QuestionCircleOutlined />}
                        className="m-2"
                      >
                        <Button htmlType="reset">
                          <StopOutlined /> {t<string>("common.cancel")}
                        </Button>
                      </Popconfirm>
                    </>
                  ) : (
                    <>
                      <Button
                        htmlType="reset"
                        className="m-2"
                        onClick={() =>
                          navigate(ROUTES.cms.pages.list.generate())
                        }
                      >
                        <ArrowLeftOutlined /> {t<string>("editCategory.return")}
                      </Button>
                      {page && (
                        <PagePreviewButton
                          disabled={editMode ?? false}
                          page={page}
                        />
                      )}
                    </>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-center flex-wrap">
                  {editMode ? (
                    <Button
                      className="btn-primary m-2"
                      htmlType="submit"
                      loading={buttonLoading}
                    >
                      <SaveOutlined /> {t<string>("common.save")}
                    </Button>
                  ) : (
                    page && (
                      <div className="d-flex">
                        {page.status === "PUBLISHED" &&
                          !page.hasMoreRecentVersion && (
                            <Button
                              loading={buttonLoading}
                              className="btn-success m-2"
                              onClick={handleNewVersion}
                            >
                              <SendOutlined />
                              {t<string>("editPage.newVersion")}
                            </Button>
                          )}
                        {setEditMode && page.status === "SAVED" && (
                          <Button
                            htmlType="reset"
                            className="btn-primary m-2"
                            onClick={() => setEditMode(true)}
                          >
                            <EditOutlined /> {t<string>("common.edit")}
                          </Button>
                        )}
                        {page.status === "SAVED" && (
                          <Dropdown.Button
                            className="m-2 dropdown-button-success"
                            menu={{
                              items: [
                                {
                                  label: (
                                    <div
                                      onClick={() =>
                                        showConfirm(
                                          t<string>(
                                            "page.globalButtons.publish.confirmation",
                                            {
                                              pageName: page.title,
                                            },
                                          ),
                                          () => {
                                            void requestWorkflowPage({
                                              id: page.id,
                                              dto: {
                                                targetStatus:
                                                  PageStatus.PUBLISHED,
                                              },
                                            });
                                          },
                                        )
                                      }
                                    >
                                      {t<string>("common.publish")}
                                    </div>
                                  ),
                                  key: "1",
                                },
                                {
                                  label: (
                                    <div
                                      onClick={() =>
                                        setIsScheduledModalIsVisible(true)
                                      }
                                    >
                                      {t<string>("common.schedulePublish")}
                                    </div>
                                  ),
                                  key: "2",
                                },
                              ],
                            }}
                            icon={<DownOutlined />}
                          >
                            <div
                              onClick={() =>
                                showConfirm(
                                  t<string>(
                                    "page.globalButtons.publish.confirmation",
                                    {
                                      pageName: page.title,
                                    },
                                  ),
                                  () => {
                                    void requestWorkflowPage({
                                      id: page.id,
                                      dto: {
                                        targetStatus: PageStatus.PUBLISHED,
                                      },
                                    });
                                  },
                                )
                              }
                            >
                              <SendOutlined /> {t<string>("editPage.publish")}
                            </div>
                          </Dropdown.Button>
                        )}
                        {page.status === "PUBLISHED" && (
                          <Dropdown.Button
                            className="m-2 dropdown-button-secondary"
                            menu={{
                              items: [
                                {
                                  label: (
                                    <div
                                      onClick={() =>
                                        showConfirm(
                                          t<string>(
                                            "page.globalButtons.publish.confirmation",
                                            {
                                              pageName: page.title,
                                            },
                                          ),
                                          () => {
                                            void requestWorkflowPage({
                                              id: page.id,
                                              dto: {
                                                targetStatus:
                                                  PageStatus.PUBLISHED,
                                              },
                                            });
                                          },
                                        )
                                      }
                                    >
                                      {t<string>("common.unpublish")}
                                    </div>
                                  ),
                                  key: "1",
                                },
                                {
                                  label: (
                                    <div
                                      onClick={() =>
                                        setIsScheduledModalIsVisible(true)
                                      }
                                    >
                                      {t<string>("common.scheduleUnpublish")}
                                    </div>
                                  ),
                                  key: "2",
                                },
                              ],
                            }}
                            icon={<DownOutlined />}
                          >
                            <div
                              onClick={() =>
                                showConfirm(
                                  t<string>(
                                    "page.globalButtons.unPublish.confirmation",
                                    {
                                      pageName: page.title,
                                    },
                                  ),
                                  () => {
                                    void requestWorkflowPage({
                                      id: page.id,
                                      dto: {
                                        targetStatus: PageStatus.SAVED,
                                      },
                                    });
                                  },
                                )
                              }
                            >
                              <StopOutlined /> {t<string>("editPage.unPublish")}
                            </div>
                          </Dropdown.Button>
                        )}
                        {page.status === "SAVED" && (
                          <Button
                            className="btn-danger m-2"
                            onClick={() =>
                              showConfirm(
                                t<string>(
                                  "page.globalButtons.delete.confirmation",
                                  {
                                    pageName: page.title,
                                  },
                                ),
                                () => {
                                  void requestDeletePage({
                                    dto: page,
                                  });
                                },
                              )
                            }
                          >
                            <DeleteOutlined /> {t<string>("common.delete")}
                          </Button>
                        )}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
          {page &&
            (page?.scheduledPublishDate || page?.scheduledUnpublishDate) && (
              <>
                <BasicAlert
                  variant="warning"
                  text={getScheduledText(page)}
                  icon={<WarningOutlined />}
                />
              </>
            )}
          <Divider orientation="left">
            <h3 className="text-secondary mb-0">{t<string>("page.type")}</h3>
          </Divider>
          <RadioFormField
            field="isNews"
            readOnly={!editMode}
            options={[
              { label: "Article", value: "false" },
              { label: "Actualités", value: "true" },
            ]}
            onChange={(value: CheckboxChangeEvent) => {
              setNews(value.target.value === "true");
            }}
            value={isNews ? "true" : "false"}
          />
          <Divider orientation="left">
            <h3 className="text-secondary mb-0">
              {t<string>("page.isHeadline")}
            </h3>
          </Divider>
          <CheckboxFormField
            labelTooltip={t<string>("page.form.isHeadline.help")}
            field="isHeadline"
            module="page"
            readOnly={!editMode}
          />
          <Divider orientation="left">
            <h3 className="text-secondary mb-0">{t<string>("page.content")}</h3>
          </Divider>
          <Row>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module="page"
                field="title"
                required={true}
                emptyErrorMessage={t<string>("common.empty_error_field")}
                readOnly={!editMode}
                maxLength={FIELD_LENGTH.NAME}
              />
            </Col>
            {categories && (
              <Col xs={24} sm={12}>
                <CategoryTreeFormField
                  labelTooltip={
                    isNews
                      ? t<string>("page.form.fields.categoryId.tooltip")
                      : undefined
                  }
                  categories={categories}
                  module="page"
                  fieldName="categoryId"
                  readOnly={!editMode}
                  required
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module="page"
                field="shortTitle"
                emptyErrorMessage={t<string>("common.empty_error_field")}
                readOnly={!editMode}
                maxLength={FIELD_LENGTH.SHORTTITLE}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <TextAreaFormField
                showLabel
                field="summary"
                required
                fieldClassName={!editMode ? "readonly-textarea" : ""}
                emptyErrorMessage={t<string>("common.empty_error_field")}
                readOnly={!editMode}
                maxLength={FIELD_LENGTH.TEXTAREA}
                module="page"
              />
            </Col>
          </Row>
          {isNews && (
            <Row>
              <Col xs={24}>
                <SelectFormField
                  mode="multiple"
                  showLabel
                  field="keywords"
                  readOnly={!editMode}
                  module="page"
                  options={keywords.map((keyword) => {
                    return {
                      label: keyword.keyword,
                      value: keyword.id,
                    };
                  })}
                />
              </Col>
              <Col xs={24} md={12}>
                <InputNumberFormField
                  showLabel
                  required
                  module="page"
                  field="readingTime"
                  emptyErrorMessage={t<string>("common.empty_error_field")}
                  readOnly={!editMode}
                  formatter={(value) => inputNumberFormat.formatNumber(value)}
                  min={1}
                />
              </Col>
              <Col xs={24} md={12}>
                <DatePickerFormField
                  showLabel
                  required
                  module="page"
                  field="publicationDate"
                  emptyErrorMessage={t<string>("common.empty_error_field")}
                  readOnly={!editMode}
                  mode="date"
                  picker="date"
                  disabledDate={() => false}
                />
              </Col>
            </Row>
          )}
          <UploadImageForm
            formFieldName="mainImage"
            formFieldLabel={t<string>("page.mainImage")}
            imageContent={
              mainImage
                ? {
                    content: getBase64UrlFrom(
                      mainImage.base64Data,
                      mainImage.type,
                    ),
                    alt: page?.mainImageAlt,
                    title: page?.mainImageTitle,
                  }
                : undefined
            }
            editMode={editMode}
            setImage={setMainImage}
            required
          />
          <Divider orientation="left">
            <h3 className="text-secondary mb-0">
              {t<string>("page.shareImage")}
            </h3>
          </Divider>
          <UploadImageForm
            formFieldName="shareImage"
            formFieldLabel={t<string>("page.shareImageDetail")}
            imageContent={
              shareImage
                ? {
                    content: getBase64UrlFrom(
                      shareImage.base64Data,
                      shareImage.type,
                    ),
                  }
                : undefined
            }
            editMode={editMode}
            setImage={setShareImage}
          />
          {(blocks?.length != null || editMode) && (
            <>
              <PageBlocks
                editMode={editMode}
                blocks={blocks}
                setBlocks={setBlocks}
              />
            </>
          )}
        </Form>
      );
    } else {
      return <div>{t<string>("page.notFound")}</div>;
    }
  };

  return (
    <>
      {!getResponseReceived && pageId ? (
        <div style={{ textAlign: "center" }}>
          <Spin indicator={spinIcon} />
        </div>
      ) : (
        getForm()
      )}
      <BasicModal
        visible={isScheduledModalIsVisible}
        title={t<string>("page.modal.schedulePublication.title")}
        onCancel={() => setIsScheduledModalIsVisible(false)}
        footer={
          <Button
            className="btn-primary my-3 mx-3"
            onClick={handleSchedule}
            loading={buttonLoading}
          >
            <ScheduleOutlined />
            {t<string>("page.modal.schedulePublication.actions.submit")}
          </Button>
        }
      >
        <>
          <Form initialValues={InitialValues}>
            <Row>
              {page?.status != "PUBLISHED" && (
                <Col span="12">
                  <h6>{t<string>("page.modal.schedulePublication.publish")}</h6>
                  <DatePickerFormField
                    format="DD/MM/YYYY HH"
                    module="page.modal.schedulePublication"
                    field="from"
                    mode="date"
                    picker="date"
                    onChange={(value) => onChangeDateFrom(value as Moment)}
                    disabledDate={(current) => {
                      return current < moment.utc(today);
                    }}
                    showTime
                  />
                </Col>
              )}
              <Col span="12">
                <h6>{t<string>("page.modal.schedulePublication.unpublish")}</h6>
                <DatePickerFormField
                  format="DD/MM/YYYY HH"
                  module="page.modal.schedulePublication"
                  field="to"
                  mode="date"
                  picker="date"
                  showTime
                  onChange={(value) => onChangeDateTo(value as Moment)}
                  disabledDate={(current) => {
                    return from ? current < from : current < moment.utc(today);
                  }}
                />
              </Col>
            </Row>
          </Form>
        </>
      </BasicModal>
    </>
  );
};
export default PageForm;
