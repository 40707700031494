import i18n from "i18next";
import { alignRight, orderAscend } from "@utils/Constant";
import { Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import React, { ReactNode } from "react";
import { ColumnsType } from "antd/lib/table/interface";
import { AlphabeticalSorter } from "@utils/AlphabeticalSorter";
import { WorksiteItemsResponseDto } from "@state/worksite/dto/worksite.response.dto";
import WorksiteStatusTag from "@components/worksite/WorksiteStatusTag";
import WorksiteResourceStatusTag from "@components/worksite/WorksiteResourceStatusTag";
import { NavigateFunction } from "react-router-dom";
import { ROUTES } from "@routes/Routes";

export const columns = (
  navigate: NavigateFunction,
): ColumnsType<WorksiteItemsResponseDto> => [
  {
    title: i18n.t<string>("mention.smokeDuct.list.columns.status"),
    key: "worksiteStatus",
    dataIndex: "worksiteStatus",
    ellipsis: false,
    render: (worksiteStatus: string) => (
      <WorksiteStatusTag worksiteStatus={worksiteStatus} />
    ),
    defaultSortOrder: orderAscend,
    sorter: AlphabeticalSorter("worksiteStatus"),
  },
  {
    title: i18n.t<string>("worksite.list.columns.resourceStatus"),
    key: "worksiteResourcesStatus",
    dataIndex: "worksiteResourcesStatus",
    ellipsis: false,
    render: (status: string) => <WorksiteResourceStatusTag status={status} />,
  },
  {
    title: i18n.t<string>("mention.smokeDuct.list.columns.companyName"),
    key: "companyName",
    dataIndex: "companyName",
    ellipsis: false,
    defaultSortOrder: orderAscend,
    sorter: AlphabeticalSorter("companyName"),
  },
  {
    title: i18n.t<string>("mention.smokeDuct.list.columns.username"),
    key: "username",
    dataIndex: "username",
    ellipsis: false,
    defaultSortOrder: orderAscend,
    sorter: AlphabeticalSorter("username"),
  },
  {
    title: i18n.t<string>("worksite.list.columns.name"),
    key: "name",
    dataIndex: "name",
    ellipsis: false,
    defaultSortOrder: orderAscend,
    sorter: AlphabeticalSorter("name"),
  },
  {
    title: i18n.t<string>("worksite.list.columns.typePipe"),
    key: "typePipe",
    dataIndex: "typePipe",
    ellipsis: false,
    defaultSortOrder: orderAscend,
    sorter: AlphabeticalSorter("typePipe"),
    render: (typePipe: string): ReactNode => (
      <div className="status-container">
        {i18n.t<string>(
          `worksite.details.form.radioGroup.typePipe.${typePipe}`,
        )}
      </div>
    ),
  },
  {
    title: i18n.t<string>("users.list.columns.actions"),
    key: "actions",
    fixed: alignRight,
    className: "it-actions",
    onCell: () => {
      return {
        onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
          e.stopPropagation(),
      };
    },
    render: (record: WorksiteItemsResponseDto) => (
      <Button
        icon={<EyeOutlined />}
        type="text"
        size="large"
        className="btn-primary me-1 btn-list"
        onClick={() => navigate(ROUTES.worksites.details.generate(record.id))}
      />
    ),
  },
];
