import { FunctionComponent, useEffect, useState } from "react";
import { Divider, Form } from "antd";
import { FORMS_FIELD_TYPE } from "@type/form/field.types";
import BaseForm from "@components/forms/BaseForm";
import {
  requestGetGazProfessional,
  requestUpdateGazProfessional,
} from "@state/gazProfessional/GazProfessionalEffets";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GazProfessionalSupportInitialization from "@views/gazProfessional/details/GazProfessionalSupportInitialization";
import AuditDetail from "@views/gazProfessional/details/audit/AuditDetail";
import BasicButton from "@components/buttons/BasicButton";
import { GazProfessionalDetailsResponseDto } from "@state/gazProfessional/dto/gaz.professional.details.response.dto";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { ROUTES } from "@routes/Routes";
import QualificationsDetail from "@views/gazProfessional/details/QualificationsDetail";
import MentionDetail from "@views/gazProfessional/details/mention/MentionDetail";

export interface Props {
  yoocanId?: string;
  editMode?: boolean;
  setEditMode?: (editMode: boolean) => void;
}

const GazProfessionalForm: FunctionComponent<Props> = (props: Props) => {
  const { yoocanId, editMode, setEditMode } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [yoocanForm] = Form.useForm();
  const [editableForm] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [showAudit, setShowAudit] = useState<boolean>(false);
  const [showMention, setShowMention] = useState<boolean>(false);
  const [showQualifications, setShowQualifications] = useState<boolean>(false);
  const [gazProfessional, setGazProfessional] =
    useState<GazProfessionalDetailsResponseDto>();

  const FORM = {
    main: {
      key: "yoocanForm",
      module: "gazProfessional.edit.yoocan",
      title: "form.title",
      fields: [
        [
          {
            name: "yoocanId",
            type: FORMS_FIELD_TYPE.NUMBER,
            size: 8,
            readOnly: true,
          },
          {
            name: "yoocanDepop",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 10,
            readOnly: true,
          },
          {
            name: "siret",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 14,
            readOnly: true,
          },
        ],
        [
          {
            name: "legalForm",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 25,
            readOnly: true,
          },
          {
            name: "companyName",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 255,
            readOnly: true,
          },
          {
            name: "address1",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 255,
            readOnly: true,
          },
        ],
        [
          {
            name: "address2",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 255,
            readOnly: true,
          },
          {
            name: "address3",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 255,
            readOnly: true,
          },
          {
            name: "zipcode",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 10,
            readOnly: true,
          },
        ],
        [
          {
            name: "city",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 255,
            readOnly: true,
          },
          {
            name: "phone1",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 25,
            readOnly: true,
          },
          {
            name: "phone2",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 25,
            readOnly: true,
          },
        ],
        [
          {
            name: "email",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 255,
            pattern:
              /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
            readOnly: true,
          },
          {
            name: "professionalOrganization",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 255,
            readOnly: true,
          },
          {
            name: "phoneOptional",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 25,
            readOnly: true,
          },
        ],
        [
          {
            name: "emailOptional",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 255,
            pattern:
              /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
            readOnly: true,
          },
          {
            name: "username",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 25,
            readOnly: true,
          },
          {
            name: "installation",
            type: FORMS_FIELD_TYPE.NUMBER,
            size: 8,
            maxLength: 3,
            readOnly: true,
          },
        ],
        [
          {
            name: "maintenance",
            type: FORMS_FIELD_TYPE.NUMBER,
            size: 8,
            readOnly: true,
          },
          {
            name: "qualificationPg",
            type: FORMS_FIELD_TYPE.NUMBER,
            size: 8,
            readOnly: true,
          },
          {
            name: "status",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 45,
            readOnly: true,
          },
        ],
      ],
    },
  };

  const EDITABLE_FORM = {
    main: {
      key: "editableForm",
      module: "gazProfessional.edit.editable",
      title: "form.title",
      fields: [
        [
          {
            name: "latitude",
            type: FORMS_FIELD_TYPE.NUMBER,
            size: 8,
            step: 0.000001,
          },
          {
            name: "longitude",
            type: FORMS_FIELD_TYPE.NUMBER,
            size: 8,
            step: 0.000001,
          },
        ],
        [
          {
            name: "editableEmail",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 255,
          },
          {
            name: "editableAddress",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 255,
          },
        ],
        [
          {
            name: "editableZipCode",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 255,
          },
          {
            name: "editableCity",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 255,
          },
        ],
        [
          {
            name: "website",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 255,
          },
          {
            name: "facebook",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 255,
          },
        ],
        [
          {
            name: "twitter",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 255,
          },
          {
            name: "linkedin",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 8,
            maxLength: 255,
          },
        ],
        [
          {
            name: "visible",
            type: FORMS_FIELD_TYPE.CHECKBOX,
          },
          {
            name: "alert",
            type: FORMS_FIELD_TYPE.CHECKBOX,
          },
        ],
      ],
    },
  };

  useEffect(() => {
    return requestUpdateGazProfessional.done.watch(({ result }) => {
      setLoading(false);
      if (result.ok && result.data) {
        toastSuccess(t<string>("users.edit.messages.success"));
        navigate(ROUTES.gazProfessionals.list.generate());
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    if (yoocanId) {
      void requestGetGazProfessional(yoocanId);
    }
  }, [yoocanId]);

  useEffect(() => {
    return requestGetGazProfessional.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setGazProfessional(result.data);
      }
    });
  }, []);

  return (
    <>
      <BaseForm
        title={t<string>("gazProfessional.edit.editable.form.title")}
        module="gazProfessional.edit"
        form={editableForm}
        entityId={yoocanId}
        loading={loading}
        editMode={editMode}
        setEditMode={setEditMode}
        getEntityConfiguration={{
          requestGet: requestGetGazProfessional,
        }}
        saveEntityConfiguration={{
          requestUpdate: requestUpdateGazProfessional,
        }}
        formConfiguration={EDITABLE_FORM}
      />
      <GazProfessionalSupportInitialization />
      <BaseForm
        showActions={false}
        title={t<string>("gazProfessional.edit.yoocan.form.title")}
        module="gazProfessional.edit"
        form={yoocanForm}
        entityId={yoocanId}
        loading={loading}
        editMode={false}
        setEditMode={setEditMode}
        getEntityConfiguration={{
          requestGet: requestGetGazProfessional,
        }}
        formConfiguration={FORM}
      />
      <div className="d-flex flex-column align-items-stretch flex-sm-row nbo-block">
        <div className="w-100 d-flex align-items-center">
          <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
            <h3 className="text-secondary mb-0">
              {t<string>("gazProfessional.edit.yoocan.mention.title")}
            </h3>
          </Divider>
        </div>
        <div className="w-100 d-flex align-items-center divider-sm-transparent">
          <Divider orientation="right" className="mt-2 mt-sm-3 mb-3">
            <BasicButton
              variant="primary"
              text={t<string>("gazProfessional.edit.yoocan.mention.action")}
              onClick={() => setShowMention(!showMention)}
            />
          </Divider>
        </div>
      </div>
      {showMention && <MentionDetail gazProfessional={gazProfessional} />}
      <div className="d-flex flex-column align-items-stretch flex-sm-row nbo-block">
        <div className="w-100 d-flex align-items-center">
          <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
            <h3 className="text-secondary mb-0">
              {t<string>("gazProfessional.edit.yoocan.audit.title")}
            </h3>
          </Divider>
        </div>
        <div className="w-100 d-flex align-items-center divider-sm-transparent">
          <Divider orientation="right" className="mt-2 mt-sm-3 mb-3">
            <BasicButton
              variant="primary"
              text={t<string>("gazProfessional.edit.yoocan.audit.action")}
              onClick={() => setShowAudit(!showAudit)}
            />
          </Divider>
        </div>
      </div>
      {showAudit && <AuditDetail gazProfessional={gazProfessional} />}
      <div className="d-flex flex-column align-items-stretch flex-sm-row nbo-block">
        <div className="w-100 d-flex align-items-center">
          <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
            <h3 className="text-secondary mb-0">
              {t<string>("gazProfessional.edit.yoocan.qualifications.title")}
            </h3>
          </Divider>
        </div>
        <div className="w-100 d-flex align-items-center divider-sm-transparent">
          <Divider orientation="right" className="mt-2 mt-sm-3 mb-3">
            <BasicButton
              variant="primary"
              text={t<string>(
                "gazProfessional.edit.yoocan.qualifications.action",
              )}
              onClick={() => setShowQualifications(!showQualifications)}
            />
          </Divider>
        </div>
      </div>
      {showQualifications && (
        <QualificationsDetail gazProfessional={gazProfessional} />
      )}
    </>
  );
};
export default GazProfessionalForm;
