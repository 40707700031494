import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Divider, Form, Row } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { requestImportChimneyProfessionals } from "@state/chimneyProfessional/ChimneyProfessionalEffects";
import UploadFormField from "@components/inputs/UploadFormField";
import { File } from "@type/resource/resource.type";
import { toastError, toastSuccess } from "@utils/toast-helper";

const GazMonthChimneyProfessionalImportForm: FC = () => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleChangeFiles = useCallback(
    (newFiles: File[]) => {
      if (newFiles.length > 0) {
        setFiles([newFiles[newFiles.length - 1]]);
      } else {
        setFiles([]);
      }
    },
    [setFiles],
  );

  const handleSubmit = () => {
    setLoading(true);
    void requestImportChimneyProfessionals({
      dto: files[0],
    });
  };

  useEffect(() => {
    return requestImportChimneyProfessionals.done.watch(({ result }) => {
      setLoading(false);
      if (result.ok && result.data && result.data.success) {
        toastSuccess(t<string>("gazMonth.import.messages.generation.success"));
      } else {
        toastError(t<string>("gazMonth.import.messages.generation.error"));
      }
    });
  });

  return (
    <>
      <div className="d-flex flex-column align-items-stretch flex-sm-row">
        <div className="w-100 d-flex align-items-center">
          <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
            <h3 className="text-secondary mb-0">
              {t<string>("gazMonth.import.title")}
            </h3>
          </Divider>
        </div>
      </div>
      <Form onFinish={handleSubmit}>
        <Row className="my-5">
          <Col xs={12} md={6}>
            <UploadFormField
              module="gazMonth.export"
              field="file"
              files={files}
              setFiles={handleChangeFiles}
              types={[
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              ]}
              formatLabel={"Ficher Excel (xls, xlsx)"}
            />
          </Col>
          <Col xs={12} md={6}>
            <Button className="btn-primary" htmlType="submit" loading={loading}>
              <SaveOutlined /> {t<string>("gazMonth.import.form.actions.save")}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default GazMonthChimneyProfessionalImportForm;
