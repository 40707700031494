import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Divider, Empty, Form, Row, Table } from "antd";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { columns } from "@components/lists/MentionsPacHybrideColumns";
import {
  requestGetMentionsPacHybride,
  requestGetMentionsPacHybrideExportExcel,
} from "@state/mention/pachybride/MentionPacHybrideEffects";
import { Pageable } from "@type/pagination/pagination.types";
import { emptySearchResult } from "@utils/Constant";
import { SpinIcon } from "@components/icons/SpinIcon";
import { MentionResponseDto } from "@state/mention/dto/mention.response.dto";
import { requestGetResources } from "@state/resource/ResourceEffects";
import { mapResourceTofileType } from "@utils/upload-utils";
import { File } from "@type/resource/resource.type";
import UploadFormField from "@components/inputs/UploadFormField";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import FileSaver from "file-saver";
import { SaveOutlined } from "@ant-design/icons";
import InputFormField from "@components/inputs/InputFormField";
import { SelectFormField } from "@components/inputs/SelectFormField";
import { OptionType } from "@type/form/field.types";
import { MENTION_STATUS } from "@components/mention/MentionStatusTag";
import { ATTESTATION_PAC_STATUS } from "@components/mention/MentionAttestationPacStatusTag";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import {
  setFilterPage,
  setTablePageNumber,
  setTablePageSize,
} from "@state/auth/AuthEvents";
import { MENTION_E_LEARNING_STATUS } from "@components/mention/MentionELearningStatusTag";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { ROUTES } from "@routes/Routes";

interface FilterObject {
  name: string;
  status: string[];
  statusRge: string[];
  statusElearning: string[];
}

const MentionsPacHybrideScreen: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const authentifiedContext = useStore(authentifiedStore);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(
    authentifiedContext?.dashboardsPageNumber?.["mentionsPacHybride"] || 1,
  );
  const [pageSize, setPageSize] = useState<number>(
    authentifiedContext?.dashboardsPageSizes?.["mentionsPacHybride"] || 10,
  );

  const [mentionsPacHybride, setMentionsPacHybride] =
    useState<Pageable<MentionResponseDto>>(emptySearchResult);

  const contextFilter: FilterObject | undefined = authentifiedContext
    ?.dashboardsFilter?.["mentionsPacHybride"] as FilterObject | undefined;

  const [name, setName] = useState<string>(contextFilter?.name || "");
  const [statusRge, setStatusRge] = useState<string[]>(
    contextFilter?.statusRge || [],
  );
  const [status, setStatus] = useState<string[]>(contextFilter?.status || []);

  const [statusElearning, setStatusElearning] = useState<string[]>(
    contextFilter?.statusElearning || [],
  );

  const InitialValues = {
    statusRge: statusRge,
    query: name,
    status: status,
    statusElearning: statusElearning,
  };

  const onChangeName = (current: string): void => {
    setName(current);
    setFilterPage({
      value: {
        name: current,
        statusRge: contextFilter?.statusRge,
        status: contextFilter?.status,
        statusElearning: contextFilter?.statusElearning,
      },
      property: "mentionsPacHybride",
    });
    onChangePageNumber(1);
  };

  const onChangeStatusRge = (current: string[]): void => {
    setStatusRge(current);
    setFilterPage({
      value: {
        name: contextFilter?.name,
        statusRge: current,
        status: contextFilter?.status,
        statusElearning: contextFilter?.statusElearning,
      },
      property: "mentionsPacHybride",
    });
    onChangePageNumber(1);
  };

  const onChangeStatus = (current: string[]): void => {
    setStatus(current);
    setFilterPage({
      value: {
        name: contextFilter?.name,
        statusRge: contextFilter?.statusRge,
        status: current,
        statusElearning: contextFilter?.statusElearning,
      },
      property: "mentionsPacHybride",
    });
    onChangePageNumber(1);
  };

  const onChangeStatusElearning = (current: string[]): void => {
    setStatusElearning(current);
    setFilterPage({
      value: {
        name: contextFilter?.name,
        statusRge: contextFilter?.statusRge,
        status: contextFilter?.status,
        statusElearning: current,
      },
      property: "mentionsPacHybride",
    });
    onChangePageNumber(1);
  };

  const [statusOptions] = useState<OptionType[]>(Object.values(MENTION_STATUS));
  const [statusRgeOptions] = useState<OptionType[]>(
    Object.values(ATTESTATION_PAC_STATUS),
  );
  const [statusElearningOptions] = useState<OptionType[]>(
    Object.values(MENTION_E_LEARNING_STATUS),
  );

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);
  const [pacHybrideFiles, setPacHybrideFiles] = useState<File[]>([]);

  const onChangePageSize = (current: number, newPageSize: number): void => {
    setPageSize(newPageSize);
    setTablePageSize({
      value: newPageSize,
      property: "mentionsPacHybride",
    });
  };

  const onChangePageNumber = (current: number): void => {
    setPageNumber(current);
    setTablePageNumber({
      value: current,
      property: "mentionsPacHybride",
    });
  };
  const fetchPages = useCallback(() => {
    setDataHasLoaded(false);
    requestGetMentionsPacHybride({
      page: pageNumber - 1,
      limit: pageSize,
      dto: {
        query: name,
        statuses: status,
        statusesRge: statusRge,
        statusesElearning: statusElearning,
      },
    })
      .catch(() => {
        toastError(t<string>("notification.void messages.error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [pageNumber, name, status, statusRge, statusElearning, pageSize, t]);

  useEffect(() => {
    fetchPages();
  }, [fetchPages]);

  useEffect(() => {
    return requestGetMentionsPacHybride.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setMentionsPacHybride(result.data);
      }
    });
  });

  useEffect(() => {
    requestGetResources({
      dto: {
        domain: ["PAC_HYBRIDE"],
        ids: [],
      },
    }).catch(() => {
      toastError(t<string>("common.error.loading"));
    });
  }, [t]);

  useEffect(() => {
    return requestGetResources.done.watch(({ result }) => {
      if (result.ok && result.data && result.data.length > 0) {
        setPacHybrideFiles(
          result.data.map((resource) => {
            return mapResourceTofileType(resource);
          }),
        );
      }
    });
  });

  const handleSubmit = () => {
    setButtonLoading(true);
    void requestGetMentionsPacHybrideExportExcel({
      dto: {
        query: name,
        statuses: status,
        statusesRge: statusRge,
        statusesElearning: statusElearning,
      },
    });
  };

  useEffect(() => {
    return requestGetMentionsPacHybrideExportExcel.done.watch(({ result }) => {
      afterWs(result);
    });
  });

  const afterWs = (result: ParsedResponse<Blob>) => {
    setButtonLoading(false);
    if (result.ok && result.data && result.fileName) {
      const data = new Blob([result.data], {
        type: "application/octet-stream",
      });
      FileSaver.saveAs(data, result.fileName);
      toastSuccess(t<string>("mention.messages.generation.success"));
    } else {
      toastError(t<string>("mention.messages.generation.error"));
    }
  };

  const toArray = (value?: string | null | string[]): string[] => {
    if (!value) {
      return [];
    }
    if (Array.isArray(value)) {
      return value;
    }
    return [value];
  };

  return (
    <PageLayout title={t<string>("mention.pacHybride.title")}>
      <>
        <Divider orientation="left">
          <h3 className="text-secondary mb-0">{t<string>("page.filter")}</h3>
        </Divider>
        <Form initialValues={InitialValues}>
          <Row>
            <Col xs={24} md={12}>
              <InputFormField
                showLabel
                module="mention.pacHybride.filters"
                field="query"
                onPressEnter={(e) => {
                  e.preventDefault();
                  onChangeName(e.currentTarget.value);
                }}
              />
            </Col>
            <Col xs={24} md={12}>
              <SelectFormField
                mode="multiple"
                allowClear
                showLabel
                module="mention.pacHybride.filters"
                field="status"
                options={statusOptions}
                onChange={(value) => {
                  onChangeStatus(toArray(value));
                }}
              />
            </Col>
            <Col xs={24} md={12}>
              <SelectFormField
                mode="multiple"
                allowClear
                showLabel
                module="mention.pacHybride.filters"
                field="statusRge"
                options={statusRgeOptions}
                onChange={(value) => {
                  onChangeStatusRge(toArray(value));
                }}
              />
            </Col>
            <Col xs={24} md={12}>
              <SelectFormField
                mode="multiple"
                allowClear
                showLabel
                module="mention.pacHybride.filters"
                field="statusElearning"
                options={statusElearningOptions}
                onChange={(value) => {
                  onChangeStatusElearning(toArray(value));
                }}
              />
            </Col>
          </Row>
        </Form>
        <div className="d-flex flex-column align-items-stretch flex-sm-row">
          <div className="w-100 d-flex align-items-center">
            <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
              <h3 className="text-secondary mb-0">
                {t<string>("mention.pacHybride.list_title")}
              </h3>
            </Divider>
          </div>
          <div className="w-100 d-flex align-items-center divider-sm-transparent">
            <Divider orientation="right" className="mt-2 mt-sm-3 mb-3">
              <Button
                className="btn-primary"
                onClick={handleSubmit}
                loading={buttonLoading}
              >
                <SaveOutlined /> {t<string>("common.download")}
              </Button>
            </Divider>
          </div>
        </div>
        <Table
          style={{ whiteSpace: "break-spaces" }}
          locale={{
            emptyText: <Empty description={t<string>("pages.table.noData")} />,
          }}
          loading={{ spinning: !dataHasLoaded, indicator: SpinIcon }}
          columns={columns(navigate)}
          dataSource={mentionsPacHybride.content}
          pagination={{
            total: mentionsPacHybride.totalElements,
            pageSize: mentionsPacHybride.size,
            current: mentionsPacHybride.number + 1,
            onChange: onChangePageNumber,
            onShowSizeChange: onChangePageSize,
            showSizeChanger: true,
          }}
          showSorterTooltip={false}
          rowKey={(record) => record.id}
          rowClassName="cursor-pointer"
          onRow={(record) => {
            return {
              onClick: () =>
                navigate(ROUTES.mentionsPacHybride.details.generate(record.id)),
            };
          }}
        />
        <UploadFormField
          multiple
          saveFile
          showLabel
          module="resource"
          files={pacHybrideFiles}
          setFiles={setPacHybrideFiles}
          field="pacHybride"
          domain="PAC_HYBRIDE"
          types={["application/pdf", "application/zip"]}
          formatLabel={"PDF - ZIP"}
          draggable
        />
      </>
    </PageLayout>
  );
};

export default MentionsPacHybrideScreen;
