import { ChangeEvent, FormEvent, FunctionComponent } from "react";
import { Form, Input, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { Rule } from "antd/es/form";
import { formUtils } from "@utils/form-utils";

interface PropsType {
  showLabel?: boolean;
  module: string;
  field: string | InternalNamePath;
  required?: boolean;
  emptyErrorMessage?: string;
  placeholder?: string;
  labelTooltip?: string | null;
  readOnly?: boolean;
  labelClassName?: string;
  fieldClassName?: string;
  maxLength?: number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onPressEnter?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  type?: string;
  addonAfter?: string;
  addonBefore?: string;
  textArea?: boolean;
  className?: string;
  fieldPrefix?: string;
  rules?: Rule[];
  onInput?: (e: FormEvent<HTMLInputElement>) => void;
  initialValue?: string;
  disabled?: boolean;
}

const InputFormField: FunctionComponent<PropsType> = (props: PropsType) => {
  const {
    showLabel,
    labelTooltip,
    module,
    field,
    required,
    emptyErrorMessage,
    readOnly,
    labelClassName,
    fieldClassName,
    onChange,
    onPressEnter,
    maxLength,
    type,
    addonAfter,
    addonBefore,
    placeholder,
    className = "",
    fieldPrefix = "",
    rules = [],
    onInput,
    initialValue,
    disabled,
  } = props;
  const { t } = useTranslation();

  const isRequired: boolean = !!readOnly ? false : !!required; // !(!var) => return false if var = false | null | undefined

  const i18nField = formUtils.geti18nFieldFromField(field);

  const key = `${String(field)}`.replace(",", "_");

  return (
    <>
      <Form.Item
        name={fieldPrefix + key}
        key={fieldPrefix + key}
        label={
          readOnly || showLabel ? (
            <span className={labelClassName ? labelClassName : ""}>
              {t<string>(`${module}.form.fields.${i18nField}.label`)}&nbsp;
              {labelTooltip && <Tooltip title={labelTooltip} />}
            </span>
          ) : (
            ""
          )
        }
        rules={[
          {
            required: isRequired,
            message: emptyErrorMessage
              ? emptyErrorMessage
              : t<string>("forms.errors.mandatory"),
            whitespace: isRequired,
          },
          ...rules,
        ]}
        className={className}
        initialValue={initialValue}
      >
        <Input
          disabled={readOnly || disabled}
          className={readOnly ? " readonly-form-field" : fieldClassName}
          placeholder={
            placeholder
              ? placeholder
              : t<string>(`${module}.form.fields.${i18nField}.placeholder`)
          }
          onChange={onChange}
          maxLength={maxLength}
          type={type ? type : "text"}
          addonAfter={addonAfter ? addonAfter : ""}
          addonBefore={addonBefore ? addonBefore : ""}
          onPressEnter={onPressEnter}
          onInput={onInput}
        />
      </Form.Item>
    </>
  );
};

export default InputFormField;
