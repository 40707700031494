import { Modal } from "antd";
import React from "react";
import CategoryForm from "@views/category/details/CategoryForm";
import { useTranslation } from "react-i18next";

export interface Props {
  categoryId?: string;
  isModalOpen: boolean;
  close: () => void;
}

const CategoryModal: React.FunctionComponent<Props> = (props: Props) => {
  const { categoryId, isModalOpen, close } = props;

  const { t } = useTranslation();

  return (
    <Modal
      title={t<string>(
        `${categoryId ? "edit" : "add"}Category.modal.description`,
      )}
      open={isModalOpen}
      onCancel={close}
      footer={null}
      centered
      closable
      destroyOnClose
      className="app-modal-category"
    >
      <CategoryForm categoryId={categoryId} editMode onSuccess={close} />
    </Modal>
  );
};

export default CategoryModal;
