import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "antd";
import { Store } from "rc-field-form/lib/interface";
import { AuthResult } from "@type/auth/auth.types";
import { setUser } from "@state/auth/AuthEvents";
import { requestGetUserInfo, requestLogin } from "@state/auth/AuthEffects";
import { ROUTES } from "@routes/Routes";
import { toastError } from "@utils/toast-helper";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import InputFormField from "@components/inputs/InputFormField";
import SubmitButton from "@components/buttons/SubmitButton";
import PageLayout from "@components/layouts/PageLayout";

interface FormValues {
  email: string;
  password: string;
}

const LoginScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const InitialValues = {
    login: "",
    password: "",
  };

  const handleSubmit = (values: Store) => {
    setButtonLoading(true);
    const obj = values as FormValues;

    requestLogin({
      dto: {
        email: obj.email,
        password: obj.password,
      },
    })
      .then((authResult: ParsedResponse<AuthResult>) => {
        if (authResult.data) {
          void requestGetUserInfo({}).then((authResult) => {
            if (authResult.ok && authResult.data) {
              setUser(authResult.data);
              navigate(ROUTES.admin.home.generate());
            }
          });
        } else {
          toastError(t<string>("login.messages.error"));
        }
      })
      .catch(() => {
        toastError(t<string>("login.messages.error"));
      })
      .finally(() => setButtonLoading(false));
  };

  return (
    <PageLayout noHeader noSideMenu bodyClassName="login-body">
      <>
        <div className="mb-4 d-flex justify-content-center">
          <img
            src="/logo.svg"
            alt={t<string>("login.title")}
            className="ant-col-13"
            title={t<string>("login.title")}
          />
        </div>
        <Form onFinish={handleSubmit} initialValues={InitialValues}>
          <InputFormField module="login" field="email" type="email" required />
          <InputFormField
            module="login"
            type="password"
            field="password"
            required
          />
          <div className="d-flex align-items-center justify-content-center mb-3">
            <SubmitButton
              module="login"
              isSubmitting={buttonLoading}
              label="submit"
            />
          </div>
          <div className="d-flex align-items-center justify-content-center mt-2">
            <Link to="/forgot-password">
              <span className="btn btn-link pl-0">
                {t<string>("login.form.actions.forgot-password")}
              </span>
            </Link>
          </div>
        </Form>
      </>
    </PageLayout>
  );
};

export default LoginScreen;
