import { createStore } from "effector";
import {
  addCategory,
  deleteCategory,
  setCategories,
  updateCategory,
} from "./CategoryEvents";
import { CategoryInfoResponseDto } from "@state/category/dto/category.info.response.dto";

export const CategoryStore = createStore<CategoryInfoResponseDto[]>([])
  .on<CategoryInfoResponseDto[]>(
    setCategories,
    (state: CategoryInfoResponseDto[], payload: CategoryInfoResponseDto[]) =>
      payload,
  )
  .on<CategoryInfoResponseDto>(
    addCategory,
    (state: CategoryInfoResponseDto[], payload: CategoryInfoResponseDto) => [
      ...state,
      payload,
    ],
  )
  .on<string>(
    deleteCategory,
    (state: CategoryInfoResponseDto[], payload: string) =>
      state.filter((c) => c.id !== payload),
  )
  .on<CategoryInfoResponseDto>(
    updateCategory,
    (state: CategoryInfoResponseDto[], payload: CategoryInfoResponseDto) => [
      ...state.filter((c) => c.id !== payload.id),
      payload,
    ],
  );
