import { RuleObject } from "rc-field-form/lib/interface";
import { UploadFile } from "antd/lib/upload/interface";
import { ResourceDetailsResponseDto } from "@state/resource/dto/resource.details.response.dto";
import { File } from "@type/resource/resource.type";

export interface UploadFormType {
  file: UploadFile;
  fileList: UploadFile[];
}

export const validateUploadFormField = (
  rule: RuleObject,
  value: UploadFormType,
): Promise<void | any> | void => {
  if (value && value.fileList.length === 0) {
    return Promise.reject("No file in file list");
  }
  return Promise.resolve();
};

export const uploadUtils = {
  validateUploadFormField,
};

export const mapResourceTofileType = (
  resource: ResourceDetailsResponseDto,
): File => {
  return {
    id: resource.id,
    base64Data: resource.data,
    type: resource.type,
    name: resource.filename,
    size: resource.size,
  };
};
