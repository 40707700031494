export const QUALIFICATION_CARD_TYPE: {
  [index: string]: QualificationCardTypeEnum;
} = {
  INSTALLATION: {
    value: "INSTALLATION",
    label: "gazProfessional.qualifications.PGIQualificationDate",
  },
  MAINTENANCE: {
    value: "MAINTENANCE",
    label: "gazProfessional.qualifications.PGMQualificationDate",
  },
};

export interface QualificationCardTypeEnum {
  value: "INSTALLATION" | "MAINTENANCE";
  label: string;
}
