/* eslint-disable */

import i18n from "i18next";

export const INTERVENTION_TYPES: { [index: string]: SearchType } = {
  MAINTENANCE: {
    value: "MAINTENANCE",
    label: i18n.t<string>("contacts.leads.labels.intervention.maintenance"),
  },
  INSTALLATION: {
    value: "INSTALLATION",
    label: i18n.t<string>("contacts.leads.labels.intervention.installation"),
  },
  CHEMINISTE: {
    value: "CHEMINISTE",
    label: i18n.t<string>("contacts.leads.labels.intervention.cheministe"),
  },
};

export const CURRENT_ENERGIES: { [index: string]: SearchType } = {
  GAZ_NATUREL: {
    value: "GAZ_NATUREL",
    label: i18n.t<string>("contacts.leads.labels.currentEnergy.gazNaturel"),
  },
  GAZ_PROPANE: {
    value: "GAZ_PROPANE",
    label: i18n.t<string>("contacts.leads.labels.currentEnergy.gazPropane"),
  },
  FIOUL: {
    value: "FIOUL",
    label: i18n.t<string>("contacts.leads.labels.currentEnergy.fioul"),
  },
  ELECTRICITE: {
    value: "ELECTRICITE",
    label: i18n.t<string>("contacts.leads.labels.currentEnergy.electricite"),
  },
  BOIS: {
    value: "BOIS",
    label: i18n.t<string>("contacts.leads.labels.currentEnergy.bois"),
  },
};

export const EQUIPMENT_TYPES: { [index: string]: SearchType } = {
  CHAUDIERE_THPE: {
    value: "CHAUDIERE_THPE",
    label: i18n.t<string>("contacts.leads.labels.equipmentType.chaudiereThpe"),
  },
  PAC_HYBRIDE_GAZ: {
    value: "PAC_HYBRIDE_GAZ",
    label: i18n.t<string>("contacts.leads.labels.equipmentType.pacHybrideGaz"),
  },
  POELE_ET_CHEMINEE_GAZ: {
    value: "POELE_ET_CHEMINEE_GAZ",
    label: i18n.t("contacts.leads.labels.equipmentType.poeleEtChemineeGaz"),
  },
  CHEMINEE_GAZ: {
    value: "CHEMINEE_GAZ",
    label: i18n.t<string>("contacts.leads.labels.equipmentType.chemineeGaz"),
  },
  POELE_GAZ: {
    value: "POELE_GAZ",
    label: i18n.t<string>("contacts.leads.labels.equipmentType.peoleGaz"),
  },
  TABLE_CUISSON: {
    value: "TABLE_CUISSON",
    label: i18n.t<string>("contacts.leads.labels.equipmentType.tableCuisson"),
  },
  PRISE_GAZ_USAGE_CUISSON_EXTERIEURE: {
    value: "PRISE_GAZ_USAGE_CUISSON_EXTERIEURE",
    label: i18n.t<string>(
      "contacts.leads.labels.equipmentType.priseGazUsageCuissonExterieure",
    ),
  },
  AUTRES: {
    value: "AUTRES",
    label: i18n.t("contacts.leads.labels.equipmentType.other"),
  },
};

export interface SearchType {
  value: string;
  label: string;
}
/* eslint-enable */
