import login from "./login.json";
import dashboard from "./dashboard.json";
import menu from "./menu.json";
import mention from "./mention.json";
import header from "./header.json";
import users from "./users.json";
import buttons from "./buttons.json";
import forms from "./forms.json";
import tasks from "./tasks.json";
import endpoints from "./endpoints.json";
import gazProfessional from "./gazProfessional.json";
import organisationProfessional from "./organisationProfessional.json";
import common from "./common.json";
import category from "./category.json";
import page from "./page.json";
import structure from "./structure.json";
import keyword from "./keyword.json";
import contact from "./contact.json";
import resource from "./resource.json";
import error from "./error.json";
import gazMonth from "./gazMonth.json";
import webinar from "./webinar.json";
import worksite from "./worksite.json";
import mail from "./mail.json";

const translationFR = {
  ...login,
  ...contact,
  ...dashboard,
  ...header,
  ...menu,
  ...mention,
  ...users,
  ...buttons,
  ...forms,
  ...tasks,
  ...endpoints,
  ...gazProfessional,
  ...organisationProfessional,
  ...category,
  ...page,
  ...structure,
  ...common,
  ...keyword,
  ...resource,
  ...error,
  ...gazMonth,
  ...webinar,
  ...worksite,
  ...mail,
};

export default translationFR;
