import React, { useCallback, useEffect, useState } from "react";
import { File } from "@type/resource/resource.type";
import UploadFormField from "@components/inputs/UploadFormField";
import { requestUpdateRgeMention } from "@state/mention/pachybride/MentionPacHybrideEffects";
import { requestUpdateRgeMentionSmokeDuct } from "@state/mention/smokeduct/MentionSmokeDuctEffects";

interface UploadResourceRgeProps {
  mentionId: string;
  files: File[];
  setFiles?: (files: File[]) => void;
  module: string;
  field: string;
  title?: string;
  domain?:
    | "INSTALLATION"
    | "MAINTENANCE"
    | "GLOBAL"
    | "PAC_HYBRIDE"
    | "SYNTHESIS_OFFER"
    | "SMOKE_DUCT"
    | "RGE_PAC_HYBRIDE"
    | "RGE_SMOKE_DUCT";
}

const UploadResourceRge: React.FunctionComponent<UploadResourceRgeProps> = ({
  mentionId,
  files,
  setFiles,
  module,
  field,
  domain,
  title,
}) => {
  const [oldFiles] = useState<File[]>(files);

  const handleSubmitNewRge = useCallback(() => {
    if (mentionId && files[files.length - 1]?.id) {
      if (domain && domain === "RGE_PAC_HYBRIDE") {
        void requestUpdateRgeMention({
          id: mentionId,
          dto: {
            rgeId: files[files.length - 1]?.id,
          },
        });
      } else if (domain && domain === "RGE_SMOKE_DUCT") {
        void requestUpdateRgeMentionSmokeDuct({
          id: mentionId,
          dto: {
            rgeId: files[files.length - 1]?.id,
          },
        });
      }
    }
    // Todo vérifier si ca marche toujours ici
  }, [domain, files, mentionId]);

  useEffect(() => {
    if (oldFiles != files) {
      handleSubmitNewRge();
    }
  });

  return (
    <>
      <div>{title}</div>
      <UploadFormField
        saveFile
        module={module}
        files={files}
        onChange={setFiles}
        field={field}
        domain={domain}
        unique
      />
    </>
  );
};

export default UploadResourceRge;
