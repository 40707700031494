import i18n from "i18next";
import { Tooltip } from "antd";
import BasicTag from "@components/tags/BasicTag";
import { ReactNode } from "react";
import { AUDIT_RESULTS } from "@utils/enums/audit.enum";

export const columns = [
  {
    title: i18n.t("gazProfessional.audit.historic.headers.date"),
    dataIndex: "date",
    key: "date",
    className: "audit-date",
    width: 170,
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t("gazProfessional.audit.historic.headers.installationId"),
    dataIndex: "ocCcId",
    key: "ocCcId",
    width: 140,
    render: (installationId: string): ReactNode => {
      return (
        <Tooltip placement="topLeft" title={installationId}>
          {installationId}
        </Tooltip>
      );
    },
  },
  {
    title: i18n.t("gazProfessional.audit.historic.headers.address"),
    dataIndex: "address",
    key: "address",
    ellipsis: {
      showTitle: false,
    },
    render: (address: string): ReactNode => {
      return (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      );
    },
  },
  {
    title: i18n.t("gazProfessional.audit.historic.headers.result"),
    dataIndex: "status",
    key: "status",
    className: "audit-result",
    render: (type: string): ReactNode => (
      <BasicTag
        variant={AUDIT_RESULTS[type].variant}
        label={i18n.t(AUDIT_RESULTS[type].label)}
        className="d-flex align-items-end justify-content-center"
      />
    ),
    width: 110,
    sorter: true,
    showSorterTooltip: false,
  },
];
