import { createEffect } from "effector";
import "whatwg-fetch";
import configuration from "src/utils/Config";
import {
  restDetailsHandler,
  restGetUniqueHandler,
  restIdListHandler,
  restListHandlerPagination,
  restPostHandler,
  restUpdateHandler,
} from "@utils/rest/RestClient";
import { Pageable } from "@type/pagination/pagination.types";
import { GazProfessionalItemResponseDto } from "./dto/gaz.professional.item.response.dto";
import { GazProfessionalDetailsResponseDto } from "./dto/gaz.professional.details.response.dto";
import { GazProfessionalUpdateRequestDto } from "./dto/gaz.professional.update.request.dto";
import { GazProfessionalSearchCriteria } from "@state/gazProfessional/dto/gaz.professional.search.criteria";
import { GazProfessionalUrlInitRequest } from "@state/gazProfessional/dto/gaz.professional.url.init.request";
import { GazProfessionalUrlInitResponse } from "@state/gazProfessional/dto/gaz.professional.url.init.response";
import { GPQualificationsResponseDto } from "@state/professional/dto/professional.qualifications.response.dto";
import { GazProfessionalRGRequestItemResponseDto } from "@state/gazProfessional/dto/gaz.professional.rgrequest.item.response.dto";

const baseUrl = configuration.backendBaseUrl;
const gazProfessionalUrl = baseUrl + "/gaz-professionals";

export const requestGetGazProfessionalsList = createEffect({
  handler: restListHandlerPagination<
    Pageable<GazProfessionalItemResponseDto>,
    GazProfessionalSearchCriteria
  >(gazProfessionalUrl),
});

export const requestGetGazProfessionalsExport = createEffect({
  handler: restGetUniqueHandler<Blob, GazProfessionalSearchCriteria>(
    `${gazProfessionalUrl}/export`,
  ),
});

export const requestGetGazProfessional = createEffect({
  handler: restDetailsHandler<GazProfessionalDetailsResponseDto>(
    `${gazProfessionalUrl}/`,
  ),
});

export const requestUpdateGazProfessional = createEffect({
  handler: restUpdateHandler<
    GazProfessionalUpdateRequestDto,
    GazProfessionalDetailsResponseDto
  >(`${gazProfessionalUrl}/`),
});

export const requestPostGazProfessionalNewUrlInitialisation = createEffect({
  handler: restPostHandler<
    GazProfessionalUrlInitRequest,
    GazProfessionalUrlInitResponse
  >(`${gazProfessionalUrl}/registration-support`),
});

export const requestGetGazProfessionalQualifications = createEffect({
  handler: restDetailsHandler<GPQualificationsResponseDto>(
    `${gazProfessionalUrl}/`,
    "/qualifications",
  ),
});

export const requestGetGazProfessionalRGRequests = createEffect({
  handler: restIdListHandler<GazProfessionalRGRequestItemResponseDto>(
    `${gazProfessionalUrl}/`,
    "/rg-requests",
  ),
});
