import { FieldData, InternalNamePath } from "rc-field-form/lib/interface";
import moment from "moment";
import i18n from "i18next";
import { NamePath } from "antd/lib/form/interface";
import { ApiResponseDto } from "@type/rest.types";
import { FieldErrors } from "@utils/rest/ServerResponseParse";
import { Field } from "@type/form/field.types";

interface DateProperties {
  [property: string]: string;
}

interface IgnoreProperties {
  [nestingLevel: number]: string[];
}

interface RecursiveProperties {
  nestingLevel: number;
  upperEntityName: string;
}

function buildFieldData(
  data: ApiResponseDto,
  ignoreProperties: IgnoreProperties = {},
  dateFormatProperties: DateProperties = {},
  selectFields: string[] = [],
  lowerEntities: string[] = [],
  recursiveProperties: RecursiveProperties = {
    nestingLevel: 0,
    upperEntityName: "",
  },
): FieldData[] {
  let result: FieldData[] = [];
  Object.keys(data)
    .filter(
      (property) =>
        data[property] &&
        (!ignoreProperties[recursiveProperties.nestingLevel] ||
          !ignoreProperties[recursiveProperties.nestingLevel].includes(
            property,
          )),
    )
    .forEach((property) => {
      if ((data[property] as ApiResponseDto).id) {
        result = result.concat(
          buildFieldData(
            data[property],
            ignoreProperties,
            dateFormatProperties,
            selectFields,
            lowerEntities,
            {
              nestingLevel: recursiveProperties.nestingLevel + 1,
              upperEntityName: lowerEntities.includes(property)
                ? recursiveProperties.upperEntityName.concat(`${property}.`)
                : recursiveProperties.upperEntityName,
            },
          ),
        );
      } else if (Object.keys(dateFormatProperties).includes(property)) {
        result.push({
          name: property,
          value: moment(data[property], dateFormatProperties[property]),
        });
      } else if (selectFields.includes(property)) {
        result.push({
          name: property,
          value: (data[property] as { value: string }).value.toString(),
        });
      } else {
        result.push({
          name: recursiveProperties.upperEntityName + property,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          value: data[property],
        });
      }
    });
  return result;
}

const replaceKey = (originalName: NamePath, keys: string[]): string => {
  const name = originalName.toString();
  const fields = name.split(".");
  const key = fields.find((field: string): boolean => !isNaN(Number(field)));
  if (key) {
    const index = fields.findIndex((field: string): boolean => field === key);
    fields[index] = keys[parseInt(key)];
    return fields.join(".");
  }
  return name;
};

function addErrorsToFieldsData(
  fieldsData: FieldData[],
  fieldsErrors: FieldErrors,
  keys?: string[],
): FieldData[] {
  const newFieldsData = [...fieldsData];
  Object.keys(fieldsErrors).forEach((fieldError: string) => {
    const fieldData = fieldsData.find((f: FieldData) => f.name === fieldError);
    const fieldDataIndex = fieldsData.findIndex(
      (f: FieldData) => f.name === fieldError,
    );
    if (fieldData) {
      newFieldsData.splice(fieldDataIndex, 0, {
        ...fieldData,
        name: fieldError,
        errors: [fieldsErrors[fieldError]],
      });
    } else {
      newFieldsData.push({
        name: fieldError,
        errors: [i18n.t(`error.${fieldsErrors[fieldError]}`)],
      });
    }
  });
  if (keys) {
    return newFieldsData.map((fieldData: FieldData) => ({
      name: replaceKey(fieldData.name, keys),
      errors: fieldData.errors,
    }));
  } else {
    return newFieldsData;
  }
}

const generateRndKeyInteger = (): number => {
  return Math.floor(Math.random() * 100000);
};

const geti18nFieldFromField = (field: string | InternalNamePath): string => {
  return typeof field === "string"
    ? field.split(".")[field.split(".").length - 1]
    : geti18nFieldFromFieldArray(field);
};

const geti18nFieldFromFieldArray = (field: InternalNamePath): string => {
  return Array.isArray(field)
    ? (field[field.length - 1] as string).replace(/[0-9]/g, "")
    : "";
};

const mapFieldsFrom = (dto: {
  [key: string]: string | number | undefined;
}): Field[] => {
  return Object.keys(dto).map((field) => ({
    name: [field],
    value: dto[field],
  }));
};

export const formUtils = {
  buildFieldData,
  addErrorsToFieldsData,
  generateRndKeyInteger,
  geti18nFieldFromField,
  mapFieldsFrom,
};
