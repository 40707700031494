import React from "react";
import { Tag } from "antd";
import i18n from "i18next";

export interface Props {
  method: string;
  className?: string;
}

interface MethodParams {
  label: string;
  color: string;
}

export const ENDPOINT_METHODS: { [index: string]: MethodParams } = {
  POST: { label: i18n.t<string>("endpoints.methods.post"), color: "#49cc90" },
  PUT: { label: i18n.t<string>("endpoints.methods.put"), color: "#fca130" },
  PATCH: { label: i18n.t<string>("endpoints.methods.patch"), color: "#50e3c2" },
  DELETE: {
    label: i18n.t<string>("endpoints.methods.delete"),
    color: "#f93e3e",
  },
  GET: { label: i18n.t<string>("endpoints.methods.get"), color: "#61affe" },
};

const EndpointMethodTag: React.FunctionComponent<Props> = (props: Props) => {
  const { method, className } = props;

  return (
    <Tag className={className} color={ENDPOINT_METHODS[method].color}>
      {ENDPOINT_METHODS[method].label}
    </Tag>
  );
};

export default EndpointMethodTag;
