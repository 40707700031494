import { OptionType } from "@type/form/field.types";
import { RG_REQUEST_STATUS } from "@utils/enums/gaz.professional.qualifications.rg-requests.status.enum";
import { RG_REQUEST_TYPE } from "@utils/enums/gaz.professional.qualifications.rg-requests.type.enum";

export const RG_REQUEST_FILTERS_STATUS = [
  {
    value: undefined,
    label: "common.all",
  },
  {
    value: RG_REQUEST_STATUS.IN_PROGRESS.value,
    label: RG_REQUEST_STATUS.IN_PROGRESS.label,
  },
  {
    value: RG_REQUEST_STATUS.PENDING.value,
    label: RG_REQUEST_STATUS.PENDING.label,
  },
  {
    value: RG_REQUEST_STATUS.TO_FIX.value,
    label: RG_REQUEST_STATUS.TO_FIX.label,
  },
  {
    value: RG_REQUEST_STATUS.ARCHIVED.value,
    label: RG_REQUEST_STATUS.ARCHIVED.label,
  },
] as OptionType[];

export const RG_REQUEST_FILTERS_TYPE = [
  {
    value: undefined,
    label: "common.all",
  },
  {
    value: RG_REQUEST_TYPE.ADD.value,
    label: RG_REQUEST_TYPE.ADD.filterLabel,
  },
  {
    value: RG_REQUEST_TYPE.RAD.value,
    label: RG_REQUEST_TYPE.RAD.filterLabel,
  },
  {
    value: RG_REQUEST_TYPE.UPDATE.value,
    label: RG_REQUEST_TYPE.UPDATE.filterLabel,
  },
] as OptionType[];
