import {
  CalendarOutlined,
  FileDoneOutlined,
  FolderViewOutlined,
  HomeOutlined,
  MailOutlined,
  MessageOutlined,
  OrderedListOutlined,
  ReadOutlined,
  TagOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { ProfileEnum } from "@utils/enums/profile.enum";
import { FeatureItem } from "@type/feature/feature.types";
import { ROUTES } from "@routes/Routes";

export const featureItems: { [key: string]: FeatureItem } = {
  home: {
    key: "home",
    link: ROUTES.admin.home.generate(),
    icon: <HomeOutlined />,
    text: "menu.home",
    profiles: [],
  },
  users: {
    key: "users",
    link: ROUTES.admin.users.list.generate(),
    icon: <TeamOutlined />,
    text: "menu.users",
    profiles: [ProfileEnum.ADMIN],
  },
  pages: {
    key: "pages",
    link: ROUTES.cms.pages.list.generate(),
    icon: <ReadOutlined />,
    text: "menu.pages",
    profiles: [ProfileEnum.CMS],
  },
  keywords: {
    key: "mots-clés",
    link: ROUTES.cms.keywords.details.generate(),
    icon: <TagOutlined />,
    text: "menu.keyword",
    profiles: [ProfileEnum.CMS],
  },
  structure: {
    key: "structure",
    link: ROUTES.cms.structure.details.generate(),
    icon: <OrderedListOutlined />,
    text: "menu.structure",
    profiles: [ProfileEnum.CMS],
  },
  gazProfessionals: {
    key: "gaz-professionals",
    link: ROUTES.gazProfessionals.list.generate(),
    icon: <TeamOutlined />,
    text: "menu.gazProfessionals",
    profiles: [ProfileEnum.SERVICES],
  },
  organisationProfessional: {
    key: "organisation-professionals",
    link: ROUTES.organisationProfessionals.list.generate(),
    icon: <TeamOutlined />,
    text: "menu.organisationProfessionals",
    profiles: [ProfileEnum.SERVICES],
  },
  leads: {
    key: "leads",
    link: "/leads",
    icon: <MessageOutlined />,
    text: "menu.leads",
    profiles: [ProfileEnum.SERVICES],
  },
  leadsMap: {
    key: "leads-map",
    link: ROUTES.leads.list.generate(),
    icon: <MessageOutlined />,
    text: "menu.leadsMap",
    profiles: [ProfileEnum.SERVICES],
  },
  leadsEvent: {
    key: "leads-event",
    link: ROUTES.leadsEvent.list.generate(),
    icon: <MessageOutlined />,
    text: "menu.leadsEvent",
    profiles: [ProfileEnum.SERVICES],
  },
  contacts: {
    key: "contacts",
    link: ROUTES.contacts.list.generate(),
    icon: <MailOutlined />,
    text: "menu.contacts",
    profiles: [ProfileEnum.SERVICES],
  },
  mentions: {
    key: "mentions",
    link: "/mentions",
    icon: <FileDoneOutlined />,
    text: "menu.mentions",
    profiles: [ProfileEnum.SERVICES],
  },
  mentionsPacHybride: {
    key: "mentionsPacHybride",
    link: ROUTES.mentionsPacHybride.list.generate(),
    icon: <FileDoneOutlined />,
    text: "menu.mentionsPacHybride",
    profiles: [ProfileEnum.SERVICES],
  },
  mentionsGreenGaz: {
    key: "mentionsGreenGaz",
    link: ROUTES.mentionsGreenGaz.list.generate(),
    icon: <FileDoneOutlined />,
    text: "menu.mentionsGreenGaz",
    profiles: [ProfileEnum.SERVICES],
  },
  mentionsSmokeDuct: {
    key: "mentionsSmokeDuct",
    link: ROUTES.mentionsSmokeDuct.list.generate(),
    icon: <FileDoneOutlined />,
    text: "menu.mentionsSmokeDuct",
    profiles: [ProfileEnum.SERVICES],
  },
  worksites: {
    key: "worksites",
    link: ROUTES.worksites.list.generate(),
    icon: <FileDoneOutlined />,
    text: "menu.worksites",
    profiles: [ProfileEnum.SERVICES],
  },
  resources: {
    key: "resources",
    link: ROUTES.resources.list.generate(),
    icon: <FolderViewOutlined />,
    text: "menu.resources",
    profiles: [ProfileEnum.SERVICES],
  },
  mail: {
    key: "mail",
    link: ROUTES.management.mails.generate(),
    icon: <MailOutlined />,
    text: "menu.mail",
    profiles: [ProfileEnum.SERVICES],
  },
  gazMonth: {
    key: "gazMonth",
    link: ROUTES.gazProm.generate(),
    icon: <CalendarOutlined />,
    text: "menu.gazMonth",
    profiles: [ProfileEnum.SERVICES],
  },
  events: {
    key: "events",
    link: ROUTES.gazProm.generate(),
    icon: <CalendarOutlined />,
    text: "menu.events",
    profiles: [ProfileEnum.SERVICES],
  },
  webinar: {
    key: "webinar",
    link: ROUTES.webinar.generate(),
    icon: <CalendarOutlined />,
    text: "menu.webinar",
    profiles: [ProfileEnum.SERVICES],
  },
};
