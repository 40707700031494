import { FunctionComponent, ReactElement } from "react";
import SideMenu from "@components/menus/SideMenu";
import Header from "@components/headers/Header";
import Footer from "@components/footers/Footer";
import BasicCard from "@components/cards/BasicCard";
import Breadcrumbs, {
  BreadcrumbsItem,
} from "@components/breadcrumbs/Breadcrumbs";

interface PageLayoutProps {
  title?: string | null;
  subtitle?: string;
  headerClassName?: string;
  bodyClassName?: string;
  container?: boolean;
  noHeader?: boolean;
  noFooter?: boolean;
  noSideMenu?: boolean;
  noContentInCard?: boolean;
  breadcrumbs?: BreadcrumbsItem[];
  children: string | ReactElement;
}

const PageLayout: FunctionComponent<PageLayoutProps> = ({
  title = null,
  subtitle,
  headerClassName = "",
  bodyClassName = "",
  container = true,
  noHeader = false,
  noFooter = false,
  noSideMenu = false,
  noContentInCard = false,
  breadcrumbs = null,
  children,
}) => {
  return (
    <>
      {!noHeader && <Header />}
      <main>
        {!noSideMenu && <SideMenu />}
        <div
          className={`scrollable-container ${noHeader ? "" : "with-header"}`}
        >
          <div
            className={`app-content position-relative ${
              container ? "content-contained" : ""
            } py-5 px-4`}
          >
            {breadcrumbs && (
              <Breadcrumbs
                breadcrumbs={breadcrumbs}
                className={!noSideMenu ? "is-side-menu" : ""}
              />
            )}
            {title && (
              <div className={`content-header pb-4 ${headerClassName}`}>
                <h1 className="text-center it-title">{title}</h1>
                {subtitle && (
                  <h4 className="text-center text-secondary">{subtitle}</h4>
                )}
              </div>
            )}
            <div className={`content-body ${bodyClassName}`}>
              {!noContentInCard ? <BasicCard>{children}</BasicCard> : children}
            </div>
          </div>
          {!noFooter && <Footer />}
        </div>
      </main>
    </>
  );
};

export default PageLayout;
