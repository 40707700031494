import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import PageLayout from "@components/layouts/PageLayout";
import PageForm from "@views/page/details/PageForm";

const PageScreen: FC = () => {
  const { t } = useTranslation();
  const { pageId } = useParams<{ pageId: string }>();

  const [editMode, setEditMode] = useState<boolean>(false);

  return (
    <PageLayout
      title={t<string>(editMode ? "page.editTitle" : "page.detailsTitle")}
    >
      <PageForm
        pageId={pageId ? pageId : ""}
        editMode={editMode}
        setEditMode={setEditMode}
      />
    </PageLayout>
  );
};

export default PageScreen;
