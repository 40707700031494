import React from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import {
  Block,
  BlockInput,
  ImageContent,
  InputImageProperties,
  InputProperties,
  InputTextAreaListProperties,
  InputTextAreaProperties,
  InputTextProperties,
  ListProperties,
} from "@type/page/page.types";
import InputFormField from "@components/inputs/InputFormField";
import TextAreaListFormField, {
  TextAreaListValue,
} from "@components/inputs/TextAreaListFormField";
import JSONFormat from "@utils/JSONFormat";
import { HtmlEditor } from "@components/editor/HtmlEditor";
import UploadImageForm from "@components/inputs/UploadImageForm";
import TextAreaFormField from "@components/inputs/TextAreaFormField";
import ListFormField, { ListValue } from "@components/block/ListFormField";
import { SelectFormField } from "@components/inputs/SelectFormField";
import FilesBlock from "@components/block/FilesBlock";
import { File } from "@type/resource/resource.type";

export interface Props {
  editMode?: boolean;
  indexBlock: number;
  indexInput: number;
  block: Block;
  input: BlockInput;
  handleUpdateBlock: (block: Block, index: number) => void;
  handleContentChange: (
    content: string | ListValue[] | ImageContent,
    inputIndex?: number,
    inputId?: string,
  ) => void;
  inputId?: string;
}

export const PageBlockInput: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const { t } = useTranslation();

  const {
    block,
    input,
    indexBlock,
    indexInput,
    handleUpdateBlock,
    handleContentChange,
    editMode,
    inputId,
  } = props;

  const handleFilesChange = (files: File[]) => {
    block.inputs[0].content = JSON.stringify(files);
    handleUpdateBlock(block, indexBlock);
  };

  const renderInputFrom = (inputIndex: number, blockCode: string) => {
    switch (input.inputType) {
      case "TEXT": {
        if (input.properties) {
          const textProperties =
            input && (input.properties as InputTextProperties);
          return (
            <Form.Item
              name="textContent"
              key={indexBlock}
              rules={[
                {
                  required:
                    textProperties.required && input?.content == undefined,
                  message: t<string>("forms.errors.mandatory"),
                },
              ]}
            >
              <HtmlEditor
                handleTextContentChange={handleContentChange}
                inputIndex={inputIndex}
                text={input.content as string}
                editMode={editMode}
              />
            </Form.Item>
          );
        }
        return null;
      }
      case "IMG": {
        if (input.properties) {
          const imgProperties =
            input && (input.properties as InputImageProperties);
          return (
            <UploadImageForm
              className="mx-5"
              formFieldName={"image"}
              formFieldLabel={t<string>(`page.block.${blockCode}.image.label`)}
              fieldPrefix={inputId ?? `${indexBlock}_${inputIndex}`}
              inputIndex={inputIndex}
              onChangeCallBack={handleContentChange}
              imageContent={input.content as ImageContent}
              editMode={editMode}
              inputId={inputId}
              required={imgProperties.required}
            />
          );
        }
        return null;
      }

      case "TEXTAREA": {
        if (input.properties) {
          const textareaProperties =
            input.properties as InputTextAreaProperties;
          return (
            <TextAreaFormField
              key={textareaProperties.name}
              readOnly={!editMode}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                handleContentChange(e.target.value, inputIndex, inputId)
              }
              showLabel
              module={`page.block.${blockCode}`}
              field={textareaProperties.name}
              initialValue={input.content as string}
              fieldPrefix={inputId ?? `${indexBlock}_${blockCode}_`}
              required={textareaProperties.required}
            />
          );
        }
        return null;
      }
      case "TEXTAREA_LIST": {
        if (input.properties) {
          const listProperties =
            input.properties as InputTextAreaListProperties;
          return (
            <TextAreaListFormField
              key={listProperties.name}
              readOnly={!editMode}
              onChange={handleContentChange}
              showLabel
              module={`page.block.${blockCode}`}
              field={listProperties.name}
              initialValues={
                input.content
                  ? (JSONFormat.parseJSON(input.content) as TextAreaListValue[])
                  : []
              }
              fieldPrefix={`${blockCode}_`}
              inputIndex={inputIndex}
            />
          );
        }
        return null;
      }
      case "TEXT_FIELD": {
        if (input.properties) {
          const properties = input.properties as InputProperties;
          return (
            <InputFormField
              readOnly={!editMode}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleContentChange(e.target.value, inputIndex, inputId)
              }
              showLabel
              module={`page.block.${blockCode}`}
              field={properties.name}
              initialValue={input.content as string}
              fieldPrefix={inputId ?? `${indexBlock}_${inputIndex}`}
              required={properties.required}
              maxLength={properties.maxLength}
            />
          );
        }
        return null;
      }
      case "SELECT": {
        if (input.properties) {
          const properties = input.properties as InputProperties;
          return (
            <>
              {properties.options && (
                <SelectFormField
                  readOnly={!editMode}
                  onSelect={(value) =>
                    handleContentChange(value, inputIndex, inputId)
                  }
                  options={properties.options}
                  showLabel
                  module={`page.block.${blockCode}`}
                  field={properties.name}
                  initialValue={input.content as string}
                  fieldPrefix={inputId ?? `${indexBlock}_${inputIndex}`}
                  required={properties.required}
                />
              )}
            </>
          );
        }
        return null;
      }
      case "LIST": {
        const properties = input.properties as ListProperties;
        return (
          <ListFormField
            readOnly={!editMode}
            listInputIndex={inputIndex}
            onChange={handleContentChange}
            properties={properties}
            handleUpdateBlock={handleUpdateBlock}
            initialValues={input.content as ListValue[]}
            block={block}
            indexBlock={indexBlock}
          />
        );
      }
      case "FILES":
        return (
          <FilesBlock
            handleFilesChange={handleFilesChange}
            key={`inputFiles${indexBlock}${inputIndex}`}
            initialFiles={
              input.content && typeof input.content === "string"
                ? (JSON.parse(input.content) as File[])
                : []
            }
            editMode={editMode}
          />
        );
      default:
        return null;
    }
  };

  return <>{renderInputFrom(indexInput, block.blockId)}</>;
};

PageBlockInput.defaultProps = {
  editMode: true,
};
