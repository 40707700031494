import React from "react";
import { Tag } from "antd";
import i18n from "i18next";

export interface Props {
  contactStatus: string;
  className?: string;
}

interface ContactStatus {
  label: string;
  color: string;
  value: string;
}

export const STATUS: { [index: string]: ContactStatus } = {
  UNREAD: {
    label: i18n.t<string>("contacts.leads.labels.status.UNREAD"),
    color: "#405faf",
    value: "UNREAD",
  },
  READ: {
    label: i18n.t<string>("contacts.leads.labels.status.READ"),
    color: "#00b2a9",
    value: "READ",
  },
  PROCESSED: {
    label: i18n.t<string>("contacts.leads.labels.status.PROCESSED"),
    color: "#00b2a9",
    value: "PROCESSED",
  },
  SENT: {
    label: i18n.t<string>("contacts.leads.labels.status.SENT"),
    color: "#00b2a9",
    value: "SENT",
  },
  CONCLUDED: {
    label: i18n.t<string>("contacts.leads.labels.status.CONCLUDED"),
    color: "#00b2a9",
    value: "CONCLUDED",
  },
  ABANDONED: {
    label: i18n.t<string>("contacts.leads.labels.status.ABANDONED"),
    color: "#e2003c",
    value: "ABANDONED",
  },
};

const ContactStatusTag: React.FunctionComponent<Props> = (props: Props) => {
  const { contactStatus, className } = props;

  return (
    <Tag className={className} color={STATUS[contactStatus].color}>
      {STATUS[contactStatus].label}
    </Tag>
  );
};

export default ContactStatusTag;
