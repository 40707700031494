import { createEffect } from "effector/effector.mjs";
import { restDetailsHandler, restListHandler } from "@utils/rest/RestClient";
import configuration from "@utils/Config";
import { WebinarItemResponse } from "@state/webinar/dto/webinar.item.response";

const baseUrl = configuration.backendBaseUrl;
const urlWebinars = baseUrl + "/webinars";

export const requestGetWebinars = createEffect({
  handler: restListHandler<WebinarItemResponse>(`${urlWebinars}`),
});

export const requestGetWebinarRegistrationsExport = createEffect({
  handler: restDetailsHandler<Blob>(`${urlWebinars}/`, "/registrations-export"),
});
