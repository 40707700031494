import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import BasicButton from "@components/buttons/BasicButton";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { clearFilter } from "@state/auth/AuthEvents";
import { MENU } from "@utils/enums/menu.enum";
import { isProfileAmong } from "@utils/profile-helper";
import { Collapse } from "antd";
import { ProfileEnum } from "@utils/enums/profile.enum";
import { FeatureItem } from "@type/feature/feature.types";

interface SideMenuProps {
  className?: string;
}

const SideMenu: FunctionComponent<SideMenuProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const [selectedKey, setSelectedKey] = useState("home");
  const [selectedParentKey, setSelectedParentKey] = useState("");
  const [isMobileSideMenuOpen, setIsMobileSideMenuOpen] =
    useState<boolean>(false);

  const authentifiedContext = useStore(authentifiedStore);

  const profile: ProfileEnum | undefined = authentifiedContext?.user?.profile;

  useEffect(() => {
    MENU.forEach((item) => {
      if (item.link && window.location.pathname === item.link) {
        setSelectedParentKey(item.key);
        setSelectedKey("");
      }
      if (item.children) {
        item.children.forEach((child) => {
          if (child.link && window.location.pathname === child.link) {
            setSelectedParentKey(item.key);
            setSelectedKey(child.key);
          }
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const buildItemFrom = (item: FeatureItem): ReactElement => {
    return (
      <>
        <div className="icon me-3">{item.icon}</div>
        <div className="text">{t<string>(item.text)}</div>
      </>
    );
  };

  return (
    <>
      <div
        className={`app-sidemenu-overlay ${
          isMobileSideMenuOpen ? "d-block" : "d-none"
        }`}
        onClick={() => setIsMobileSideMenuOpen(false)}
      />
      <div
        className={`app-sidemenu d-flex flex-column ${
          isMobileSideMenuOpen ? "mobile-sidemenu-open" : ""
        } ${className}`}
      >
        <div className="position-relative">
          <BasicButton
            variant="side-menu"
            icon={isMobileSideMenuOpen ? <CloseOutlined /> : <MenuOutlined />}
            onClick={() => setIsMobileSideMenuOpen(!isMobileSideMenuOpen)}
          />
        </div>
        <div className="sidemenu-container flex-grow-1">
          {profile &&
            MENU.filter((item) => isProfileAmong(profile, item.profiles)).map(
              (item) =>
                item.children && item.children.length > 0 ? (
                  <Collapse
                    key={item.key}
                    className={`sidemenu-link-collapse ${
                      selectedParentKey === item.key
                        ? "sidemenu-link-collapse-active"
                        : ""
                    }`}
                    expandIconPosition="end"
                  >
                    <Collapse.Panel
                      header={
                        <div className="d-flex align-items-center">
                          {buildItemFrom(item)}
                        </div>
                      }
                      key={item.key}
                    >
                      <>
                        {item.children.map((child) => (
                          <div
                            key={child.key}
                            className={`collapse-link ${
                              selectedKey === child.key
                                ? "collapse-link-active"
                                : ""
                            }`}
                          >
                            <Link
                              to={child.link}
                              className="d-flex align-items-center"
                            >
                              {buildItemFrom(child)}
                            </Link>
                          </div>
                        ))}
                      </>
                    </Collapse.Panel>
                  </Collapse>
                ) : item.link ? (
                  <Link
                    to={item.link}
                    key={item.key}
                    className={`link d-flex align-items-center ${
                      selectedParentKey === item.key ? "link-active" : ""
                    }`}
                    onClick={() => clearFilter()}
                  >
                    {buildItemFrom(item)}
                  </Link>
                ) : (
                  <div
                    key={item.key}
                    className="link d-flex align-items-center"
                  >
                    {buildItemFrom(item)}
                  </div>
                ),
            )}
        </div>
      </div>
    </>
  );
};

export default SideMenu;
