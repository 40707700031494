import i18n from "i18next";
import { ReactNode } from "react";
import BasicTag from "@components/tags/BasicTag";
import { IconNotValid, IconValid, IconWaiting } from "../../../static/icons";
import { maptoQualificationRgStatus } from "@utils/rgStatusMapper";
import { OrganisationProfessionalRGItemResponseDto } from "@state/organisationProfessional/dto/organisation.professional.responsable.gaz.item.response.dto";

const getIconFromRGStatus = (status: string) => {
  switch (status) {
    case "VALID":
      return <IconValid />;
    case "EXPIRED":
      return <IconNotValid />;
    case "EXPIRED_SOON":
      return <IconWaiting />;
    case "FIRED":
      return <></>;
    default:
      return <></>;
  }
};
export const columns = [
  {
    title: i18n.t(
      "organisationProfessional.details.listRGs.table.headers.code",
    ),
    dataIndex: "code",
    key: "code",
    width: 200,
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t(
      "organisationProfessional.details.listRGs.table.headers.name",
    ),
    dataIndex: "name",
    key: "name",
    ellipsis: {
      showTitle: false,
    },
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t(
      "organisationProfessional.details.listRGs.table.headers.companyName",
    ),
    dataIndex: "companyName",
    key: "companyName",
    ellipsis: {
      showTitle: false,
    },
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t(
      "organisationProfessional.details.listRGs.table.headers.qualifications",
    ),
    key: "qualifications",
    width: 201,
    render: (record: OrganisationProfessionalRGItemResponseDto): ReactNode => {
      const iStatus = maptoQualificationRgStatus(record.pgiStatus);
      const mStatus = maptoQualificationRgStatus(record.pgmStatus);
      return record.pgiStatus || record.pgmStatus ? (
        <div className="app-rg-tags">
          <div className="rg-tag">
            {record.pgiStatus && (
              <BasicTag
                label={i18n.t(
                  "organisationProfessional.details.listRGs.table.rgi",
                )}
                icon={getIconFromRGStatus(record.pgiStatus)}
                variant={iStatus.variant}
              />
            )}
          </div>
          <div className="rg-tag">
            {record.pgmStatus && (
              <BasicTag
                label={i18n.t(
                  "organisationProfessional.details.listRGs.table.rgm",
                )}
                icon={getIconFromRGStatus(record.pgmStatus)}
                variant={mStatus.variant}
              />
            )}
          </div>
        </div>
      ) : (
        ""
      );
    },
  },
];
