import { createEffect } from "effector";
import "whatwg-fetch";
import configuration from "src/utils/Config";
import { restGetUniqueHandler } from "@utils/rest/RestClient";
import { GazProfessionalSearchCriteria } from "@state/gazProfessional/dto/gaz.professional.search.criteria";

const baseUrl = configuration.backendBaseUrl;
const greenGazUrl = baseUrl + "/mentions/green-gaz";

export const requestGetGreenGazExport = createEffect({
  handler: restGetUniqueHandler<Blob, GazProfessionalSearchCriteria>(
    `${greenGazUrl}/export`,
  ),
});
