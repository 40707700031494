import { FC, useEffect, useState } from "react";
import { Button, Divider, Empty, Table } from "antd";
import { useTranslation } from "react-i18next";
import { KeywordInfoResponseDTO } from "@state/keyword/dto/keyword.response.dto";
import { columns } from "@components/lists/KeywordsColumns";
import { requestDeleteKeyword } from "@state/keyword/KeywordEffects";
import { deleteKeyword } from "@state/keyword/KeywordEvents";
import { KeywordModal } from "@views/keyword/add/KeywordModal";
import { useKeywords } from "@state/keyword/Keywords";
import { PlusCircleOutlined } from "@ant-design/icons";
import PageLayout from "@components/layouts/PageLayout";
import { toastError, toastSuccess } from "@utils/toast-helper";

const KeywordsScreen: FC = () => {
  const { t } = useTranslation();
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<KeywordInfoResponseDTO | undefined>(
    undefined,
  );
  const keywordStore: KeywordInfoResponseDTO[] = useKeywords();

  useEffect(() => {
    return requestDeleteKeyword.done.watch(({ params, result }) => {
      if (result.ok) {
        deleteKeyword(params.dto.id);
        toastSuccess(t<string>("keywords.global_buttons.delete.success"));
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  return (
    <PageLayout title={t<string>("keywords.title")}>
      <>
        <div className="d-flex flex-column align-items-stretch flex-sm-row">
          <div className="w-100 d-flex align-items-center">
            <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
              <h3 className="text-secondary mb-0">
                {t<string>("keywords.list_title")}
              </h3>
            </Divider>
          </div>
          <div className="w-100 d-flex align-items-center divider-sm-transparent">
            <Divider orientation="right" className="mt-2 mt-sm-3 mb-3">
              <Button
                type="default"
                className="btn-secondary"
                onClick={() => {
                  setKeyword(undefined);
                  setCreateModalVisible(true);
                }}
              >
                <PlusCircleOutlined />
                {t<string>("keywords.global_buttons.add")}
              </Button>
              <KeywordModal
                visible={createModalVisible || updateModalVisible}
                setVisible={
                  (createModalVisible && setCreateModalVisible) ||
                  setUpdateModalVisible
                }
                keyword={keyword}
              />
            </Divider>
          </div>
        </div>

        <Table
          locale={{
            emptyText: <Empty description={t<string>("common.noData")} />,
          }}
          columns={columns()}
          dataSource={keywordStore}
          showSorterTooltip={false}
          rowKey={(record) => record.id}
          onRow={(record) => {
            return {
              onClick: () => {
                setKeyword(record);
                setUpdateModalVisible(true);
              },
            };
          }}
        />
      </>
    </PageLayout>
  );
};

export default KeywordsScreen;
