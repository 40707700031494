const formatCurrency = (value: number | string | undefined): string => {
  if (typeof value === "string" && value.startsWith(",")) {
    value = value.substring(1);
  }
  if (value === undefined) {
    return "";
  }
  return `${value} €`.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const parseCurrency = (displayValue: string | undefined): string | number => {
  if (displayValue) {
    return displayValue.replace(/€|&euro|\s/g, "");
  }
  return "";
};

const formatNumber = (value: number | string | undefined): string => {
  if (typeof value === "string" && value.startsWith(",")) {
    value = value.substring(1);
  }
  if (value === undefined) {
    return "";
  }
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const inputNumberFormat = {
  formatCurrency,
  parseCurrency,
  formatNumber,
};
