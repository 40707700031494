import { FC, Key, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider, Popconfirm, Tag, Tree } from "antd";
import {
  EditOutlined,
  FileOutlined,
  FolderOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  requestGetStructure,
  requestUpdateStructure,
} from "src/state/structure/StructureEffects";
import { StructureDto } from "@state/structure/dto/structure.dto";
import { DataNode, EventDataNode } from "antd/lib/tree";
import { NodeDragEventParams } from "rc-tree/lib/contextTypes";
import PageLayout from "@components/layouts/PageLayout";
import PagesStatusTag from "@views/page/details/PagesStatusTag";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { useMyContext } from "../../MyContext";
import { Link, useLocation } from "react-router-dom";
import {
  StructureStaticKeys,
  StructureTypes,
} from "@utils/enums/structure.enums";
import PreviewPageLink from "@views/structure/PreviewPageLink";
import { ROUTES } from "@routes/Routes";
import CategoryModal from "@views/category/modal/CategoryModal";
import BasicButton from "@components/buttons/BasicButton";
import { useCategoriesManager } from "@state/category/Categories";
import {
  requestCreateCategory,
  requestDeleteCategory,
  requestUpdateCategory,
} from "@state/category/CategoryEffects";
import DeleteCategory from "@views/category/delete/DeleteCategories";

type StructureDragNDropInfo = NodeDragEventParams<DataNode, HTMLDivElement> & {
  dragNode: EventDataNode<DataNode>;
  dragNodesKeys: Key[];
  dropPosition: number;
  dropToGap: boolean;
};

interface StructureCallbackParam {
  item: StructureDto;
  index: number;
  arr: StructureDto[];
  parent: StructureDto | undefined;
}

type StructureCallback = (param: StructureCallbackParam) => void;

const findAndApply = (
  data: StructureDto[],
  key: string | number,
  callback: StructureCallback,
  parentNode?: StructureDto,
): boolean => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].key === key) {
      callback({ item: data[i], index: i, arr: data, parent: parentNode });
      return true;
    } else if (
      data[i].children &&
      findAndApply(data[i].children, key, callback, data[i])
    ) {
      return true;
    }
  }
  return false;
};

const deepCopy = (datas: StructureDto[] | null | undefined): StructureDto[] => {
  if (datas) {
    return datas.map((data) => ({
      ...data,
      children: deepCopy(data.children),
    }));
  }
  return [];
};

const StructureScreen: FC = () => {
  const { fetchCategories } = useCategoriesManager();

  const { t } = useTranslation();

  const location = useLocation();

  const { setValue } = useMyContext();

  useEffect(() => setValue(location.pathname), [location.pathname, setValue]);

  const [originalStructure, setOriginalStructure] = useState<StructureDto[]>(
    [],
  );

  const [structure, setStructure] = useState<StructureDto[]>([]);

  const [editMode, setEditMode] = useState<boolean>(false);

  const [selectedCategoryId, setSelectedCategoryId] = useState<
    string | undefined
  >(undefined);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    return requestCreateCategory.done.watch(({ result }) => {
      if (result.ok && result.data) {
        fetchCategories();
        void requestGetStructure({});
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    return requestUpdateCategory.done.watch(({ result }) => {
      if (result.ok && result.data) {
        fetchCategories();
        void requestGetStructure({});
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    return requestDeleteCategory.done.watch(({ result }) => {
      if (result.ok) {
        fetchCategories();
        void requestGetStructure({});
        toastSuccess(t<string>("categories.globalButtons.delete.success"));
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    void requestGetStructure({});
  }, []);

  useEffect(() => {
    return requestGetStructure.done.watch(({ result }) => {
      if (result.ok && result.data) {
        const data = result.data;
        setOriginalStructure(data);
        setStructure(deepCopy(data));
      } else {
        toastError(t<string>("structure.loadFailed"));
      }
    });
  });

  useEffect(() => {
    return requestUpdateStructure.done.watch(({ result }) => {
      if (result.ok && result.data) {
        toastSuccess(t<string>("structure.success"));
        setOriginalStructure(deepCopy(structure));
        setEditMode(false);
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  const isValidDragAndDrop = (
    dragNode: StructureDto,
    dropNode: StructureDto,
    info: StructureDragNDropInfo,
    parentNode?: StructureDto,
  ): boolean => {
    const isCategory = (obj?: StructureDto, key?: string): boolean => {
      return (
        obj?.type === StructureTypes.CATEGORY && (!key || obj?.shortcut === key)
      );
    };

    if (!dropNode || !dragNode || !info) {
      return false;
    }

    // les catégories racines ne sont pas déplaçables
    if (
      (isCategory(dragNode, StructureStaticKeys.FOOTER) ||
        isCategory(dragNode, StructureStaticKeys.NAVIGATION) ||
        isCategory(dragNode, StructureStaticKeys.HOME)) &&
      (parentNode || !info.dropToGap)
    ) {
      toastError(t<string>("structure.errors.rootCategoryIsNotDraggable"));
      return false;
    }

    // une page ne peut pas être parent
    if (dropNode.type === StructureTypes.PAGE && !info.dropToGap) {
      toastError(t<string>("structure.errors.pageCannotBeParent"));
      return false;
    }

    // on ne peut pas déplacer de contenu à la catégorie Home sauf le contenu déjà présent
    if (
      (isCategory(dropNode, StructureStaticKeys.HOME) &&
        !info.dropToGap &&
        dragNode.categoryId !== dropNode.key) ||
      (parentNode &&
        isCategory(parentNode, StructureStaticKeys.HOME) &&
        info.dropToGap &&
        dragNode.categoryId !== parentNode.key)
    ) {
      toastError(t<string>("structure.errors.addingToHomeIsForbidden"));
      return false;
    }

    // du contenu ne peut pas être ajouté à la racine
    if (
      !isCategory(dragNode, StructureStaticKeys.FOOTER) &&
      !isCategory(dragNode, StructureStaticKeys.NAVIGATION) &&
      !isCategory(dragNode, StructureStaticKeys.HOME) &&
      ((!parentNode && info.dropToGap) || (!dropNode && !info.dropToGap))
    ) {
      toastError(t<string>("structure.errors.cannotAddContentToRoot"));
      return false;
    }

    // on ne peut pas déplacer une page à la catégorie navigation
    if (
      dragNode.type === StructureTypes.PAGE &&
      ((isCategory(dropNode, StructureStaticKeys.NAVIGATION) &&
        !info.dropToGap) ||
        (parentNode &&
          isCategory(parentNode, StructureStaticKeys.NAVIGATION) &&
          info.dropToGap))
    ) {
      toastError(
        t<string>("structure.errors.addingPageToNavigationIsForbidden"),
      );
      return false;
    }

    return true;
  };

  const onDrop = (info: StructureDragNDropInfo): void => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const data = [...structure];

    // Find dropNode and its parent
    let dropNode: StructureDto | undefined;
    let dropParent: StructureDto | undefined;
    findAndApply(data, dropKey.toString(), ({ item, parent }) => {
      dropNode = item;
      dropParent = parent;
    });

    // Find dragObject
    let dragNode: any;
    findAndApply(data, dragKey.toString(), ({ item }) => {
      dragNode = item;
    });

    // Validations Drag and drop
    if (
      !dropNode ||
      !info ||
      !dragNode ||
      !isValidDragAndDrop(dragNode as StructureDto, dropNode, info, dropParent)
    ) {
      return;
    }

    // Apply drag and drop
    findAndApply(data, dragKey.toString(), ({ index, arr }) => {
      arr.splice(index, 1);
    });
    if (!info.dropToGap) {
      // Insert as child of drop node
      dropNode.children = dropNode.children || [];
      const newChild = {
        ...dragNode,
        categoryId: dropNode.key,
      } as StructureDto;
      if (info.node.expanded) {
        dropNode.children.unshift(newChild);
      } else {
        dropNode.children.push(newChild);
      }
    } else {
      // Insert as child of parent node
      const children: StructureDto[] = dropParent?.children ?? data;
      let i = 0;
      findAndApply(children, dropKey.toString(), ({ index }) => {
        i = index;
      });
      if (info.dropPosition === -1) {
        children.splice(i, 0, {
          ...dragNode,
          categoryId: dropNode.categoryId ?? null,
        } as StructureDto);
      } else {
        children.splice(i + 1, 0, {
          ...dragNode,
          categoryId: dropNode.categoryId ?? null,
        } as StructureDto);
      }
      if (dropParent) {
        // Force React to properly rerender children
        dropParent.children = [...children];
      }
    }
    setStructure(data);
  };

  const handleSubmit = () => {
    void requestUpdateStructure({
      dto: {
        structure,
      },
    });
  };

  return (
    <PageLayout title={t<string>("structure.title")}>
      <>
        <div className="form-first-item-sticky-top-container">
          <div className="form-first-item-sticky-top-content">
            <div className="d-flex align-items-center justify-content-between flex-wrap px-3">
              <Divider orientation="left">
                <h3 className="text-secondary mb-0">
                  {t<string>("common.actions")}
                </h3>
              </Divider>
              <div className="my-3">
                {editMode ? (
                  <>
                    <Popconfirm
                      title={t<string>("common.cancel_confirmation")}
                      okText={t<string>("common.yes")}
                      cancelText={t<string>("common.no")}
                      onConfirm={() => {
                        setStructure(deepCopy(originalStructure));
                        setEditMode(false);
                        void requestGetStructure({});
                      }}
                      placement="top"
                      icon={<QuestionCircleOutlined />}
                      className="m-2"
                    >
                      <Button htmlType="reset">
                        <StopOutlined /> {t<string>("common.cancel")}
                      </Button>
                    </Popconfirm>
                    <BasicButton
                      className="btn-primary m-2"
                      type="submit"
                      onClick={handleSubmit}
                      text={t<string>("common.save")}
                      icon={<SaveOutlined />}
                    />
                  </>
                ) : (
                  <>
                    <BasicButton
                      type="reset"
                      className="btn-primary m-2"
                      onClick={() => setEditMode(true)}
                      text={t<string>("common.edit")}
                      icon={<EditOutlined />}
                    />
                    <BasicButton
                      type="submit"
                      variant="secondary"
                      onClick={() => {
                        setSelectedCategoryId(undefined);
                        setIsModalOpen(true);
                      }}
                      text={t<string>("addCategory.button")}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {editMode && (
          <Divider orientation="left">
            <h3 className="text-secondary mb-0">
              {t<string>("structure.help")}
            </h3>
          </Divider>
        )}
        <Tree
          disabled={!editMode}
          className="draggable-tree structure-tree"
          draggable
          blockNode
          onDrop={onDrop}
          treeData={structure.filter(
            (elmt) =>
              elmt.shortcut !== StructureStaticKeys.ARCHIVE &&
              elmt.shortcut !== StructureStaticKeys.ARTICLE,
          )}
          titleRender={(nodeData: DataNode) => {
            const data = nodeData as StructureDto;
            return (
              <div
                className="d-flex justify-content-center"
                style={{ flexDirection: "column", height: "32px" }}
              >
                <div>
                  {data.pageStatus && (
                    <PagesStatusTag
                      className="status-tag"
                      status={data.pageStatus}
                    />
                  )}

                  <span>
                    {data.type === "CATEGORY" && (
                      <span style={{ fontWeight: "bold" }}>
                        <FolderOutlined
                          style={{ marginRight: "8px", marginLeft: "5px" }}
                        />
                        {data.title}
                      </span>
                    )}

                    {data.type === "PAGE" && (
                      <FileOutlined
                        style={{ marginRight: "8px", marginLeft: "5px" }}
                      />
                    )}
                    {data.version !== null && data.version !== undefined && (
                      <Tag className="" color="#888888">
                        {`v${data.version}`}
                      </Tag>
                    )}
                  </span>

                  {data.type === "PAGE" && <span>{data.title}</span>}

                  {data.type === "PAGE" && !editMode && (
                    <>
                      <Link
                        to={ROUTES.cms.pages.details.generate(data.key)}
                        className="ms-3 ml-16 mr-8 tree-link"
                      >
                        {t<string>("structure.details")}
                      </Link>
                      <div className="text-grey d-inline">{" | "}</div>
                      <PreviewPageLink page={data} />
                    </>
                  )}

                  {data.type === "CATEGORY" && data.editable && !editMode && (
                    <BasicButton
                      type="submit"
                      variant="primary"
                      title={t<string>("structure.actions.update")}
                      icon={<EditOutlined style={{ fontSize: "18px" }} />}
                      className="ml-8 tree-button"
                      onClick={() => {
                        setSelectedCategoryId(data.key);
                        setIsModalOpen(true);
                      }}
                    />
                  )}

                  {data.type === "CATEGORY" && !editMode && (
                    <DeleteCategory category={data} />
                  )}
                </div>
              </div>
            );
          }}
        />
        <CategoryModal
          categoryId={selectedCategoryId}
          isModalOpen={isModalOpen}
          close={() => {
            setSelectedCategoryId(undefined);
            setIsModalOpen(false);
          }}
        />
      </>
    </PageLayout>
  );
};

export default StructureScreen;
