import i18n from "i18next";
import { ReactNode } from "react";
import BasicTag from "@components/tags/BasicTag";
import {
  ClockCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { tooltipDateNoTimeRender } from "@components/tooltips/CustomTooltip";
import {
  RG_REQUEST_STATUS,
  RgRequestStatus,
} from "@utils/enums/gaz.professional.qualifications.rg-requests.status.enum";
import { ColumnsType } from "antd/es/table";
import { QualificationsRequestItem } from "@views/gazProfessional/details/QualificationsDetail";

export const columns = (): ColumnsType<QualificationsRequestItem> => [
  {
    title: i18n.t(
      "gazProfessional.qualifications.requests.table.headers.createdAt",
    ),
    dataIndex: "createdAt",
    key: "createdAt",
    className: "date",
    width: 180,
    render: tooltipDateNoTimeRender,
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t("gazProfessional.qualifications.requests.table.headers.type"),
    dataIndex: "typeLabel",
    key: "typeLabel",
    className: "typeLabel",
    ellipsis: {
      showTitle: false,
    },
    sorter: true,
    showSorterTooltip: false,
    width: "35%",
  },
  {
    title: i18n.t("gazProfessional.qualifications.requests.table.headers.rg"),
    dataIndex: "rg",
    key: "rg",
    className: "rg",
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: i18n.t(
      "gazProfessional.qualifications.requests.table.headers.status",
    ),
    dataIndex: "status",
    key: "status",
    className: "status",
    ellipsis: {
      showTitle: false,
    },
    width: 160,
    render: (record: RgRequestStatus): ReactNode => {
      return (
        <div className="d-flex justify-content-start">
          {record === RG_REQUEST_STATUS.IN_PROGRESS ? (
            <BasicTag
              label={i18n.t(record.label)}
              variant="light-green"
              className="d-inline"
            />
          ) : record === RG_REQUEST_STATUS.PENDING ? (
            <BasicTag
              label={i18n.t(record.label)}
              variant="light-yellow"
              className="d-inline"
              icon={<ClockCircleOutlined />}
            />
          ) : record === RG_REQUEST_STATUS.TO_FIX ? (
            <BasicTag
              label={i18n.t(record.label)}
              variant="light-red"
              className="d-inline"
              icon={<ExclamationCircleOutlined />}
            />
          ) : (
            <b>{i18n.t(record.label)}</b>
          )}
        </div>
      );
    },
    sorter: true,
    showSorterTooltip: false,
  },
];
