import React, { useState } from "react";
import { ContentBlock, EditorState, EntityInstance } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "@shelterzoom/draft-js-import-html";
import { Editor } from "react-draft-wysiwyg";

/* eslint-disable */
const importOptions: any = {
  customInlineFn: (element: any) => {
    const style = [];
    if (element.style.color) {
      style.push("color-" + element.style.color);
    }
    if (element.style.backgroundColor) {
      style.push("bgcolor-" + element.style.backgroundColor);
    }
    if (element.style["font-family"]) {
      style.push("fontfamily-" + element.style["font-family"]);
    }
    if (element.style.fontSize) {
      style.push("fontsize-" + element.style.fontSize);
    }
    if (element.style["text-align"]) {
      style.push("textalign-" + element.style["text-align"]);
    }
    if (style.length > 0) {
      return {
        type: "STYLE",
        style,
      };
    } else return null;
  },

  customBlockFn: (element: any) => {
    if (element.style["text-align"]) {
      return {
        data: {
          "text-align": element.style["text-align"],
        },
      };
    } else return null;
  },
};

const colorKey = "color-";
const bgColorKey = "bgcolor-";
const fontSizeKey = "fontsize-";
const fontFamilyKey = "fontfamily-";

const availableColors = {
  "#0F4291": "primary-blue",
  "#4197CB": "secondary-blue",
  "#E7EDF4": "light-primary-blue",
  "#6BA43A": "primary-green",
  "#456926": "secondary-green",
  "#E1EDD8": "light-primary-green",
  "#FF1818": "danger-color",
  "#fecb00": "warning-color",
  "#A5A5A5": "grey",
  "#ECECEC": "light-grey",
  "#ffffff": "white",
  "#000000": "black",
};

const availableFontSize = [12, 14, 16, 18, 24, 30];

function componentToHex(c: string): string {
  const hex = parseInt(c).toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

const regexpRGB = /rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)/;

function extractColorFrom(value: string) {
  if (!value.includes("rgb(")) {
    return value;
  }

  const matches = RegExp(regexpRGB).exec(value);
  if (matches) {
    return (
      "#" +
      componentToHex(matches[1]) +
      componentToHex(matches[2]) +
      componentToHex(matches[3])
    );
  }

  return "";
}

const exportOptions: any = {
  inlineStyles: {},
  inlineStyleFn: (styles: any) => {
    const colorValue = styles
      .filter((value: any) => value.startsWith(colorKey))
      .last();
    const bgColorValue = styles
      .filter((value: any) => value.startsWith(bgColorKey))
      .last();
    const fontSizeValue = styles
      .filter((value: any) => value.startsWith(fontSizeKey))
      .last();
    const fontFamilyValue = styles
      .filter((value: any) => value.startsWith(fontFamilyKey))
      .last();

    if (colorValue || fontSizeValue || fontFamilyValue || bgColorValue) {
      const values: any = {};

      let classes: string[] = [];

      if (colorValue) {
        const color = colorValue.substring(colorKey.length);
        values.color = color;

        let hexColorValue = extractColorFrom(color);
        availableColors.valueOf();
        // @ts-ignore
        classes.push(`text-${availableColors[hexColorValue]}`);
      }
      if (bgColorValue) {
        const bgColor = bgColorValue.substring(colorKey.length);
        values.backgroundColor = bgColor;

        let hexBgColorValue = extractColorFrom(bgColor);
        // @ts-ignore
        classes.push(`highlight-text-${availableColors[hexBgColorValue]}`);
      }
      if (fontSizeValue) {
        const fontSize = fontSizeValue.substring(fontSizeKey.length);

        values.fontSize = fontSize;
        classes.push(`font-size-${fontSize}`);
      }
      if (fontFamilyValue) {
        values.fontFamily = fontFamilyValue.substring(fontFamilyKey.length);
      }
      return {
        element: "span",
        attributes: {
          class: classes.join(" "),
        },
        style: values,
      };
    } else return null;
  },
  defaultBlockTag: "div",
  blockStyleFn: (block: ContentBlock) => {
    const alignment = block.getData().get("text-align") as string;
    if (alignment) {
      const style: any = {
        textAlign: alignment,
      };
      return {
        attributes: {
          class: `text-${alignment}`,
        },
        style,
      };
    }
    return {};
  },
  entityStyleFn: (entity: EntityInstance) => {
    const entityType = entity.getType().toLowerCase();
    const entityData = entity.getData();
    if (entityType === "image") {
      const alignment = entityData.alignment;
      const style: any = {
        display: "block",
      };
      if (alignment !== "left") {
        style.marginLeft = "auto";
      }
      if (alignment !== "right") {
        style.marginRight = "auto";
      }
      return {
        element: "img",
        attributes: {
          src: entityData.src,
          height: entityData.height,
          width: entityData.width,
        },
        style,
      };
    } else if (entityType === "link") {
      const data = entity.getData();
      return {
        element: "a",
        attributes: {
          href: data.url,
          target: "_blank",
          class: "link",
        },
      };
    } else if (entityType === "ul") {
      return {
        element: "ul",
      };
    } else return {};
  },
};
/* eslint-enable */

export const HtmlEditor: React.FunctionComponent<{
  debug?: boolean;
  text?: string;
  inputIndex: number;
  handleTextContentChange: (htmlContent: string, inputIndex: number) => void;
  editMode?: boolean;
}> = ({
  debug = false,
  text = undefined,
  inputIndex,
  handleTextContentChange,
  editMode = true,
}: {
  debug?: boolean;
  text?: string;
  inputIndex: number;
  handleTextContentChange: (htmlContent: string, inputIndex: number) => void;
  editMode?: boolean;
}) => {
  const [editorState, setEditorState] = useState<EditorState>(
    text
      ? EditorState.createWithContent(stateFromHTML(text, importOptions))
      : EditorState.createEmpty(),
  );

  const [html, setHtml] = useState<string | null>(
    text ? text.replace(/^<p><br><\/p>$/, "") : null,
  );

  const handleOnChange = (editorState: EditorState): void => {
    setEditorState(editorState);
    const htmlState = stateToHTML(
      editorState.getCurrentContent(),
      exportOptions,
    );
    const parsedState: string | null = htmlState
      ? htmlState.replace(/^<p><br><\/p>$/, "")
      : null;
    setHtml(parsedState);
    const contentState = parsedState ?? "";
    handleTextContentChange(contentState, inputIndex);
  };

  const toolbar = {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "list",
      "textAlign",
      "colorPicker",
      "link",
      "remove",
      "history",
    ],
    inline: {
      inDropdown: false,
      className: undefined,
      options: [
        "bold",
        "italic",
        "underline",
        "strikethrough" /*"monospace", "superscript", "subscript"*/,
      ],
      bold: { /*icon: bold, */ className: undefined },
      italic: { /*icon: italic, */ className: undefined },
      underline: { /*icon: underline, */ className: undefined },
      strikethrough: { /*icon: strikethrough, */ className: undefined },
      monospace: { /*icon: monospace, */ className: undefined },
      superscript: { /*icon: superscript, */ className: undefined },
      subscript: { /*icon: subscript, */ className: undefined },
    },
    blockType: {
      inDropdown: true,
      options: ["Normal", "H2", "H3", "H4", "H5", "H6", "Blockquote"],
      className: undefined,
      dropdownClassName: undefined,
    },
    fontSize: {
      /*icon: fontSize,*/
      options: availableFontSize,
      className: undefined,
      dropdownClassName: undefined,
    },
    fontFamily: {
      options: [
        "Arial",
        "Georgia",
        "Impact",
        "Tahoma",
        /*"Times New Roman", */ "Verdana",
      ],
      className: undefined,
      dropdownClassName: undefined,
    },
    list: {
      inDropdown: false,
      className: undefined,
      options: ["unordered", "ordered" /*, "indent", "outdent"*/],
      unordered: { /*icon: unordered, */ className: undefined },
      ordered: { /*icon: ordered, */ className: undefined },
      indent: { /*icon: indent, */ className: undefined },
      outdent: { /*icon: outdent, */ className: undefined },
    },
    textAlign: {
      inDropdown: false,
      className: undefined,
      options: ["left", "center", "right", "justify"],
      left: { /*icon: left, */ className: undefined },
      center: { /*icon: center, */ className: undefined },
      right: { /*icon: right, */ className: undefined },
      justify: { /*icon: justify, */ className: undefined },
    },
    colorPicker: {
      /*icon: color,*/
      className: undefined,
      popClassName: undefined,
      colors: Object.keys(availableColors),
    },
    link: {
      inDropdown: false,
      className: undefined,
      popClassName: undefined,
      options: ["link", "unlink"],
      link: { /*icon: link, */ className: undefined },
      unlink: { /*icon: unlink, */ className: undefined },
    },
    embedded: {
      /*icon: image, */ className: undefined,
      popClassName: undefined,
    },
    emoji: {
      /*icon: emoji,*/
      className: undefined,
      popClassName: undefined,
      emojis: [],
    },
    image: {
      /*icon: image,*/
      className: undefined,
      popupClassName: undefined,
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: false,
      uploadCallback: undefined,
    },
    remove: { /*icon: eraser, */ className: undefined },
    history: {
      inDropdown: false,
      className: undefined,
      options: ["undo", "redo"],
      undo: { /*icon: undo, */ className: undefined },
      redo: { /*icon: redo, */ className: undefined },
    },
  };

  return (
    <>
      <div className="position-relative pt-1">
        <div>
          <Editor
            editorState={editorState}
            toolbarClassName="cncc-richtext-toolbar"
            wrapperClassName={`cncc-richtext-wrapper${
              !editMode ? " cncc-richtext-wrapper-readonly" : ""
            }`}
            editorClassName="cncc-richtext-editor"
            stripPastedStyles={true}
            spellCheck={true}
            toolbar={toolbar}
            onEditorStateChange={handleOnChange}
            readOnly={!editMode}
          />
        </div>

        {debug ? (
          <div>
            <h2 className="preview">Code HTML</h2>
            <div>{html}</div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
