import {
  ParsedResponse,
  ServerResponseParser,
} from "@utils/rest/ServerResponseParse";
import { logout } from "@state/auth/AuthEvents";
import logger from "@utils/logger";
import { DEFAULT_500_ERROR } from "@utils/Constant";
import { SorterResult } from "antd/es/table/interface";

type RestMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

interface DataWithId {
  id: string;
}

interface SearchDto {
  [index: string]: any;
}

const timeout = 60000;

const executeCall = <U>(
  fetchPromise: Promise<Response>,
  method: RestMethod,
) => {
  return fetchPromise
    .then((response: Response) => {
      logger.debug(`HTTP response ${response.status}`);
      if (response.status === 401) {
        logout();
      }
      if (response.status === 403) {
        logout();
      }
      if (response.status === 500) {
        return DEFAULT_500_ERROR;
      }
      return method === "DELETE"
        ? {
            responseCode: response.status,
            errorMessage: undefined,
            data: undefined,
            ok: true,
          }
        : new ServerResponseParser<U>().parseResponse(response);
    })
    .catch(() => {
      return DEFAULT_500_ERROR;
    });
};

export const makeRestCall = <T, U>(
  url: string,
  method: RestMethod,
  data?: T,
): Promise<ParsedResponse<U>> => {
  const controller = new AbortController();
  const signal = controller.signal;

  setTimeout(() => controller.abort(), timeout);

  const headers: HeadersInit = {
    ["Content-Type"]: "application/json",
  };

  const fetchPromise = fetch(url, {
    method,
    signal,
    credentials: "include",
    body: data ? JSON.stringify(data) : undefined,
    headers,
  });
  return executeCall<U>(fetchPromise, method);
};

export const makeRestMultipartCall = <U>(
  url: string,
  method: RestMethod,
  data: FormData,
): Promise<ParsedResponse<U>> => {
  const controller = new AbortController();
  const signal = controller.signal;
  setTimeout(() => controller.abort(), timeout);
  const fetchPromise = fetch(url, {
    method,
    signal,
    credentials: "include",
    body: data,
  });
  return executeCall<U>(fetchPromise, method);
};

const buildSearchParameters = <T extends SearchDto>(dto?: T) => {
  return dto
    ? Object.keys(dto)
        .map((key) =>
          dto[key] !== undefined ? `&${key}=${String(dto[key])}` : "",
        )
        .join("")
    : "";
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const restListHandlerPagination = <T, U extends SearchDto = {}>(
  endpointUrl: string,
): ((data: {
  page: number;
  limit: number;
  sorter?: SorterResult<any>;
  dto?: U;
}) => Promise<ParsedResponse<T>>) => {
  return ({
    page,
    limit,
    sorter,
    dto,
  }: {
    page: number;
    limit: number;
    sorter?: SorterResult<any>;
    dto?: U;
  }) => {
    const searchParameters = buildSearchParameters<U>(dto);
    let order;
    switch (sorter?.order) {
      case "ascend":
        order = ",ASC";
        break;
      case "descend":
        order = ",DESC";
        break;
      default:
        order = "";
        break;
    }
    return makeRestCall<void, T>(
      `${endpointUrl}?page=${page}&size=${limit}&sort=${
        (order && sorter?.columnKey) || ""
      }${order}${searchParameters}`,
      "GET",
    );
  };
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const restListHandler = <T, U extends SearchDto = {}>(
  endpointUrl: string,
): ((data: { dto?: U }) => Promise<ParsedResponse<T[]>>) => {
  return ({ dto }: { dto?: U }) => {
    const searchParameters = buildSearchParameters<U>(dto);
    return makeRestCall<void, T[]>(`${endpointUrl}?${searchParameters}`, "GET");
  };
};

export const restDetailsHandler = <T>(
  endpointUrl: string,
  suffixUrl?: string,
): ((id: string) => Promise<ParsedResponse<T>>) => {
  return (id: string) =>
    makeRestCall<void, T>(
      endpointUrl + id + (suffixUrl ? suffixUrl : ""),
      "GET",
    );
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const restGetUniqueHandler = <T, U extends SearchDto = {}>(
  endpointUrl: string,
  suffixUrl?: string,
): ((data: { dto?: U }) => Promise<ParsedResponse<T>>) => {
  return ({ dto }: { dto?: U }) => {
    const searchParameters = buildSearchParameters<U>(dto);
    return makeRestCall<void, T>(
      `${endpointUrl + (suffixUrl ? suffixUrl : "")}?${searchParameters}`,
      "GET",
    );
  };
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const restIdListHandlerPagination = <T, U extends SearchDto = {}>(
  endpointUrl: string,
  suffixUrl: string,
): ((data: {
  page: number;
  limit: number;
  sorter?: SorterResult<any>;
  dto?: U;
  id: string;
}) => Promise<ParsedResponse<T>>) => {
  return (data: {
    page: number;
    limit: number;
    sorter?: SorterResult<any>;
    dto?: U;
    id: string;
  }) => {
    const searchParameters = buildSearchParameters<U>(data.dto);
    let order;
    switch (data.sorter?.order) {
      case "ascend":
        order = ",ASC";
        break;
      case "descend":
        order = ",DESC";
        break;
      default:
        order = "";
        break;
    }
    return makeRestCall<void, T>(
      `${endpointUrl}${data.id}${suffixUrl}?page=${data.page}&size=${
        data.limit
      }&sort=${
        (order && data.sorter?.columnKey) || ""
      }${order}${searchParameters}`,
      "GET",
    );
  };
};

export const restIdListHandler = <T>(
  endpointUrl: string,
  suffixUrl?: string,
): ((id: string) => Promise<ParsedResponse<T[]>>) => {
  return (id: string) =>
    makeRestCall<void, T[]>(
      `${endpointUrl}${id}${suffixUrl ? suffixUrl : ""}`,
      "GET",
    );
};

export const restCreationHandler = <T, U>(
  endpointUrl: string,
  suffixUrl?: string,
  suffix2Url?: string,
): ((data: {
  upperEntityId?: string;
  upperEntity2Id?: string;
  dto: T;
}) => Promise<ParsedResponse<U>>) => {
  return (data: { upperEntityId?: string; upperEntity2Id?: string; dto: T }) =>
    makeRestCall<T, U>(
      endpointUrl +
        (data.upperEntityId ? data.upperEntityId : "") +
        (suffixUrl ? suffixUrl : "") +
        (data.upperEntity2Id ? data.upperEntity2Id : "") +
        (suffix2Url ? suffix2Url : ""),
      "POST",
      data.dto,
    );
};

export const restCreationMultipartHandler = <U>(
  endpointUrl: string,
  suffixUrl?: string,
): ((data: { dto: FormData }) => Promise<ParsedResponse<U>>) => {
  return (data: { upperEntityId?: string; dto: FormData }) =>
    makeRestMultipartCall<U>(
      endpointUrl + (suffixUrl ? suffixUrl : ""),
      "POST",
      data.dto,
    );
};

export const restDeletionHandler = <T extends DataWithId>(
  endpointUrl: string,
  suffixUrl?: string,
): ((data: {
  upperEntityId?: string;
  dto: T;
}) => Promise<ParsedResponse<void>>) => {
  return (data: { upperEntityId?: string; dto: T }) =>
    makeRestCall<T, void>(
      endpointUrl +
        (data.upperEntityId ? data.upperEntityId : "") +
        (suffixUrl ? suffixUrl : "") +
        data.dto.id,
      "DELETE",
      data.dto,
    );
};

export const restUpdateHandler = <T, U>(
  endpointUrl: string,
  suffixUrl?: string,
): ((data: {
  upperEntityId?: string;
  id: string;
  dto: T;
}) => Promise<ParsedResponse<U>>) => {
  return (data: { upperEntityId?: string; id: string; dto: T }) =>
    makeRestCall<T, U>(
      endpointUrl +
        (data.upperEntityId ? data.upperEntityId : data.id) +
        (suffixUrl ? suffixUrl : "") +
        (data.upperEntityId ? data.id : ""),
      "PUT",
      data.dto,
    );
};

export const restPatchHandler = <T, U>(
  endpointUrl: string,
  suffixUrl?: string,
): ((data: {
  upperEntityId?: string;
  id: string;
  dto: T;
}) => Promise<ParsedResponse<U>>) => {
  return (data: { upperEntityId?: string; id: string; dto: T }) =>
    makeRestCall<T, U>(
      endpointUrl +
        (data.upperEntityId ? data.upperEntityId : data.id) +
        (suffixUrl ? suffixUrl : "") +
        (data.upperEntityId ? data.id : ""),
      "PATCH",
      data.dto,
    );
};

export const restPostHandler = <T, U>(
  endpointUrl: string,
  suffixUrl?: string,
): ((data: {
  upperEntityId?: string;
  dto: T;
}) => Promise<ParsedResponse<U>>) => {
  return (data: { upperEntityId?: string; dto: T }) =>
    makeRestCall<T, U>(
      endpointUrl +
        (data.upperEntityId ? data.upperEntityId : "") +
        (suffixUrl ? suffixUrl : ""),
      "POST",
      data.dto,
    );
};

export const restWorkflowHandler = <T, U>(
  endpointUrl: string,
  suffixUrl?: string,
): ((data: {
  upperEntityId?: string;
  id: string;
  dto: T;
}) => Promise<ParsedResponse<U>>) => {
  return (data: { upperEntityId?: string; id: string; dto: T }) =>
    makeRestCall<T, U>(
      endpointUrl +
        (data.upperEntityId ? data.upperEntityId : "") +
        (suffixUrl ? suffixUrl : "") +
        data.id +
        "/workflow",
      "POST",
      data.dto,
    );
};

export const restFieldUpdateHandler = <T, U>(
  endpointUrl: string,
  suffixUrl?: string,
): ((data: { id: string; dto: T }) => Promise<ParsedResponse<U>>) => {
  return (data: { id: string; dto: T }) =>
    makeRestCall<T, U>(
      endpointUrl + data.id + (suffixUrl ? suffixUrl : ""),
      "PATCH",
      data.dto,
    );
};

export const restIncreaseOrderHandler = <T extends DataWithId>(
  endpointUrl: string,
): ((data: T) => Promise<ParsedResponse<void>>) => {
  return (data: T) =>
    makeRestCall<T, void>(endpointUrl + data.id + "/increaseOrder", "POST");
};

export const restDecreaseOrderHandler = <T extends DataWithId>(
  endpointUrl: string,
): ((data: T) => Promise<ParsedResponse<void>>) => {
  return (data: T) =>
    makeRestCall<T, void>(endpointUrl + data.id + "/decreaseOrder", "POST");
};
