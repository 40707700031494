import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Modal, Popconfirm, Row, Spin } from "antd";
import {
  EditOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { FIELD_LENGTH, formItemBlockLayout } from "src/utils/Constant";
import { SaveOutlined, StopOutlined } from "@ant-design/icons/lib";
import { addCategory, updateCategory } from "src/state/category/CategoryEvents";
import {
  requestCreateCategory,
  requestGetCategory,
  requestUpdateCategory,
} from "src/state/category/CategoryEffects";
import { CategoryInfoResponseDto } from "@state/category/dto/category.info.response.dto";
import { CategoryDetailsResponseDto } from "@state/category/dto/category.details.response.dto";
import { useNavigate } from "react-router-dom";
import { Field } from "@type/form/field.types";
import { Store, ValidateErrorEntity } from "rc-field-form/lib/interface";
import { CategorySaveRequestDto } from "@state/category/dto/category.save.request.dto";
import InputFormField from "@components/inputs/InputFormField";
import CategoryTreeFormField from "@components/category/CategoryTreeFormField";
import { useCategories } from "@state/category/Categories";
import { ROUTES } from "@routes/Routes";
import { toastError, toastSuccess } from "@utils/toast-helper";

const { confirm } = Modal;

export interface Props {
  categoryId?: string;
  editMode?: boolean;
  setEditMode?: (editMode: boolean) => void;
  setElementTitle?: (title: string) => void;
  onSuccess: () => void;
}

const CategoryForm: React.FunctionComponent<Props> = (props: Props) => {
  const { categoryId, editMode, setEditMode, setElementTitle, onSuccess } =
    props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const categories: CategoryInfoResponseDto[] | null = useCategories({});

  const [form] = Form.useForm();

  const [category, setCategory] = useState<CategoryDetailsResponseDto | null>(
    null,
  );

  const [fields, setFields] = useState<Field[]>([]);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [getResponseReceived, setGetResponseReceived] =
    useState<boolean>(false);

  const confirmationPopup = async (publish?: boolean) => {
    return new Promise<boolean>((resolve) => {
      confirm({
        title: t<string>("common.confirm"),
        content: publish
          ? t<string>("category.publish")
          : publish !== undefined
          ? t<string>("categories.table.confirmation.unpublish")
          : t<string>("category.save"),
        okText: t<string>("common.yes"),
        cancelText: t<string>("common.no"),
        centered: true,
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  useEffect(() => {
    if (categoryId) {
      void requestGetCategory(categoryId);
    }
  }, [categoryId]);

  useEffect(() => {
    return requestGetCategory.done.watch(({ result }) => {
      setGetResponseReceived(true);
      if (result.ok && result.data) {
        setCategory(result.data);
        setFields([
          {
            name: ["title"],
            value: result.data.title,
          },
          {
            name: ["parentCategoryId"],
            value: result.data.parentCategoryId,
          },
        ]);
        setElementTitle && setElementTitle(result.data.title);
      } else {
        toastError(t<string>("category.not_found"));
      }
    });
  });

  useEffect(() => {
    return requestCreateCategory.done.watch(({ result }) => {
      setButtonLoading(false);
      if (result.ok && result.data) {
        addCategory(result.data);
        toastSuccess(t<string>("addCategory.success"));
        onSuccess();
        navigate(ROUTES.cms.structure.details.generate());
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    return requestUpdateCategory.done.watch(({ result }) => {
      setButtonLoading(false);
      if (result.ok && result.data) {
        updateCategory(result.data);
        toastSuccess(t<string>("editCategory.success"));
        onSuccess();
        navigate(ROUTES.cms.structure.details.generate());
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  const handleSubmit = (values: Store) => {
    void confirmationPopup().then((confirmed: boolean) => {
      if (confirmed) {
        setButtonLoading(true);
        const categoryToSave: CategorySaveRequestDto = {
          title: values["title"] as string,
          parentCategoryId: values["parentCategoryId"] as string,
        };
        if (categoryId) {
          void requestUpdateCategory({
            id: categoryId,
            dto: categoryToSave,
          });
        } else {
          void requestCreateCategory({
            dto: categoryToSave,
          });
        }
        setButtonLoading(true);
      }
    });
  };

  const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const onFinishFailed = ({ errorFields }: ValidateErrorEntity<Store>) => {
    toastError(t<string>("common.on_finish_failed"));
    form.scrollToField(errorFields[0].name);
  };

  return (
    <>
      {!getResponseReceived && categoryId ? (
        <div style={{ textAlign: "center" }}>
          <Spin indicator={spinIcon} />
        </div>
      ) : !categoryId || category ? (
        <Form
          {...formItemBlockLayout}
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          fields={fields}
        >
          <h3 className="text-secondary title-modal-category">
            {t<string>("common.infos")}
          </h3>
          <Row>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module="category"
                field="title"
                required={true}
                emptyErrorMessage={t<string>("common.empty_error_field")}
                readOnly={!editMode}
                maxLength={FIELD_LENGTH.NAME}
              />
            </Col>
            {categories && (
              <Col xs={24} sm={12}>
                <CategoryTreeFormField
                  categories={categories}
                  readOnly={!editMode}
                  module="category"
                  fieldName="parentCategoryId"
                  required
                />
              </Col>
            )}
          </Row>
          <div className="my-3">
            {editMode ? (
              <Button
                className="btn-primary m-2"
                htmlType="submit"
                loading={buttonLoading}
              >
                <SaveOutlined /> {t<string>("common.save")}
              </Button>
            ) : (
              category && (
                <div className="d-flex align-items-center justify-content-center flex-wrap">
                  {setEditMode && (
                    <Button
                      disabled={!category.editable}
                      htmlType="reset"
                      className="btn-primary m-2"
                      onClick={() => setEditMode(true)}
                    >
                      <EditOutlined /> {t<string>("common.edit")}
                    </Button>
                  )}
                </div>
              )
            )}
          </div>
          <div className="my-3">
            {editMode && setEditMode && (
              <>
                <Popconfirm
                  title={t<string>("common.cancel_confirmation")}
                  okText={t<string>("common.yes")}
                  cancelText={t<string>("common.no")}
                  onConfirm={() => {
                    setEditMode(false);
                  }}
                  placement="top"
                  icon={<QuestionCircleOutlined />}
                  className="m-2"
                >
                  <Button htmlType="reset">
                    <StopOutlined /> {t<string>("common.cancel")}
                  </Button>
                </Popconfirm>
              </>
            )}
          </div>
        </Form>
      ) : (
        <div>{t<string>("category.not_found")}</div>
      )}
    </>
  );
};
export default CategoryForm;
