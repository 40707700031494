import React from "react";
import { Tag } from "antd";
import i18n from "i18next";

export interface Props {
  status: string;
  className?: string;
}

interface MentionELearningStatus {
  label: string;
  color: string;
  value: string;
}

export const MENTION_E_LEARNING_STATUS: {
  [index: string]: MentionELearningStatus;
} = {
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  IN_PROGRESS: {
    label: i18n.t<string>("mention.labels.eLearningStatus.IN_PROGRESS"),
    color: "#405faf",
    value: "IN_PROGRESS",
  },
  VALIDATED: {
    label: i18n.t<string>("mention.labels.eLearningStatus.VALIDATED"),
    color: "#00b2a9",
    value: "VALIDATED",
  },
  REFUSED: {
    label: i18n.t<string>("mention.labels.eLearningStatus.REFUSED"),
    color: "#e2003c",
    value: "REFUSED",
  },
};

export const MENTION_E_LEARNING_SMOKE_DUCT_STATUS: {
  [index: string]: MentionELearningStatus;
} = {
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  IN_PROGRESS: {
    label: i18n.t<string>("mention.labels.eLearningStatus.IN_PROGRESS"),
    color: "#405faf",
    value: "IN_PROGRESS",
  },
  VALIDATED: {
    label: i18n.t<string>("mention.labels.eLearningStatus.VALIDATED"),
    color: "#00b2a9",
    value: "VALIDATED",
  },
};

const MentionELearningStatusTag: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const { status, className } = props;

  return (
    <Tag className={className} color={MENTION_E_LEARNING_STATUS[status].color}>
      {MENTION_E_LEARNING_STATUS[status].label}
    </Tag>
  );
};

export default MentionELearningStatusTag;
