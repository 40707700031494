import { FunctionComponent, useEffect, useState } from "react";
import { Col, Divider, Form, Row } from "antd";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/buttons/BasicButton";
import { requestGetOrganisationProfessional } from "@state/organisationProfessional/OrganisationProfessionalEffets";
import { OrganisationProfessionalItemResponseDto } from "@state/organisationProfessional/dto/organisation.professional.item.response.dto";
import PGsList from "@views/organisationProfessional/details/PGsList";
import { useForm } from "antd/lib/form/Form";
import { formItemBlockLayout } from "@utils/Constant";
import InputFormField from "@components/inputs/InputFormField";
import TextAreaFormField from "@components/inputs/TextAreaFormField";
import RGsList from "@views/organisationProfessional/details/RGsList";
import RGRequestsList from "@views/organisationProfessional/details/RGRequestsList";
import { toastError } from "@utils/toast-helper";

export interface Props {
  yoocanId?: string;
}

const OrganisationProfessionalForm: FunctionComponent<Props> = (
  props: Props,
) => {
  const { yoocanId } = props;

  const { t } = useTranslation();

  const [form] = useForm();

  const [showPGs, setShowPGs] = useState<boolean>(false);
  const [showRGs, setShowRGs] = useState<boolean>(false);
  const [showRGRequests, setShowRGRequests] = useState<boolean>(false);
  const [organisationProfessional, setOrganisationProfessional] =
    useState<OrganisationProfessionalItemResponseDto>();

  useEffect(() => {
    if (organisationProfessional) {
      form.setFields([
        {
          name: ["yoocanId"],
          value: organisationProfessional.id,
        },
        {
          name: ["opId"],
          value: organisationProfessional.opId,
        },
        {
          name: ["opCode"],
          value: organisationProfessional.opCode,
        },
        {
          name: ["email"],
          value: getMailsInAColumn(organisationProfessional.email),
        },
      ]);
    }
  }, [organisationProfessional, form]);

  useEffect(() => {
    if (yoocanId) {
      void requestGetOrganisationProfessional(yoocanId);
    }
  }, [yoocanId]);

  useEffect(() => {
    return requestGetOrganisationProfessional.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setOrganisationProfessional(result.data);
      } else {
        toastError(t<string>("organisationProfessional.details.error"));
      }
    });
  }, [t]);

  const getMailsInAColumn = (mails: string) => {
    const listMails = mails.split(",");

    return listMails.reduce(
      (prev, curr) => `${prev}${!prev ? "" : "\n"}${curr}`,
      "",
    );
  };

  return (
    <>
      <div className="d-flex flex-column align-items-stretch flex-sm-row nbo-block">
        <div className="w-100 d-flex align-items-center">
          <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
            <h3 className="text-secondary mb-0">
              {t<string>("organisationProfessional.details.form.title")}
            </h3>
          </Divider>
        </div>
      </div>
      <Form form={form} {...formItemBlockLayout}>
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <InputFormField
              showLabel
              module="organisationProfessional.details"
              field="yoocanId"
              readOnly
            />
          </Col>
          <Col xs={24} lg={12}>
            <InputFormField
              showLabel
              module="organisationProfessional.details"
              field="opId"
              readOnly
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <InputFormField
              showLabel
              module="organisationProfessional.details"
              field="opCode"
              readOnly
            />
          </Col>
          <Col xs={24} lg={12}>
            <TextAreaFormField
              showLabel
              module="organisationProfessional.details"
              field="email"
              readOnly
            />
          </Col>
        </Row>
      </Form>

      <div className="d-flex flex-column align-items-stretch flex-sm-row nbo-block">
        <div className="w-100 d-flex align-items-center">
          <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
            <h3 className="text-secondary mb-0">
              {t<string>("organisationProfessional.details.listPGs.title")}
            </h3>
          </Divider>
        </div>
        <div className="w-100 d-flex align-items-center divider-sm-transparent">
          <Divider orientation="right" className="mt-2 mt-sm-3 mb-3">
            <BasicButton
              variant="primary"
              text={t<string>(
                "organisationProfessional.details.listPGs.action",
              )}
              onClick={() => setShowPGs(!showPGs)}
            />
          </Divider>
        </div>
      </div>
      {showPGs && (
        <PGsList organisationProfessional={organisationProfessional} />
      )}
      <div className="d-flex flex-column align-items-stretch flex-sm-row nbo-block">
        <div className="w-100 d-flex align-items-center">
          <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
            <h3 className="text-secondary mb-0">
              {t<string>("organisationProfessional.details.listRGs.title")}
            </h3>
          </Divider>
        </div>
        <div className="w-100 d-flex align-items-center divider-sm-transparent">
          <Divider orientation="right" className="mt-2 mt-sm-3 mb-3">
            <BasicButton
              variant="primary"
              text={t<string>(
                "organisationProfessional.details.listRGs.action",
              )}
              onClick={() => setShowRGs(!showRGs)}
            />
          </Divider>
        </div>
      </div>
      {showRGs && (
        <RGsList organisationProfessional={organisationProfessional} />
      )}
      <div className="d-flex flex-column align-items-stretch flex-sm-row nbo-block">
        <div className="w-100 d-flex align-items-center">
          <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
            <h3 className="text-secondary mb-0">
              {t<string>(
                "organisationProfessional.details.listRGRequests.title",
              )}
            </h3>
          </Divider>
        </div>
        <div className="w-100 d-flex align-items-center divider-sm-transparent">
          <Divider orientation="right" className="mt-2 mt-sm-3 mb-3">
            <BasicButton
              variant="primary"
              text={t<string>(
                "organisationProfessional.details.listRGRequests.action",
              )}
              onClick={() => setShowRGRequests(!showRGRequests)}
            />
          </Divider>
        </div>
      </div>
      {showRGRequests && (
        <RGRequestsList organisationProfessional={organisationProfessional} />
      )}
    </>
  );
};
export default OrganisationProfessionalForm;
