import { Navigate, Outlet } from "react-router-dom";
import { ProfileEnum } from "@utils/enums/profile.enum";
import { ROUTES } from "@routes/Routes";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { isProfileAmong } from "@utils/profile-helper";

type ProfileLayoutProps = {
  allowedProfiles: ProfileEnum[];
};

const ProfileLayout = ({
  allowedProfiles,
}: ProfileLayoutProps): JSX.Element => {
  const isAuthentified = useStore(authentifiedStore);

  const profile: ProfileEnum | undefined = isAuthentified?.user?.profile;

  if (!isProfileAmong(profile, allowedProfiles)) {
    return <Navigate to={ROUTES.admin.home.generate()} />;
  }

  return <Outlet />;
};

export default ProfileLayout;
