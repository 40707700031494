import { OptionType } from "@type/form/field.types";

export const PROFILES = [
  {
    value: "ADMIN",
    label: "users.labels.profiles.ADMIN",
  },
  {
    value: "SERVICES",
    label: "users.labels.profiles.SERVICES",
  },
  {
    value: "CMS",
    label: "users.labels.profiles.CMS",
  },
] as OptionType[];

export enum ProfileEnum {
  ADMIN = "ADMIN",
  CMS = "CMS",
  SERVICES = "SERVICES",
}
